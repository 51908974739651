import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from 'common/hooks';
import SwiperCore, { EffectCoverflow, Navigation, A11y } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/navigation/navigation.scss';
import './swiper.scss';
import GalleryItem from './galleryItem';

SwiperCore.use([EffectCoverflow, Navigation, A11y]);

const GalleryPreview = ({ gallery, headerColor, bodyColor }) => {
  const swiperRef = useRef();
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setEnabled(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const [showNav, setShowNav] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [width, height] = useWindowSize();
  const [sliderWidth, slidePerView] = useMemo(() => {
    if (width > 1280) {
      return [520, 5];
    } else if (width > 1200) {
      return [400, 5];
    } else if (width < height) {
      // mobile portrait
      return [300, 1.5];
    } else if (width < 767 && height < width) {
      // mobile landscape
      return [150, 5];
    } else {
      return [300, 5];
    }
  }, [width, height]);

  useEffect(() => {
    setShowNav(width > 900);
  }, [width]);

  if (!enabled) return null;

  return (
    <div className="media-preview-gallery">
      <div className="media-gallery">
        <Swiper
          ref={swiperRef}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={slidePerView}
          effect="coverflow"
          navigation={showNav}
          coverflowEffect={{
            rotate: 20,
            stretch: 10,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          onSwiper={(swiper) => swiper.slideTo(0)}
          onSetTranslate={(swiper, translate) =>
            console.log('translate', translate)
          }
          onSetTransition={(swiper, transition) =>
            console.log('transition', transition)
          }
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {gallery.map((item, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: sliderWidth + 'px',
                height: sliderWidth + 'px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GalleryItem
                maxWidth={sliderWidth}
                maxHeight={sliderWidth}
                readMoreUrl={item.readMoreUrl}
                src={item.base64 || item.imageUrl}
                alt={item.title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="preview-gallery-content">
          <div className="preview-gallery-content-body">
            {gallery.map((item, index) => (
              <div
                key={index}
                className={`preview-gallery-item ${
                  index === activeIndex ? 'active' : ''
                }`}
              >
                <p
                  className="preview-gallery-title"
                  style={{ color: headerColor }}
                >
                  {item.title}
                </p>
                <p
                  className="preview-gallery-caption"
                  style={{ color: bodyColor }}
                >
                  {item.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="preview-gallery-pagination">
          {gallery.map((item, index) => (
            <span
              key={index}
              className={`pagination-dot ${index === activeIndex && 'active'}`}
            >
              &nbsp;
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryPreview;
