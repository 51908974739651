import React from 'react';
import { connect } from 'react-redux';
import Popup from '../../components/popup';
import SocialIcon from './icon';
import './style.scss';

const SocialPopup = (props) => {
  const { tour } = props;
  return (
    <Popup closePopup={() => props.toggleSocialPopup(false)}>
      <div className="social-backdrop">
        <div className="social-title" style={{ color: props.textColor }}>
          Follow Us
        </div>
        {typeof tour === 'object' && (
          <div className="social-icons">
            {tour.websiteUrl && (
              <SocialIcon type="WEBSITE" {...props} link={tour.websiteUrl} />
            )}
            {tour.facebookUrl && (
              <SocialIcon type="FACEBOOK" {...props} link={tour.facebookUrl} />
            )}
            {tour.instagramUrl && (
              <SocialIcon
                type="INSTAGRAM"
                {...props}
                link={tour.instagramUrl}
              />
            )}
            {tour.twitterUrl && (
              <SocialIcon type="TWITTER" {...props} link={tour.twitterUrl} />
            )}
            {tour.youtubeUrl && (
              <SocialIcon type="YOUTUBE" {...props} link={tour.youtubeUrl} />
            )}
            {tour.pinterestUrl && (
              <SocialIcon
                type="PINTEREST"
                {...props}
                link={tour.pinterestUrl}
              />
            )}
          </div>
        )}
      </div>
    </Popup>
  );
};

const mapStateToProps = ({ json }) => ({
  textColor: json.tour.menuHighlightColor,
  color: (json.tour && json.tour.lightboxBodyColor) || 'white',
  tour: json.tour || {},
});

export default connect(mapStateToProps)(SocialPopup);
