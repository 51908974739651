import React, { useState, useRef } from 'react';
import Html from './Html';
import CanvasCircle from './canvasCircle';
import Canvas from './canvas';
import { CANVAS_W, CANVAS_H } from './utils';
import HotspotContainer from './hotspotContainer';
import HotspotIcon from '../icons';
import PopOverInfo from '../popover/popover.info';
import MEDIA_TYPES from '../../../consts/mediaType.const';
import SCENE_TYPES from 'consts/sceneType.const';

const HOVERzINDEX = '100';
const zIndexRange1 = [50, 0];
const zIndexRangeHover = [100, 100];

const styles = {
  width: `${CANVAS_W}px`,
  height: `${CANVAS_H}px`,
  pointerEvents: 'none',
};

const LineSingle = (props) => {
  const canvasRef = useRef();
  const hotspotRef = useRef();

  const elRef = useRef();
  const [zIndex, setZIndex] = useState('0');
  const [zIndexRange, setZIndexRange] = useState(zIndexRange1);
  const [hovering, setHovering] = useState(false);
  const hoverToggled = (isHover) => {
    const parentNode = elRef.current && elRef.current.parentNode;
    setHovering(Boolean(isHover));
    if (isHover) {
      setZIndexRange(zIndexRangeHover);
      if (parentNode) {
        setZIndex(parentNode.style.zIndex);
        parentNode.style.zIndex = HOVERzINDEX;
      }
    } else {
      setTimeout(() => {
        if (elRef && elRef.current) {
          setZIndexRange(zIndexRange1);
          if (parentNode) {
            parentNode.style.zIndex = zIndex;
          }
        }
      }, 0);
    }
  };

  return (
    <Html
      ref={elRef}
      hotspotRef={hotspotRef}
      canvasRef={canvasRef}
      position={props.position}
      center={true}
      zIndexRange={zIndexRange}
      style={styles}
      isSkytag={props.media?.type === MEDIA_TYPES.SKYTAG}
      isAerialHs={props?.scene?.sceneType === SCENE_TYPES.AERIAL}
    >
      <>
        <CanvasCircle />
        <Canvas ref={canvasRef} />
        <HotspotContainer ref={hotspotRef}>
          {props.media?.type === MEDIA_TYPES.SKYTAG ? (
            <PopOverInfo
              position={props.position}
              media={props.media}
              tour={props.tour}
            />
          ) : (
            <HotspotIcon
              type={props.type}
              hotspot={props.hotspot}
              currentSceneCoords={props.currentSceneCoords}
              scene={props.scene}
              media={props.media}
              tour={props.tour}
              onClick={props.onClick}
              isHover={hovering}
              toggleHover={(val) => hoverToggled(val)}
              hoverIn={() => hoverToggled(true)}
              hoverOut={() => hoverToggled(false)}
              visited={props.visited}
              color={props.color || ''}
            />
          )}
        </HotspotContainer>
      </>
    </Html>
  );
};

export default React.memo(LineSingle);
