import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import {
  get2DScreenPosition,
  distanceBetween2dCoordinates,
} from '../../../utils/positionHelper';
import configs from '../../../configs';
import { getLensflareLocation } from './lensflareCheck';

const getWindowMinSize = () =>
  // get min width from center to border
  (window.innerWidth > window.innerHeight
    ? window.innerHeight
    : window.innerWidth) / 2;

const getCenterScreen = () => ({
  x: window.innerWidth / 2,
  y: window.innerHeight / 2,
});

function SunFlare(props) {
  const [x, y, z] = props.position;
  const ref = useRef();
  const [texture] = useLoader(THREE.TextureLoader, [
    configs.baseUrl + '/assets/images/lensflare/sunflare/sunflare.png',
  ]);
  const { camera } = useThree();
  const curPosition = new THREE.Vector3(x, y, z);
  const updatePosition = () =>
    new Promise((resolve) => {
      ref.current.lookAt(camera.position);
      const xyLocation = get2DScreenPosition(
        curPosition,
        camera,
        window.innerWidth,
        window.innerHeight
      );
      if (!xyLocation || !xyLocation.visible) {
        if (ref.current.material.opacity > 0) {
          ref.current.material.opacity = 0;
        }
      } else {
        const maxVisibleDistance = getWindowMinSize(),
          centerArea = 50,
          screenCenter = getCenterScreen();
        const distance = distanceBetween2dCoordinates(xyLocation, screenCenter);
        if (distance <= maxVisibleDistance) {
          if (distance <= centerArea) {
            if (ref.current.material.opacity !== 1) {
              ref.current.material.opacity = 1;
            }
          } else {
            const newOpacity =
              1.0 - (distance - centerArea) / (maxVisibleDistance - centerArea);
            ref.current.material.opacity =
              newOpacity > 0 ? (newOpacity > 1 ? 1 : newOpacity) : 0;
          }
        } else if (
          !ref.current.material.opacity ||
          ref.current.material.opacity > 0
        ) {
          ref.current.material.opacity = 0;
        }
      }
      resolve(true);
    });
  useFrame(() => updatePosition());

  const meshPosition = useMemo(() => {
    const k = 0.7;
    return [x * k, y * k, z * k];
  }, [x, y, z]);

  const planeArgs = useMemo(() => [300, 300, 1, 1], []);

  return (
    <mesh ref={ref} position={meshPosition}>
      <planeBufferGeometry attach="geometry" args={planeArgs} />
      <meshBasicMaterial
        attach="material"
        map={texture}
        side={THREE.DoubleSide}
        depthWrite={false}
        transparent={true}
      />
    </mesh>
  );
}

function WebSunFlare(props) {
  const { pano, variantIndex } = props;
  const lensflareLocation = useMemo(
    () => getLensflareLocation(pano, variantIndex),
    [pano, variantIndex]
  );
  return lensflareLocation ? (
    <SunFlare position={[...lensflareLocation]} />
  ) : null;
}

export default WebSunFlare;
