export const needLensflare = (pano, index) => {
  if (!pano || !pano.variants || index < 0) return false;
  const curVariant = pano.variants[index];
  if (!curVariant || curVariant.noLensFlare) return false;
  if (
    typeof curVariant.lensFlareLocation[0] !== 'number' ||
    typeof curVariant.lensFlareLocation[1] !== 'number' ||
    typeof curVariant.lensFlareLocation[2] !== 'number'
  ) {
    return false;
  }
  return true;
};

export const getLensflareLocation = (pano, index) => {
  if (!pano || !pano.variants || index < 0) return null;
  const curVariant = pano.variants[index];
  if (!curVariant || curVariant.noLensFlare) return null;
  const [x, y, z] = curVariant.lensFlareLocation;
  if (typeof x !== 'number' || typeof y !== 'number' || typeof z !== 'number') {
    return null;
  }
  return [x, y, z];
};
