import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import ErrorBoundary from './ErrorBoundary';
const eslintEval = window;

function PopupCustomHtml(props) {
  const { media } = props;
  const { customHtml } = media;
  const [isUrlReady, setIsUrlReady] = useState(false);

  const customHtmlData = useMemo(() => {
    const domData = _.isEmpty(customHtml.html) ? null : customHtml.html;
    return {
      libraryUrl: customHtml.libraryUrl,
      dom: domData,
      scripts: customHtml.scripts,
    };
  }, [customHtml.libraryUrl, customHtml.html, customHtml.scripts]);

  useEffect(() => {
    const script = document.createElement('script');

    if (_.isEmpty(customHtmlData.libraryUrl)) return;
    else {
      script.src = customHtmlData.libraryUrl;
      document.body.appendChild(script);
      script.onload = () => setIsUrlReady(true);
    }

    return () => {
      script.remove();
    };
  }, [customHtmlData.libraryUrl]);

  useEffect(() => {
    if (_.isEmpty(customHtmlData.scripts)) return;
    else {
      if (!_.isEmpty(customHtmlData.libraryUrl) && isUrlReady) {
        customHtmlData.scripts.forEach((s) => {
          try {
            eslintEval.eval(s);
          } catch (error) {
            console.log({ error });
          }
        });
      } else if (_.isEmpty(customHtmlData.libraryUrl)) {
        customHtmlData.scripts.forEach((s) => {
          try {
            eslintEval.eval(s);
          } catch (error) {
            console.log({ error });
          }
        });
      }
    }
  }, [customHtmlData.scripts, customHtmlData.libraryUrl, isUrlReady]);

  const createMarkup = (customHtmlDom) => {
    return { __html: customHtmlDom };
  };

  return (
    <div className="Popup-content Popup-content-iframe">
      <div className="Popup-content-wp">
        {customHtmlData.dom && (
          <ErrorBoundary>
            <div
              className="html-embeded-content"
              dangerouslySetInnerHTML={createMarkup(customHtmlData.dom)}
            ></div>
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
}

export default PopupCustomHtml;
