import React from 'react';

function IconMenu(props) {
  const color = props.color || '#fff';
  return (
    <svg
      className="sn-icon sn-icon-menu"
      data-name="Icon Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.59 23.91"
    >
      <rect fill={color} x="9.95" y="20" width="25.64" height="3.89" />
      <path
        fill={color}
        d="M941.91,548.39c.11,0,0,.08,0,.2v3.48c0,.12.15.2.2.22h4.66v-3.89Z"
        transform="translate(-941.87 -528.38)"
      />
      <rect fill={color} x="9.95" y="10" width="25.64" height="3.89" />
      <path
        fill={color}
        d="M941.91,538.39c.11,0,0,.08,0,.2v3.48c0,.12.15.2.2.22h4.66v-3.89Z"
        transform="translate(-941.87 -528.38)"
      />
      <rect fill={color} x="9.95" width="25.64" height="3.89" />
      <path
        fill={color}
        d="M941.91,528.39c.11,0,0,.08,0,.2v3.48c0,.12.15.2.2.22h4.66v-3.89Z"
        transform="translate(-941.87 -528.38)"
      />
    </svg>
  );
}

export default IconMenu;
