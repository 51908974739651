import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import configs from 'configs';
import SocialPopup from 'containers/socialPopup';
import ThreeMap from 'containers/threedMap';

import HotspotPopup from '../mediaPopup';

const PopupHandler = (props) => {
  const { hotspotId } = useParams();

  const { media } = useSelector((state) => state.json);

  const isConfigRoute = useMemo(
    () => [configs.mapRoute, configs.socialRoute].includes(hotspotId),
    [hotspotId]
  );

  const currentHotspot = useMemo(() => {
    if (!hotspotId || isConfigRoute) return;
    const currentMedia = media.find((m) => m.id === hotspotId) || {};
    return {
      id: currentMedia.friendlyName,
      _id: currentMedia._id,
      media: currentMedia,
    };
  }, [hotspotId, isConfigRoute, media]);

  if (!hotspotId) return null;

  if (hotspotId === configs.socialRoute) {
    return <SocialPopup />;
  }
  if (hotspotId === configs.mapRoute) {
    return <ThreeMap />;
  }
  if (currentHotspot) {
    return <HotspotPopup media={currentHotspot.media} {...props} />;
  }

  return null;
};

export default PopupHandler;
