import React, { useEffect, useState } from 'react';
import './spinner.scss';
import loadingGif from './skynav_loading_logo.gif';
import { useSelector } from 'react-redux';

const Spinner = ({ onClosing, onClosed }) => {
  const { sdLoaded: loaded } = useSelector((state) => state);

  const [alpha, setAlpha] = useState(1);
  const [opacity, setOpacity] = useState(1);
  const [enabled, setEnabled] = useState(true);
  useEffect(() => {
    if (loaded) {
      console.log('loaded');
      setAlpha(0);
      setTimeout(() => {
        setOpacity(0);
        onClosing && onClosing();
        setTimeout(() => {
          setEnabled(false);
          onClosed && onClosed();
          window.logMessage('spinner closed');
        }, 1100);
      }, 1100);
    }
    // eslint-disable-next-line
  }, [loaded]);

  if (!enabled) {
    return '';
  }

  return (
    <div
      className="loading-spinner"
      style={{ background: `rgba(255,255,255,${alpha})` }}
    >
      <div className="loading-logo" style={{ opacity }}>
        <img src={loadingGif} alt="SKYNAV Loading Logo" />
      </div>
    </div>
  );
};

export default Spinner;
