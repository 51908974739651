import { useEffect, useState } from 'react';
import { debounce } from 'utils/functionHelper';

function useTogglePopup(func) {
  const [mounted, setMounted] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const handleClosePopup = () => {
    if (btnDisabled) return;
    setMounted(false);
    setBtnDisabled(true);

    debounce(() => {
      if (func) func();
    }, 2000)();
  };
  useEffect(() => {
    setMounted(true);
  }, []);
  return [mounted, handleClosePopup];
}

export default useTogglePopup;
