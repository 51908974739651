import { isArray } from 'lodash';
import HOTSPOT_TYPE from '../consts/hotspotType.const';
import MEDIA_TYPE from '../consts/mediaType.const';
import SCENE_TYPE from '../consts/sceneType.const';
import {
  handleUpdateArrayFeaturedMedia,
  handleUpdateObjectFeaturedMedia,
} from './mediaHelper';

export const getPathFromJsonUrl = (url) => {
  var path = url.split('/');
  path.pop();
  return path.join('/');
};

export const getNewImgUrl = (path, imgUrl) => {
  let newUrl = imgUrl.trim();
  if (newUrl.indexOf('http://') === 0 || newUrl.indexOf('https://') === 0) {
    return newUrl;
  }
  if (newUrl[0] !== '/') {
    newUrl = '/' + newUrl;
  }
  return path + newUrl;
};

export const convertImg = (img, path) => {
  let newImg = img;
  if (newImg) {
    if (newImg.constructor === String) {
      newImg = getNewImgUrl(path, newImg);
    }
    if (newImg.constructor === Array) {
      newImg = newImg.map((img) => getNewImgUrl(path, img));
    }
  }
  return newImg;
};

export const formatJsonImgUrl = (url, jsonData) => {
  if (jsonData.panoList) {
    const path = getPathFromJsonUrl(url);
    if (jsonData.brand && jsonData.brand.logo) {
      jsonData.brand.logo = convertImg(jsonData.brand.logo, path);
    }
    jsonData.panoList.forEach((p) => {
      Object.keys(p.images).forEach((type) => {
        if (p.images[type]) {
          Object.keys(p.images[type]).forEach((res) => {
            if (p.images[type][res]) {
              if (p.images[type][res].constructor === Array) {
                p.images[type][res].forEach((img, index) => {
                  p.images[type][res][index] = convertImg(img, path);
                });
              } else {
                p.images[type][res] = convertImg(p.images[type][res], path);
              }
            }
          });
        }
      });
      if (p.hotspots) {
        p.hotspots.forEach((h) => {
          h.icons.unread = convertImg(h.icons.unread, path);
          h.icons.read = convertImg(h.icons.read, path);
          if (h.thumb) {
            h.thumb = convertImg(h.thumb, path);
          }
        });
      }
    });
  }
  return jsonData;
};

export const formatOldJson = (json) => {
  const data = JSON.parse(JSON.stringify(json));
  if (!data.panoList) {
    return data;
  }
  const newJson = {};
  newJson.media = [];
  newJson.scenes = data.panoList.map((p) => {
    const hotspots = [];
    p.hotspots.forEach((h) => {
      const hp = {
        id: h.id,
        type: h.type === 'pano' ? HOTSPOT_TYPE.SCENE : HOTSPOT_TYPE.MEDIA,
        location: [h.location.x, h.location.y, h.location.z],
        distance: h.type === 'pano' ? h.distance : null,
        sceneLinkId: h.type === 'pano' ? h.panoId : null,
        mediaLinkId: h.type !== 'pano' ? h.id : null,
      };
      hotspots.push(hp);
      if (h.type !== 'pano' && !newJson.media.find((m) => m.id === h.id)) {
        newJson.media.push({
          id: h.id,
          title: h.title,
          friendlyName: h.id,
          type:
            h.type === 'youtube-video' ? MEDIA_TYPE.YOUTUBE : MEDIA_TYPE.IMAGE,
          text: h.type === 'info-box' ? h.content.description : null,
          imageURL: h.type === 'info-box' ? h.content.images[0] : null,
          readMoreURL: h.type === 'info-box' ? h.content.moreInfoLink : null,
          youtubeID: h.type === 'youtube-video' ? h.content : null,
        });
      }
    });
    const scene = {
      id: p.id,
      title: p.title,
      friendlyName: p.id,
      type: String(p.type || SCENE_TYPE.GROUND).toUpperCase(),
      isDefault: p.default || false,
      location: '',
      viewingAngle: 70,
      defaultOrientation: [
        p.defaultOrientation.x,
        p.defaultOrientation.y,
        p.defaultOrientation.z,
      ],
      coordinates: [
        (p.coordinates && p.coordinates.lng) || 0,
        (p.coordinates && p.coordinates.lat) || 0,
        (p.coordinates && p.coordinates.alt) || 0,
      ],
      lensFlareLocation: [p.lensflare.x, p.lensflare.y, p.lensflare.z],
      noLensFlare: !(p.lensflare || p.lensflare.x) ? true : false,
      yaw: '',
      elevation: '',
      previewImgUrl: p.thumb,
      cubeMapImages: {
        size1024: p.images.cube.low,
        size2048: p.images.cube.high,
      },
      hotspots: hotspots,
    };
    return scene;
  });
  newJson.menu = data.menu.items.map((m, i) => ({
    name: m.title,
    order: i,
    scenes: m.panos.map((p, j) => ({
      id: p.id,
      order: j,
    })),
  }));
  return newJson;
};

const fromCustomData = (media) => {
  if (media.type === MEDIA_TYPE.CUSTOM) {
    let customData = null;
    try {
      customData = JSON.parse(media.customData);
      //Parse TypeForm
      if (customData.type === MEDIA_TYPE.TYPEFORM && customData.url) {
        media.type = MEDIA_TYPE.TYPEFORM;
        media.typeForm = {
          url: customData.url,
        };
      }
      // Parse Logo Override
      else if (customData.type === MEDIA_TYPE.LOGO_OVERRIDE && customData.url) {
        media.type = MEDIA_TYPE.LOGO_OVERRIDE;
        media.logoOverride = {
          url: customData.url,
        };
      }

      // Parse SKYtag
      else if (
        customData.type === MEDIA_TYPE.SKYTAG &&
        customData.description
      ) {
        media.type = MEDIA_TYPE.SKYTAG;
        media.skytag = {
          description: customData.description,
          readMoreURL: customData.readMoreURL || null,
        };
      }

      // Parse Custom Html
      else if (customData.type === MEDIA_TYPE.CUSTOM_HTML) {
        media.type = MEDIA_TYPE.CUSTOM_HTML;
        media.customHtml = {
          html: customData.html,
          libraryUrl: customData.libraryUrl,
          scripts: customData.scripts,
        };
      } else if (customData.type === MEDIA_TYPE.SKETCH_FAB) {
        media.type = MEDIA_TYPE.SKETCH_FAB;
        media.sketchFab = {
          src: customData.sketchFabUrl,
        };
      }

      // AUDIO
      else if (customData.type === MEDIA_TYPE.AUDIO) {
        media.type = MEDIA_TYPE.AUDIO;
        media.soundUrl = customData.soundUrl;
        media.loop = customData.loop;
      }
      // VIDEO MEDIA
      // Old Vimeo type
      else if (customData.type === MEDIA_TYPE.VIMEO) {
        media.type = MEDIA_TYPE.VIMEO;
        media.videoUrl = customData.vimeoUrl;
        media.videoPlatform = MEDIA_TYPE.VIMEO;
        media.videoId = customData.vimeoId;
      }
      // New type Video
      else if (customData.type === MEDIA_TYPE.VIDEO) {
        media.type = MEDIA_TYPE.VIDEO;
        media.videoUrl = customData.videoUrl;
        media.videoPlatform = customData.videoPlatform;
        media.videoId = customData.videoId;
      }
      // Tour Audio
      else if (customData.type === MEDIA_TYPE.TOUR_AUDIO) {
        media.type = MEDIA_TYPE.TOUR_AUDIO;
        media.soundUrl = customData.soundUrl;
      }
    } catch (error) {
      console.log('error parsing custom type from api response', error);
    }
  } else if (media.type === MEDIA_TYPE.YOUTUBE) {
    media.type = MEDIA_TYPE.VIDEO;
    media.videoUrl = media.youtubeId;
    media.videoPlatform = MEDIA_TYPE.YOUTUBE;
    media.videoId = '';
  }
};

export const parseMediaCustom = (listMedia) => {
  if (listMedia) {
    listMedia.forEach((m) => fromCustomData(m));
  }
};

export const updateDefaultValues = (data) => {
  if (data.customer && data.tour) {
    data.customer.brandColor1 = data.customer.brandColor1 || 'white';
    data.tour.logoURL = data.tour.logoURL || data.customer.logoURL;
    data.tour.tagline = data.tour.tagline || data.customer.companyTagline;
    data.tour.menuHighlightColor = data.tour.menuHighlightColor || 'white';
    data.tour.lightboxBackgroundColor1 =
      data.tour.lightboxBackgroundColor1 || 'black';
    data.tour.lightboxBackgroundColor2 =
      data.tour.lightboxBackgroundColor2 || 'black';
    data.tour.lightboxBackgroundColor3 =
      data.tour.lightboxBackgroundColor3 || 'black';
    data.tour.lightboxHeaderColor = data.tour.lightboxHeaderColor || 'white';
    data.tour.audioAvailable = (data.media || []).find(
      (m) => m.type === MEDIA_TYPE.AUDIO
    )
      ? true
      : false;
    const angle = data.tour.defaultViewingAngle;
    data.tour.defaultViewingAngle = 20 > angle || angle > 120 ? 60 : angle;
  }
};

/**
 * Update featured media data
 * @param {Object} data tour json data
 * @return {void}
 *
 * Currently, Tour support 3 type of feature media
 * 1. map: MAPFLOORPLAN
 * 2. contact: CONTACTFORM
 * 3. audio: TOUR_AUDIO
 */
export const updateFeaturedMedia = (data) => {
  data.featuredMedia = {
    map: null,
    contact: null,
    audio: null,
  };
  data.featuredHotspots = [];

  if (data.featured) {
    /**
     * We support 2 type of data.featured
     *
     * 1. Array
     * ```js
     * featured: [{type: 'Map', id: 'xxxx'}]
     * ```
     *
     * 2. Object
     * ```js
     * featured: {map: mapId, others: [otherId]}
     * ```
     */
    if (isArray(data.featured)) {
      handleUpdateArrayFeaturedMedia(data);
    } else {
      handleUpdateObjectFeaturedMedia(data);
    }
  }
};

export const updateScenesAndHotspots = (data) => {
  data.scenes.forEach((scene) => {
    scene.sceneType = String(scene.type || SCENE_TYPE.GROUND).toUpperCase();
    scene.hotspots.forEach((h) => {
      if (h.type === HOTSPOT_TYPE.MEDIA) {
        h.media = data.media.find((m) => m.id === h.mediaLinkId) || null;
        if (h.media) {
          h._id = h.media.id;
          h.id = h.media.friendlyName;
          h.friendlyName = h.media.friendlyName;
        }
      } else if (h.type === HOTSPOT_TYPE.SCENE) {
        h.scene = data.scenes.find((s) => s.id === h.sceneLinkId) || null;
        if (h.scene) {
          h._id = h.scene.id;
          h.id = h.scene.friendlyName;
          h.friendlyName = h.scene.friendlyName;
        }
      }
    });
  });
};

export const updateMenuItemsWithScene = (data) => {
  data.menu.forEach((g) => {
    g.scenes.forEach((s) => {
      const scene = data.scenes.find((d) => d.id === s.id);
      if (scene) {
        window.logMessage('scene', { ...scene });
        if (scene.tourGroupId && data.groups) {
          const group = data.groups.find((g) => g.id === scene.tourGroupId);
          if (group) {
            s.groupId = group.friendlyName;
          }
        }
        s._id = s.id;
        s.id = scene.friendlyName;
        s.title = s.title || scene.title;
        s.friendlyName = scene.friendlyName;
        s.type = String(scene.type || SCENE_TYPE.GROUND).toUpperCase();
      }
    });
  });
};

export const replaceItemIdWithFriendlyName = (list = []) => {
  list.forEach((item) => {
    if (item.friendlyName) {
      item._id = item.id;
      item.id = item.friendlyName;
    }
  });
};

const replaceGroupIdWithFriendlyName = (data) => {
  if (!data.groups || !data.groups.length) {
    data.groups = [
      {
        _id: 'welcome',
        id: 'welcome',
      },
    ];
  }
  data.scenes.forEach((scene) => {
    let group = data.groups.find((g) => g.id === scene.tourGroupId);
    if (!group) {
      group = data.groups.find((g) => g.isDefault) || data.groups[0];
    }
    scene.groupId = group ? group.friendlyName : 'welcome';
  });
};

export const replaceLogoOverrideIdToURL = (data) => {
  data.scenes.forEach((scene) => {
    const mediaLogo = data.media.find((m) => m._id === scene.mediaLogoOverride);
    if (mediaLogo && mediaLogo.logoOverride && mediaLogo.logoOverride.url) {
      scene.mediaLogoOverride = mediaLogo.logoOverride.url;
    } else {
      scene.mediaLogoOverride = null;
    }
  });
};

export const mapSceneVariantsToScene = (scene) => {
  let defaultIndex = 0;
  if (!scene.sceneVariants) {
    scene.sceneVariants = [];
  }
  const newVariants = [
    {
      id: scene.id,
      title: scene.variantTitle,
      order: scene.variantOrder,
      cubeMapImages: scene.cubeMapImages,
      isDefault: false,
      isPrimary: true,
      lensFlareLocation: scene.lensFlareLocation,
      noLensFlare: scene.noLensFlare,
      previewImgUrl: scene.previewImgUrl,
      useDefaultJumpSpot: true,
      useDefaultLensflare: true,
      useDefaultMediaSpot: true,
    },
    ...scene.sceneVariants.map((v, index) => {
      if (v.isDefault) {
        defaultIndex = index + 1;
      }
      return {
        ...v,
        isPrimary: false,
        lensFlareLocation: v.useDefaultLensflare
          ? scene.lensFlareLocation
          : v.lensflare,
        noLensFlare: v.useDefaultLensflare ? scene.noLensFlare : v.noLensFlare,
      };
    }),
  ];

  if (!newVariants.find((v) => v.isDefault)) {
    newVariants[0].isDefault = true;
  }
  return {
    variants: newVariants,
    index: defaultIndex,
  };
};

export const replaceTitleToFriendlyName = (title) => {
  return title.trim().toLowerCase().replaceAll(' ', '-');
};

export const mapSceneAndVariantsToArray = (scenes) => {
  scenes.forEach((s) => {
    const { variants, index } = mapSceneVariantsToScene(s);
    s.variants = variants;
    s.defaultVariantIndex = index;
  });
};

/**
 * Update Social Data for Json.Tour from Customer
 * @param {object} data Json Data
 */
export const updateSocialData = (data) => {
  const { tour, customer } = data;
  data.tour = {
    ...tour,
    websiteUrl: tour.websiteUrl || customer.websiteURL || '',
    facebookUrl: tour.facebookUrl || customer.facebookURL || '',
    instagramUrl: tour.instagramUrl || customer.instagramURL || '',
    twitterUrl: tour.twitterUrl || customer.twitterURL || '',
    snapchatUrl: tour.snapchatUrl || customer.snapchatURL || '',
    youTubeUrl: tour.youTubeUrl || customer.youTubeURL || '',
    pinterestUrl: tour.pinterestUrl || customer.pinterestURL || '',
  };
};

export const updateItemId = (data) => {
  replaceItemIdWithFriendlyName(data.scenes);
  replaceItemIdWithFriendlyName(data.media);
  replaceLogoOverrideIdToURL(data);
  mapSceneAndVariantsToArray(data.scenes);
  replaceGroupIdWithFriendlyName(data);
  replaceItemIdWithFriendlyName(data.groups);
};
