import React from 'react';

function IconResize(props) {
  const color = props.color || '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 56.56"
      height="2rem"
    >
      <path
        opacity={0.5}
        d="M982.13,512c-8.55-.09-36-.15-72.26-.19v56.55c38.39,0,66.85,0,71.44-.06,15.87-.14,28.42-12.54,28.56-27.95A28.05,28.05,0,0,0,982.13,512Z"
        transform="translate(-909.87 -511.82)"
      />
      <path
        fill={color}
        d="M982.13,512c-8.33-.09-34.61-.15-69.44-.19v56.55c37,0,64.14,0,68.62-.06,15.87-.14,28.42-12.54,28.56-27.95A28.05,28.05,0,0,0,982.13,512Zm-25.21,40.8a10.15,10.15,0,0,1-.08,1.17,1,1,0,0,1-.71.65l-.6,0H945V544.35l0-.26c0-.18,0-.37,0-.55a1,1,0,0,1,.77-.75c.85,0,1.61,0,2.32-.06h7.21a5.62,5.62,0,0,1,.76,0,.92.92,0,0,1,.79.83h0c0,.34,0,.67,0,1Zm2.19-10.75a.84.84,0,0,1-1.06.09,1.17,1.17,0,0,1-.68-1.1.55.55,0,0,1,.13-.38l.15-.17c.35-.39.7-.78,1.06-1.15l3.42-3.47,1.31-1.32.14-.15a.25.25,0,0,0,0-.27.24.24,0,0,0-.22-.15l-.21,0h-.42c-.38,0-.76,0-1.13,0a1.13,1.13,0,0,1-.45-2.16,1.62,1.62,0,0,1,.54-.08h5a1.41,1.41,0,0,1,.59.11.94.94,0,0,1,.57.9c0,.6,0,1.2,0,1.81,0,.83,0,1.67,0,2.5,0,.27,0,.53,0,.79s0,.31,0,.46a.82.82,0,0,1-.34.59,1.15,1.15,0,0,1-1.55-.08,1,1,0,0,1-.32-.79c0-.37,0-.75,0-1.13v-.43s0-.08,0-.12v-.07a.26.26,0,0,0-.16-.21.25.25,0,0,0-.25.05l-.06.06-.13.11-1.59,1.58-3.2,3.16c-.31.3-.63.59-.95.88Zm15.1,12.63H961l0,0-.22-.12a1.14,1.14,0,0,1-.58-.95,1.18,1.18,0,0,1,.51-1,1.1,1.1,0,0,1,.62-.19h10.35a.25.25,0,0,0,.25-.25V528a.25.25,0,0,0-.25-.25H947.49a.26.26,0,0,0-.25.25v2.92c0,2.1,0,4.21,0,6.31,0,.18,0,.36,0,.53a4.71,4.71,0,0,0,0,.53,1,1,0,0,1-.83.8,3.4,3.4,0,0,1-.68-.05c-.3-.08-.48-.33-.69-.68l-.22.07h0l.24-.09v-4.82c0-.07,0-.14,0-.21v-7.88h29.25Z"
        transform="translate(-909.87 -511.82)"
      />
      <path
        fill={color}
        d="M954.36,545H953c-1.78,0-3.62,0-5.45,0a.26.26,0,0,0-.24.25v6.86a.25.25,0,0,0,.25.25h6.84a.25.25,0,0,0,.25-.25v-6.86A.25.25,0,0,0,954.36,545Z"
        transform="translate(-909.87 -511.82)"
      />
    </svg>
  );
}

export default IconResize;
