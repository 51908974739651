import React from 'react';

import { connect } from 'react-redux';

import { initTracking } from 'gaTracking';
import {
  goToScene,
  toggleMenu,
  setTourData,
  setPanoData,
  showHotspot,
  toggleLanding,
  setAudioAvailable,
} from 'store/actions';
import { loadTourJson } from 'utils/apiHelper';
import PanoErrorBoundary from 'components/errors/panoError';
import DomErrorBoundary from 'components/errors/domError';
import configs from 'configs';

import World3D from './world3d';
import World2D from './world2d';
import RouteHandlers from './routes';

import LandingSpinner from '../landing/spinner';
import LandingIntro, { INTRO_STEP } from '../landing/intro';

import './style.scss';

const CONFIG_DELAY = 10000;

class World extends React.Component {
  state = {
    initiating: true,
    json: null,
    jsonInvalid: false,
    tour: null,
    autoRotate: false,
    currentPano: null,
    isPanning: false,
    spinner: true,
    showSpinner: true,
    showIntro: false,
    introStatus: 'in',
    defaultScene: null,
    popupOpen: false,
    variantIndex: 0,
  };
  isPanning = false;
  panCounter = 0;
  disableRotate = false;
  panTimer = null;
  worldEl = null;
  trackDeepLink = null;

  componentDidMount() {
    const { tourId, groupId, sceneId, hotspotId } = this.props.match.params;
    window.logMessage('params', { tourId, groupId, sceneId, hotspotId });
    if (sceneId || hotspotId) {
      this.trackDeepLink = {
        tour: tourId,
        scene: sceneId,
        hotspot: hotspotId,
      };
    }
    this.loadData(tourId);
  }

  componentDidUpdate(preProps, preState) {
    const { groupId, sceneId, hotspotId } = this.props.match.params;
    if (sceneId && sceneId !== preProps.match.params.sceneId) {
      this.selectSceneById(groupId, sceneId);
    }

    const { currentScene } = this.props;
    if (
      currentScene.id &&
      (preProps.currentScene.id !== currentScene.id ||
        preProps.currentScene.groupId !== currentScene.groupId)
    ) {
      this.selectSceneById(currentScene.groupId, currentScene.id);
    }
    if (!hotspotId && !preProps.sdLoaded && this.props.sdLoaded) {
      this.toggleIntro(true);
    }
    if (hotspotId && preState.showSpinner && !this.state.showSpinner) {
      this.setState({ introStatus: 'out' });
    }
  }

  navigateToRoute = (groupId = null, sceneId = null, hotspotId = null) => {
    const { tourId } = this.props.match.params;
    const routeParams = [];
    if (!configs.singleTour) {
      routeParams.push(tourId || 'SKYNAV');
    }
    if (groupId) {
      routeParams.push(groupId);
      if (sceneId) {
        routeParams.push(sceneId);
        if (hotspotId) {
          routeParams.push(hotspotId);
        }
      }
    }
    this.props.history.push(`/${routeParams.join('/')}`);
  };

  dataArrived = (json) => {
    window.logMessage('json', json);
    if (!json.cubeMap) {
      const { tour } = json;
      initTracking(
        tour.googleAnalyticTrackingID,
        this.trackDeepLink,
        tour.facebookPixelID
      );
      if (tour.audioAvailable) {
        this.props.setAudioAvailable(true);
      }
      const autoRotate = Boolean(tour && tour.autoRotate);
      const defaultScene = this.getDefaultScene(json);
      this.setState(
        { json, tour, autoRotate, defaultScene },
        this.selectDefaultScene
      );
      document.title = tour.name ? `${tour.name} | SKYNAV` : 'SKYNAV';
    } else {
      this.setState({ jsonInvalid: true });
    }
  };

  loadData = (tourId = null) => {
    loadTourJson(tourId).then(
      ({ json, tour }) => {
        if (json) {
          window.logMessage('Tour data:', tour, json);
          this.props.setTourData(json);
          this.dataArrived(json);
        } else {
          this.props.history.push(`/not-found`);
        }
      },
      (err) => {
        console.error('got error', err);
        this.props.history.push(`/not-found`);
      }
    );
  };

  getDefaultScene = ({ scenes, groups }) => {
    let defaultScene = scenes.find((p) => p.isDefault);
    if (!defaultScene) {
      const defaultGroup = groups.find((g) => g.isDefault);
      if (defaultGroup) {
        defaultScene = scenes.find((s) => s.groupId === defaultGroup.id);
      }
    }
    return defaultScene;
  };

  selectDefaultScene = () => {
    const { groupId, sceneId } = this.props.match.params;
    const {
      defaultScene,
      json: { scenes, groups },
    } = this.state;
    let group = null;
    if (groupId) {
      group = groups.find((g) => g.id === groupId);
      if (!group) {
        group = groups.find((g) => g.isDefault) || groups[0];
      }
      if (group) {
        let scene = null;
        if (sceneId) {
          scene = scenes.find((s) => s.id === sceneId);
        }
        if (!scene) {
          scene = scenes.find((s) => s.groupId === group.id && s.isDefault);
        }
        if (!scene) {
          scene = scenes.find((s) => s.groupId === group.id);
        }
        if (scene) {
          return this.selectSceneById(scene.groupId, scene.id);
        }
      }
    }
    if (defaultScene) {
      return this.selectSceneById(defaultScene.groupId, defaultScene.id);
    }
    // group not found and there is no group
    this.selectSceneById(null); // which will redirect to 404
  };

  selectSceneById = (gId, sId) => {
    if (!gId) {
      console.error('missing gId', gId);
      return this.props.history.push('/not-found');
    }
    const { groups } = this.state.json;
    const group = groups.find((s) => s.id === gId);
    if (!group) {
      console.error('missing group', group);
      return this.props.history.push('/not-found');
    }
    const { groupId, sceneId } = this.props.match.params;
    window.logMessage('selectSceneById', gId, sId);
    if (gId !== groupId || sceneId !== sId) {
      this.navigateToRoute(gId, sId);
    } else {
      // groupId and sceneId did NOT change
      // need to select scene and check to open lightbox
      const { currentScene } = this.props;
      if (currentScene.id === sId && currentScene.groupId === gId) {
        this.loadSceneById(gId, sId);
      } else {
        this.props.goToScene(gId, sId);
      }
    }
  };

  loadSceneById = (groupId, sceneId) => {
    const { scenes } = this.state.json;
    const scene =
      scenes.find((p) => p.id === sceneId && p.groupId === groupId) || null;
    if (scene) {
      this.setScene(scene);
    } else {
      console.error('missing scene', groupId, sceneId);
      this.props.history.push('/not-found');
    }
  };

  setScene = (currentPano) => {
    window.logMessage('setScene', currentPano);
    this.setState({
      currentPano,
      variantIndex: currentPano.defaultVariantIndex,
    });
    if (this.state.initiating) {
      this.setState({ initiating: false });
    }
  };

  toggleRotate = (isAutoRotate) => {
    if (isAutoRotate === undefined) {
      this.disableRotate = false;
      this.resetTimer();
    }
    this.setState({
      autoRotate:
        isAutoRotate !== undefined ? isAutoRotate : !this.state.autoRotate,
    });
  };

  onPointerClick = () => {
    if (this.props.isNavOpen) {
      this.props.toggleMenu(false);
    }
  };

  onPointerDown = () => {
    if (!this.isPanning) {
      this.isPanning = true;
      this.setState({ isPanning: true });
    }
  };

  onPointerMove = (e) => {
    if (this.isPanning) {
      if (this.props.isNavOpen) {
        this.props.toggleMenu(false);
      }
      this.panCounter += 1;
      if (this.panCounter > 3) {
        if (this.state.autoRotate) {
          this.disableRotate = true;
          this.toggleRotate(false);
        }
        if (this.disableRotate) {
          this.resetTimer();
        }
      }
    }
  };

  onPointerUp = () => {
    if (this.isPanning) {
      this.isPanning = false;
      this.setState({ isPanning: false });
      this.panCounter = 0;
      if (this.disableRotate && !this.panTimer) {
        this.panTimer = setTimeout(() => {
          this.toggleRotate(true);
          this.disableRotate = false;
          this.panTimer = null;
        }, CONFIG_DELAY);
      }
    }
  };

  resetTimer = () => {
    if (this.panTimer) {
      clearTimeout(this.panTimer);
      this.panTimer = null;
    }
  };

  pointerEvents = {
    onClick: this.onPointerClick,
    onPointerDown: this.onPointerDown,
    onPointerUp: this.onPointerUp,
    onPointerMove: this.onPointerMove,
  };

  onImageLoaded = (key) => {
    if (!this.state[key]) {
      this.setState({ [key]: true });
    }
  };

  toggleIntro = (showIntro) => {
    if (showIntro !== this.state.showIntro) {
      this.setState({ showIntro });
      this.props.toggleLanding(showIntro);
    }
  };

  onUpdateIntroStep = (step) => {
    if (step === INTRO_STEP.FADING_IN) {
      this.setState({ introStatus: 'in' });
    } else if (step === INTRO_STEP.FADING_OUT) {
      this.setState({ introStatus: 'out' });
    } else if (step === INTRO_STEP.FADED_OUT) {
      this.toggleIntro(false);
    }
  };

  renderLanding = () => {
    const { defaultScene } = this.state;
    const { showIntro, showSpinner } = this.state;
    return (
      <>
        {showIntro && (
          <LandingIntro
            spinnerVisible={showSpinner}
            image={defaultScene ? defaultScene.previewImgUrl : null}
            onUpdate={this.onUpdateIntroStep}
          />
        )}
        <LandingSpinner
          onClosing={() => this.setState({ spinner: false })}
          onClosed={() => this.setState({ showSpinner: false })}
        />
      </>
    );
  };

  render() {
    const {
      json,
      currentPano,
      isPanning,
      autoRotate,
      showSpinner,
      spinner,
      showIntro,
      introStatus,
      variantIndex,
    } = this.state;
    const { currentHotspot, panoMode, tour, customer } = this.props;
    return (
      <div className="World">
        {json && (
          <>
            <PanoErrorBoundary>
              <World3D
                ref={(ref) => {
                  this.worldEl = ref;
                }}
                json={json}
                tour={tour}
                showSpinner={spinner}
                showIntro={showIntro}
                introClosed={!spinner && introStatus === 'out'}
                autoRotate={
                  showIntro ||
                  (!showSpinner && !showIntro && !currentHotspot && autoRotate)
                }
                customer={customer}
                panoMode={panoMode}
                isPanning={isPanning}
                currentPano={currentPano}
                onSwitchPano={({ groupId, id }) =>
                  this.selectSceneById(groupId, id)
                }
                pointerEvents={this.pointerEvents}
                popupOpen={currentHotspot}
                variantIndex={variantIndex}
                history={this.props.history}
              />
            </PanoErrorBoundary>
            <DomErrorBoundary>
              <World2D
                currentPano={currentPano}
                autoRotate={autoRotate}
                showSpinner={showSpinner}
                hotspotHistory={this.props.hotspotHistory}
                toggleRotate={() => this.toggleRotate()}
                selectSceneById={this.selectSceneById}
                toggleMap={this.toggleMap}
                toggleSocial={this.toggleSocial}
                toggleFeatured={this.toggleFeatured}
                introStatus={introStatus}
                onClickPoweredBy={() => {
                  this.toggleIntro(true);
                }}
                showIntro={showIntro}
                onChangeVariantIndex={(index) =>
                  this.setState({ variantIndex: index })
                }
                variantIndex={variantIndex}
              />
            </DomErrorBoundary>
            <RouteHandlers />
          </>
        )}
        {this.renderLanding()}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentHotspot: store.currentHotspot,
  currentScene: store.currentScene,
  isNavOpen: store.menu.isOpen,
  isMapOpen: store.menu.isoMetricMap,
  mapPopupOpen: store.menu.isoMetricMap,
  socialPopupOpen: store.menu.social,
  contactPopupOpen: store.menu.contact,
  hotspotHistory: store.hotspotHistory,
  panoMode: store.panoMode,
  customer: (store.json && store.json.customer) || null,
  tour: store.tour,
  shouldBlur: (store.tour && store.tour.lightboxBlur) || false,
  featuredHotspots: (store.json && store.json.featuredHotspots) || [],
  sdLoaded: store.sdLoaded,
});

const mapDispatchToProps = {
  goToScene,
  setTourData,
  setPanoData,
  toggleMenu,
  showHotspot,
  toggleLanding,
  setAudioAvailable,
};

export default connect(mapStateToProps, mapDispatchToProps)(World);
