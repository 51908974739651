import React from 'react';
import './popup.iframe.scss';

const getMatterportEmbeddedIframe = (url) => `
  <iframe src="${url}" frameborder="0"></iframe>
`;

const getHtml = (url) => ({
  __html: getMatterportEmbeddedIframe(url),
});

function PopupMatterport({ media }) {
  window.logMessage('media', media);
  if (!media || !media.matterport || !media.matterport.url) {
    return null;
  }
  return (
    <div className={`Popup-content Popup-content-iframe`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={getHtml(media.matterport.url)}
        ></div>
      </div>
    </div>
  );
}

export default PopupMatterport;
