import React from 'react';

function IconAudio(props) {
  const color = props.color || '#fff';
  const muted = props.muted || false;
  return (
    <svg
      className="sn-icon sn-icon-audio"
      data-name="Icon Audio"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <rect fill="none" width="64" height="64" />
      {muted ? (
        <>
          <path
            fill={color}
            d="M964.52,518.2a3.75,3.75,0,0,0-3.65-.09Q955.41,521,950,524l-6.66,3.59a1.61,1.61,0,0,1-.75.19h-4.43a3.68,3.68,0,0,0-3.88,3.89v17.19a3.69,3.69,0,0,0,3.86,3.85h4.49a1.33,1.33,0,0,1,.58.13l7.78,4.19,3.15,1.71c2.21,1.19,4.42,2.39,6.64,3.57a3.79,3.79,0,0,0,5.65-3.37q0-6.63,0-13.25v-17c0-.74,0-1.47,0-2.21,0-1.69,0-3.44,0-5.16A3.76,3.76,0,0,0,964.52,518.2Zm-2.17,40.3-.45-.2a1,1,0,0,1-.21-.1l-15.51-8.38c-.54-.28-1.08-.57-1.61-.87a2.26,2.26,0,0,0-1.22-.3h-4.64l-.37,0V531.89l.33,0h4.66a2.54,2.54,0,0,0,1.28-.32l12.26-6.63,2.19-1.18,2.64-1.42a1.11,1.11,0,0,1,.19-.09l.46-.21Z"
            transform="translate(-927.69 -508.23)"
          />
          <path
            fill={color}
            d="M987.52,532.74a2,2,0,0,0-2.83,0l-4.71,4.7-4.7-4.7a2,2,0,0,0-2.83,2.82l4.71,4.71L972.45,545a2,2,0,1,0,2.83,2.82l4.7-4.7,4.71,4.7a2,2,0,0,0,2.83-2.82l-4.71-4.71,4.71-4.71A2,2,0,0,0,987.52,532.74Z"
            transform="translate(-927.69 -508.23)"
          />
        </>
      ) : (
        <>
          <path
            fill={color}
            d="M964.52,518.2a3.75,3.75,0,0,0-3.65-.09Q955.41,521,950,524l-6.66,3.59a1.61,1.61,0,0,1-.75.19h-4.43a3.68,3.68,0,0,0-3.88,3.89v17.19a3.69,3.69,0,0,0,3.86,3.85h4.49a1.33,1.33,0,0,1,.58.13l7.78,4.19,3.15,1.71c2.21,1.19,4.42,2.39,6.64,3.57a3.79,3.79,0,0,0,5.65-3.37q0-6.63,0-13.25v-17c0-.74,0-1.47,0-2.21,0-1.69,0-3.44,0-5.16A3.76,3.76,0,0,0,964.52,518.2Zm-2.17,40.3-.45-.2a1,1,0,0,1-.21-.1l-15.51-8.38c-.54-.28-1.08-.57-1.61-.87a2.26,2.26,0,0,0-1.22-.3h-4.64l-.37,0V531.89l.33,0h4.66a2.54,2.54,0,0,0,1.28-.32l12.26-6.63,2.19-1.18,2.64-1.42a1.11,1.11,0,0,1,.19-.09l.46-.21Z"
            transform="translate(-927.69 -508.23)"
          />
          <path
            fill={color}
            d="M984,535a20.06,20.06,0,0,0-5.17-8.92,2.11,2.11,0,0,0-1.49-.62,1.92,1.92,0,0,0-1.15.37,2,2,0,0,0-.21,3.11A16.18,16.18,0,0,1,979,547.16a16.54,16.54,0,0,1-3.14,4.45,2,2,0,0,0,.13,2.95,1.88,1.88,0,0,0,2.78-.1A20,20,0,0,0,984,535Z"
            transform="translate(-927.69 -508.23)"
          />
          <path
            fill={color}
            d="M976.85,539.57c0-.45-.08-1-.18-1.57a13.08,13.08,0,0,0-3.73-7.15,1.87,1.87,0,0,0-1.36-.6,2.06,2.06,0,0,0-.63.1,1.88,1.88,0,0,0-1.4,1.5,2,2,0,0,0,.67,2,8.15,8.15,0,0,1,1.94,3.06,9.16,9.16,0,0,1-2,9.82,2,2,0,0,0,0,2.91,2,2,0,0,0,2.89,0,13.26,13.26,0,0,0,3.87-9.55C976.88,539.9,976.87,539.75,976.85,539.57Z"
            transform="translate(-927.69 -508.23)"
          />
        </>
      )}
    </svg>
  );
}

export default IconAudio;
