import React from 'react';

function Icon() {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.66 32.61"
    >
      <path
        fill="#fff"
        d="M971.74,528.78a16.29,16.29,0,0,0-11.59-5H960a16.39,16.39,0,0,0-16.31,15.65,16.57,16.57,0,0,0,4.63,12,16.08,16.08,0,0,0,11.64,4.95h.23a16.39,16.39,0,0,0,16.15-16.15A15.93,15.93,0,0,0,971.74,528.78ZM960.15,553.4h-.23a13.31,13.31,0,0,1,0-26.61h.15a13.31,13.31,0,0,1,0,26.61Z"
        transform="translate(-943.64 -523.79)"
      />
      <path
        opacity={0.65}
        d="M970.66,529.82a14.85,14.85,0,0,0-10.53-4.53H960a14.87,14.87,0,0,0-14.81,14.2,15,15,0,0,0,4.21,10.91,14.57,14.57,0,0,0,10.56,4.5h.23a14.88,14.88,0,0,0,14.65-14.68A14.46,14.46,0,0,0,970.66,529.82Zm-10.7-3h.15c-.26,0-.51,0-.76,0C959.55,526.81,959.75,526.79,960,526.79Zm.19,26.61h-.23c-.24,0-.47,0-.7,0,.25,0,.51,0,.77,0s.52,0,.78,0C960.56,553.37,960.36,553.4,960.15,553.4Z"
        transform="translate(-943.64 -523.79)"
      />
      <g id="KaEoSY.tif">
        <path
          fill="#00acde"
          d="M946.73,540.52v-.93a13.39,13.39,0,0,1,13.85-12.88,15.1,15.1,0,0,1,2,.22,13.19,13.19,0,0,1,2.38.68,13.05,13.05,0,0,1,2.69,1.42,13.51,13.51,0,0,1,3.73,3.82A14,14,0,0,1,972.5,535a13.56,13.56,0,0,1,.78,2.64,14.54,14.54,0,0,1,.22,2.07,16.57,16.57,0,0,1-.06,1.73,15.38,15.38,0,0,1-.31,1.8,12.92,12.92,0,0,1-1.1,3,13.36,13.36,0,0,1-2.31,3.22,13.31,13.31,0,0,1-5,3.24,13.28,13.28,0,0,1-2.16.6,14.18,14.18,0,0,1-1.58.19l-.45,0h-.91a13.64,13.64,0,0,1-2.64-.35,13.28,13.28,0,0,1-3-1.11,13.5,13.5,0,0,1-6.46-7.3,12.32,12.32,0,0,1-.59-2.16,14.21,14.21,0,0,1-.2-1.58C946.74,540.82,946.73,540.67,946.73,540.52Zm6.43-4.6,0,0,1.73.91c.94.51,1.89,1,2.84,1.51l2.16,1.14a.08.08,0,0,0,.09,0L962.9,538l3.41-1.8.49-.27,0,0-2.36-1.37L960,532a.09.09,0,0,0-.11,0l-2.21,1.28-3.9,2.25Zm0,2.09v6.6a.16.16,0,0,0,.09.17l2.3,1.33L958.8,548l.15.08v-6.87a.07.07,0,0,0-.05-.08l-.49-.26-4.82-2.61Zm13.63,0-.08,0-5.62,3a.09.09,0,0,0-.05.08V548s0,0,0,.06l.1,0c.77-.45,1.54-.9,2.32-1.34l3.25-1.88a.13.13,0,0,0,.07-.13v-6.6Z"
          transform="translate(-943.64 -523.79)"
        />
        <path
          fill="#fff"
          d="M953.16,535.92l.65-.38,3.9-2.25,2.21-1.28a.09.09,0,0,1,.11,0l4.36,2.51,2.36,1.37,0,0-.49.27L962.9,538,960,539.51a.07.07,0,0,1-.09,0l-2.16-1.14c-.95-.5-1.9-1-2.84-1.51L953.2,536Z"
          transform="translate(-943.64 -523.79)"
        />
        <path
          fill="#fff"
          d="M953.18,538l.41.22,4.82,2.61.49.26a.07.07,0,0,1,.05.08v6.87l-.15-.08-3.23-1.86-2.3-1.33a.16.16,0,0,1-.09-.17V538Z"
          transform="translate(-943.64 -523.79)"
        />
        <path
          fill="#fff"
          d="M966.81,538.05v6.6a.13.13,0,0,1-.07.13l-3.25,1.88c-.78.44-1.55.89-2.32,1.34l-.1,0s0,0,0-.06v-6.78a.09.09,0,0,1,.05-.08l5.62-3Z"
          transform="translate(-943.64 -523.79)"
        />
      </g>
    </svg>
  );
}

export default Icon;
