import { useEffect, useMemo, useState } from 'react';

import { Vector2 } from 'three';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { gsap } from 'gsap';

import { TriangleBlurShader } from './shaders/triangleShader';
import { useBlur } from 'common/hooks';

extend({ EffectComposer, ShaderPass, RenderPass });

const RADIUS = 30;
const obj = { rad: RADIUS };

function TriangleBlurEffect(props) {
  const { onBlurStartChanging, onBlurChanged } = props;

  const blur = useBlur();

  const [isOn, setIsOn] = useState(true);
  const [rad, setRad] = useState(RADIUS);
  const { gl, scene, camera, size } = useThree();

  const [final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const finalComposer = new EffectComposer(gl);
    const fstPass = new ShaderPass(TriangleBlurShader, 'texture1');
    fstPass.uniforms.delta.value = new Vector2(0, RADIUS / size.height);
    const sndPass = new ShaderPass(TriangleBlurShader, 'texture1');
    sndPass.uniforms.delta.value = new Vector2(RADIUS / size.width, 0);
    finalComposer.addPass(renderScene);
    finalComposer.addPass(fstPass);
    finalComposer.addPass(sndPass);
    return [finalComposer];
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    final.setSize(size.width, size.height);
  }, [final, size]);

  useEffect(() => {
    window.logMessage('enabled', blur);
    if (final && isOn !== blur) {
      onBlurStartChanging && onBlurStartChanging();
      obj.rad = blur ? 0 : RADIUS;
      gsap.to(obj, {
        rad: blur ? RADIUS : 0,
        duration: 1.5,
        onUpdate: () => {
          setRad(obj.rad);
          if (obj.rad === 0 || obj.rad === RADIUS) {
            setIsOn(blur);
          }
        },
        onComplete: () => onBlurChanged && onBlurChanged(),
      });
    }
    // eslint-disable-next-line
  }, [final, blur, isOn]);

  useEffect(() => {
    if (final) {
      if (final.passes[1] instanceof ShaderPass) {
        final.passes[1].uniforms.delta.value.y = rad / size.height;
      }
      if (final.passes[2] instanceof ShaderPass) {
        final.passes[2].uniforms.delta.value.x = rad / size.width;
      }
    }
  }, [final, rad, size]);

  useFrame(() => {
    if (isOn || blur) {
      final.render();
    }
  }, 1);

  return null;
}

export default TriangleBlurEffect;
