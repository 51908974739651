import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import configs from './configs';

const gaTrackerNames = ['SKYNAV', 'Customer'];
const defaultGaOptions = { cookieFlags: 'secure;sameSite=none' };

export const init = (gaTrackingId, fbPixelId) => {
  const trackingGroup = [
    {
      trackingId: configs.gaTrackingId,
      gaOptions: {
        ...defaultGaOptions,
        name: gaTrackerNames[0],
      },
    },
  ];
  if (gaTrackingId) {
    trackingGroup.push({
      trackingId: gaTrackingId,
      gaOptions: {
        ...defaultGaOptions,
        name: gaTrackerNames[1],
      },
    });
  }
  ReactGA.initialize(trackingGroup, {
    debug: false,
    titleCase: false,
    alwaysSendToDefaultTracker: false,
    gaOptions: {
      ...defaultGaOptions,
    }
  });
  trackPageView(window.location.pathname + window.location.search);
  if (fbPixelId) {
    window.logMessage('fbPixelId', fbPixelId);
    // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(fbPixelId, advancedMatching, options);
    ReactPixel.pageView();
  }
};

export const trackPageView = (page) => ReactGA.pageview(page, gaTrackerNames);

export const trackEvent = (eventData) =>
  ReactGA.event(eventData, gaTrackerNames);

export const EVENT_TYPE = {
  SCENE: 'SceneView',
  SCENE_DEEPLINK: 'SceneView.DeepLink',
  SCENE_FROM_HOTSPOT: 'SceneView.HotspotClick',
  SCENE_FROM_MENU: 'SceneView.MenuClick',
  SCENE_FROM_MAP: 'SceneView.MapClick',
  MAP: 'MapView',
  MAP_DEEPLINK: 'MapView.DeepLink',
  HOTSPOT: 'HotspotView',
  HOTSPOT_DEEPLINK: 'HotspotView.DeepLink',
  HOTSPOT_FROM_HOTSPOT: 'HotspotView.HotspotClick',
};

export const trackEventByType = (type, id) => {
  const eventData = {
    action: type,
  };
  if (
    type === EVENT_TYPE.SCENE ||
    type === EVENT_TYPE.SCENE_DEEPLINK ||
    type === EVENT_TYPE.SCENE_FROM_HOTSPOT ||
    type === EVENT_TYPE.SCENE_FROM_MENU ||
    type === EVENT_TYPE.SCENE_FROM_MAP
  ) {
    eventData.category = 'Scene';
    eventData.label = `Scene-ID: ${id || ''}`;
  }
  if (
    type === EVENT_TYPE.HOTSPOT ||
    type === EVENT_TYPE.HOTSPOT_DEEPLINK ||
    type === EVENT_TYPE.HOTSPOT_FROM_HOTSPOT
  ) {
    eventData.category = 'Hotspot';
    eventData.label = `Hotspot-ID: ${id || ''}`;
  }
  if (type === EVENT_TYPE.MAP || type === EVENT_TYPE.MAP_DEEPLINK) {
    eventData.category = 'Map';
    eventData.label = `Viewing Map`;
  }
  trackEvent(eventData);
};

export const initTracking = (gaTrackingId, trackDeepLink, fbPixelId) => {
  init(gaTrackingId, fbPixelId);
  if (trackDeepLink) {
    if (trackDeepLink.hotspot) {
      if (trackDeepLink.hotspot === configs.mapRoute) {
        trackEventByType(EVENT_TYPE.MAP_DEEPLINK, null);
      } else {
        trackEventByType(EVENT_TYPE.HOTSPOT_DEEPLINK, trackDeepLink.hotspot);
      }
    } else {
      trackEventByType(EVENT_TYPE.SCENE_DEEPLINK, trackDeepLink.scene);
    }
  }
};
