import React from 'react';

const Icon = (props) => {
  const bgColor = props.color || 'white';
  return (
    <svg
      data-name="Icon Contact"
      className="sn-icon sn-icon-contact"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49.09 49.49"
    >
      <path
        fill={bgColor}
        d="M979.93,528.75h-2.51v3h2.51a1.54,1.54,0,0,1,1.55,1.55v20a1.55,1.55,0,0,1-1.55,1.55H967a1.47,1.47,0,0,0-.65.15L955,560.42l1.24-3.64a1.5,1.5,0,0,0-1.42-2h-3.54a1.55,1.55,0,0,1-1.54-1.55V548h-3v5.27a4.55,4.55,0,0,0,4.54,4.55h1.45L951,562.85a1.51,1.51,0,0,0,1.42,2,1.51,1.51,0,0,0,.65-.15l14.24-6.89h12.6a4.55,4.55,0,0,0,4.55-4.55v-20A4.55,4.55,0,0,0,979.93,528.75Z"
        transform="translate(-935.39 -515.35)"
      />
      <path
        fill={bgColor}
        d="M966.78,551.29a1.52,1.52,0,0,0,.66.15,1.51,1.51,0,0,0,1.42-2l-1.72-5.06h1.44a4.55,4.55,0,0,0,4.55-4.55v-20a4.55,4.55,0,0,0-4.55-4.55H939.94a4.55,4.55,0,0,0-4.55,4.55v20a4.55,4.55,0,0,0,4.55,4.55h12.61Zm-28.39-11.44v-20a1.56,1.56,0,0,1,1.55-1.55h28.64a1.56,1.56,0,0,1,1.55,1.55v20a1.55,1.55,0,0,1-1.55,1.55H965a1.5,1.5,0,0,0-1.42,2l1.24,3.64-11.32-5.48a1.44,1.44,0,0,0-.65-.15H939.94A1.55,1.55,0,0,1,938.39,539.85Z"
        transform="translate(-935.39 -515.35)"
      />
    </svg>
  );
};

export default Icon;
