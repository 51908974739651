import React, { useRef } from 'react';
import Slider from 'react-slick';
import './introSlider.scss';
import Tip1 from './tip_1';
import Tip2D from './tip_2d';
import Tip2M from './tip_2m';
import Tip3 from './tip_3';
import Tip4 from './tip_4';
import LogoSvg from '../../components/powered/svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const IntroSlider = (props) => {
  const { phone, portrait, image, onClick } = props;
  const listRef = useRef();
  const slidePerPage = phone
    ? window.innerWidth < window.innerHeight
      ? 1
      : 2
    : 4;
  const settings = {
    dots: Boolean(phone),
    arrows: false,
    infinite: false,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: slidePerPage,
    slidesToScroll: slidePerPage,
  };

  return (
    <div
      className={`intro-slide-container ${phone ? 'phone' : 'desktop'} ${
        portrait ? 'portrait' : 'landscape'
      }`}
      onClick={onClick}
    >
      <div ref={listRef} className="intro-slide-item-list">
        <Slider {...settings}>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body">
              <div className="intro-svg">
                <Tip1 />
              </div>
              <div className="intro-text">
                See a list of locations &amp; control viewing options with the
                menu
              </div>
            </div>
          </div>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body">
              <div className="intro-svg">{phone ? <Tip2M /> : <Tip2D />}</div>
              <div className="intro-text">
                Click and drag to rotate 360&deg; in any direction
              </div>
            </div>
          </div>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body">
              <div className="intro-svg">
                <Tip3 image={image} />
              </div>
              <div className="intro-text">
                Fly between locations, open media &amp; see every detail
              </div>
            </div>
          </div>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body pr-1">
              <div className="intro-svg ml-auto">
                <Tip4 />
                <div
                  className="intro-logo"
                  onClickCapture={(e) => e.stopPropagation()}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Open SKYNAV 360"
                    href="https://skynav360.com"
                  >
                    <LogoSvg />
                  </a>
                </div>
              </div>
              <div className="intro-text text-right">
                More about the SKYNAV
                <br />
                experience platform
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default IntroSlider;
