import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Icon Media Typeform"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.43 32.43"
    >
      <rect
        fill="none"
        stroke={bgColor}
        strokeMiterlimit="10"
        x="0.5"
        y="0.5"
        width="31.43"
        height="31.43"
        rx="3.64"
      />
      <rect
        opacity="0.5"
        x="0.99"
        y="0.99"
        width="30.45"
        height="30.45"
        rx="3.17"
      />
      <rect
        fill={bgColor}
        x="2.41"
        y="2.41"
        width="27.62"
        height="27.62"
        rx="1.86"
      />
      <path
        d="M962.81,545.53a.52.52,0,0,0,.27.07.67.67,0,0,0,.42-.16.63.63,0,0,0,.18-.67l-.72-2.12h.6a1.92,1.92,0,0,0,1.91-1.91v-8.36a1.91,1.91,0,0,0-1.91-1.91h-12a1.9,1.9,0,0,0-1.9,1.91v8.36a1.91,1.91,0,0,0,1.9,1.91h5.29Zm-11.9-4.79v-8.36a.65.65,0,0,1,.64-.65h12a.65.65,0,0,1,.65.65v8.36a.65.65,0,0,1-.65.65h-1.48a.62.62,0,0,0-.51.26.65.65,0,0,0-.09.57l.52,1.53-4.74-2.3a.67.67,0,0,0-.28-.06h-5.43A.65.65,0,0,1,950.91,540.74Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        d="M962.81,545.53a.52.52,0,0,0,.27.07.67.67,0,0,0,.42-.16.63.63,0,0,0,.18-.67l-.72-2.12h.6a1.92,1.92,0,0,0,1.91-1.91v-8.36a1.91,1.91,0,0,0-1.91-1.91h-12a1.9,1.9,0,0,0-1.9,1.91v8.36a1.91,1.91,0,0,0,1.9,1.91h5.29Zm-11.9-4.79v-8.36a.65.65,0,0,1,.64-.65h12a.65.65,0,0,1,.65.65v8.36a.65.65,0,0,1-.65.65h-1.48a.62.62,0,0,0-.51.26.65.65,0,0,0-.09.57l.52,1.53-4.74-2.3a.67.67,0,0,0-.28-.06h-5.43A.65.65,0,0,1,950.91,540.74Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        d="M968.32,536.84H965l-.37,5.29h-2.17l.56,2-7.13-2h-.74v4.23a1.15,1.15,0,0,0,1.14,1.15h1.49a.12.12,0,0,1,.1,0,.13.13,0,0,1,0,.12l-.52,1.52a.52.52,0,0,0,.14.54.5.5,0,0,0,.33.12.42.42,0,0,0,.22-.05l4.8-2.3h5.43a1.16,1.16,0,0,0,1.15-1.15V538A1.16,1.16,0,0,0,968.32,536.84Z"
        transform="translate(-943.72 -523.88)"
      />
    </svg>
  );
};

export default Icon;
