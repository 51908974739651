import React, { useState, useRef } from 'react';
import { Html } from '@react-three/drei';
import HOTSPOT_TYPE from '../../consts/hotspotType.const';
import HotspotIcon from './icons';
import { trackEventByType, EVENT_TYPE } from '../../gaTracking';

const HOVERzINDEX = '100';
const zIndexRange1 = [50, 0];
const zIndexRangeHover = [100, 100];

export const HotspotSingle = (props) => {
  const elRef = useRef();
  const [x, y, z] = props.position;
  const [zIndex, setZIndex] = useState('0');
  const [zIndexRange, setZIndexRange] = useState(zIndexRange1);
  const [hovering, setHovering] = useState(false);
  const hoverToggled = (isHover) => {
    const parentNode = elRef.current && elRef.current.parentNode;
    setHovering(Boolean(isHover));
    if (isHover) {
      setZIndexRange(zIndexRangeHover);
      if (parentNode) {
        setZIndex(parentNode.style.zIndex);
        parentNode.style.zIndex = HOVERzINDEX;
      }
    } else {
      setTimeout(() => {
        setZIndexRange(zIndexRange1);
        if (parentNode) {
          parentNode.style.zIndex = zIndex;
        }
      }, 0);
    }
  };
  return (
    <group position={[x, y, z]}>
      <Html ref={elRef} center={true} zIndexRange={zIndexRange}>
        <HotspotIcon
          type={props.type}
          hotspot={props.hotspot}
          currentSceneCoords={props.currentSceneCoords}
          scene={props.scene}
          media={props.media}
          tour={props.tour}
          onClick={props.onClick}
          isHover={hovering}
          toggleHover={(val) => hoverToggled(val)}
          hoverIn={() => hoverToggled(true)}
          hoverOut={() => hoverToggled(false)}
          visited={props.visited}
          color={props.color || ''}
        />
      </Html>
    </group>
  );
};

const HotspotList = (props) => {
  const hotspots = props.hotspots.filter(
    (hs) => hs.scene || (hs.media && !hs.media.audio)
  );
  if (!hotspots || !hotspots.length) {
    return null;
  }
  const onClickHotspot = (hs) => {
    if (hs.type === HOTSPOT_TYPE.MEDIA) {
      trackEventByType(EVENT_TYPE.HOTSPOT_FROM_HOTSPOT, hs.media.id);
      props.onClickMedia(hs.media.id);
    } else {
      trackEventByType(EVENT_TYPE.SCENE_FROM_HOTSPOT, hs.scene.id);
      props.onClickJumpSpot(hs.scene.id);
    }
  };

  return (
    <>
      {hotspots.map((hs, index) => (
        <HotspotSingle
          key={index}
          currentSceneCoords={props.currentSceneCoords}
          position={hs.location}
          hotspot={hs}
          type={hs.type}
          scene={hs.scene}
          media={hs.media}
          color={props.brandColor}
          visited={props.hotspotHistory.indexOf(hs._id) !== -1}
          onClick={() => onClickHotspot(hs)}
        />
      ))}
    </>
  );
};

export default HotspotList;
