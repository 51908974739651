import React, { useRef } from 'react';
import BtnReadMore from '../buttonReadMoreNew';
import './galleryItem.scss';

const GalleryItem = ({ src, maxWidth, maxHeight, alt, readMoreUrl }) => {
  const maxWidthPx = maxWidth + 'px';
  const maxHeightPx = maxHeight + 'px';
  const imgRef = useRef();
  const onLoad = () => {
    if (imgRef.current.width < imgRef.current.height) {
      imgRef.current.style.height = maxHeightPx;
    } else {
      imgRef.current.style.width = maxWidthPx;
    }
  };

  return (
    <div className="media-img-preview-wp" style={{ width: maxWidthPx }}>
      <img
        onLoad={onLoad}
        className="media-img-preview"
        ref={imgRef}
        src={src}
        style={{ maxWidth: maxWidthPx, maxHeight: maxHeightPx }}
        alt={alt}
      />
      {readMoreUrl && (
        <div className="media-img-readmore-button">
          <BtnReadMore href={readMoreUrl} color="white" />
        </div>
      )}
    </div>
  );
};

export default GalleryItem;
