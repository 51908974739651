import { Vector3, Vector2, PerspectiveCamera, OrthographicCamera } from 'three';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useFrame, useThree } from '@react-three/fiber';
import { getCanvasX, CANVAS_W, CANVAS_H } from './utils';

const v1 = new Vector3();
const v2 = new Vector3();
const v3 = new Vector3();

function calcXY({ x, y }, { width, height }) {
  const halfW = width / 2;
  const halfH = height / 2;
  return [x * halfW + halfW, -(y * halfH) + halfH];
}

function calculatePosition1(el, camera, size = { width: 0, height: 0 }) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  objectPos.project(camera);
  return calcXY(objectPos, size);
}

function isVisibleInRange(x, y, w, h, percent) {
  const min = 0 + percent,
    max = 1 - percent;
  return !(x < w * min || x > w * max || y < h * min || y > h * max);
}

const OPACITY_RANGE = 0.1;

function calcOpacityFloor(curPercent, percent) {
  const evaluate = curPercent - percent;
  return evaluate / OPACITY_RANGE;
}

function calcOpacityCeil(curPercent, percent) {
  const maxRange = percent + OPACITY_RANGE;
  const minPercent = 1 - maxRange;
  const evaluate = curPercent - minPercent;
  return 1 - evaluate / OPACITY_RANGE;
}

function getOpacityPercent(val, target) {
  return Math.min(Math.max(val / target, 0), 1);
}

function calcOpacity(x, y, w, h, percent) {
  const maxPercent = 1 - percent;
  const curPercentX = getOpacityPercent(x, w);
  const curPercentY = getOpacityPercent(y, h);
  if (curPercentX < percent || curPercentY < percent) {
    return 0;
  }
  if (curPercentX > maxPercent || curPercentY > maxPercent) {
    return 0;
  }
  const opacityFloor = percent + OPACITY_RANGE;
  const opacityCeil = 1 - opacityFloor;
  const checkFloorX = Math.max(curPercentX, opacityFloor) === opacityFloor;
  const checkCeilX = Math.min(curPercentX, opacityCeil) === opacityCeil;
  const checkFloorY = Math.max(curPercentY, opacityFloor) === opacityFloor;
  const checkCeilY = Math.min(curPercentY, opacityCeil) === opacityCeil;

  const opacity = { x: 1, y: 1 };
  if (checkFloorX) {
    opacity.x = calcOpacityFloor(curPercentX, percent);
  }
  if (checkCeilX) {
    opacity.x = calcOpacityCeil(curPercentX, percent);
  }
  if (checkFloorY) {
    opacity.y = calcOpacityFloor(curPercentY, percent);
  }
  if (checkCeilY) {
    opacity.y = calcOpacityCeil(curPercentY, percent);
  }

  return opacity.x < opacity.y ? opacity.x : opacity.y;
}

function isVisibleCheck(
  x,
  y,
  size = { width: 0, height: 0 },
  isBehind = false,
  isSkytag = false
) {
  if (isBehind) return false;
  const w = size.width,
    h = size.height;
  const percent = isSkytag ? 0.2 : 0.1;
  return isVisibleInRange(x, y, w, h, percent);
}

function isBehindCheck(objectPos, camera) {
  const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
  const deltaCamObj = objectPos.sub(cameraPos);
  const camDir = camera.getWorldDirection(v3);
  return deltaCamObj.angleTo(camDir) > Math.PI / 2;
}

function calculatePosition2(
  el,
  x,
  y,
  camera,
  size = { width: 0, height: 0 },
  isSkytag
) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  const isBehind = isBehindCheck(objectPos, camera);
  const isVisible = isVisibleCheck(x, y, size, isBehind, isSkytag);
  const percent = isSkytag ? 0.2 : 0.1;
  const opacity = !isVisible
    ? 0
    : calcOpacity(x, y, size.width, size.height, percent);

  return [isVisible, isBehind, opacity];
}

function calculatePosition(el, camera, size = { width: 0, height: 0 }) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  objectPos.project(camera);
  const [x, y] = calcXY(objectPos, size);
  const isBehind = isBehindCheck(objectPos, camera);
  const isVisible = isVisibleCheck(x, y, size, isBehind);
  const opacity = !isVisible
    ? 0
    : calcOpacity(x, y, size.width, size.height, 0.1);
  return [x, y, isVisible, isBehind, opacity];
}

function objectScale(el, camera) {
  if (camera instanceof PerspectiveCamera) {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const vFOV = (camera.fov * Math.PI) / 180;
    const dist = objectPos.distanceTo(cameraPos);
    return 1 / (2 * Math.tan(vFOV / 2) * dist);
  }
  if (camera instanceof OrthographicCamera) return camera.zoom;
  return 1;
}

const objectZIndex = (el, camera, zIndexRange) => {
  if (
    camera instanceof PerspectiveCamera ||
    camera instanceof OrthographicCamera
  ) {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const dist = objectPos.distanceTo(cameraPos);
    const A = (zIndexRange[1] - zIndexRange[0]) / (camera.far - camera.near);
    const B = zIndexRange[1] - A * camera.far;
    return Math.round(A * dist + B);
  }
  return undefined;
};

const ori = new Vector2(CANVAS_W / 2, CANVAS_H / 2);

const getPercent = (x, y, percent) => {
  const tp = new Vector2(x - ori.x, y - ori.y);
  tp.multiplyScalar(percent);
  return { x: tp.x + ori.x, y: tp.y + ori.y };
};

const ANIMATION_DURATION = 500;

const getElapsed = (time) => Math.min(new Date() - time, ANIMATION_DURATION);

const drawHsLinePercent = (ctx, toX, toY, visibleAt) => {
  const elapsed = getElapsed(visibleAt);
  if (elapsed < ANIMATION_DURATION) {
    const percent = elapsed / ANIMATION_DURATION;
    const { x, y } = getPercent(toX, toY, percent);
    drawHsLine(ctx, x, y);
  } else {
    drawHsLine(ctx, toX, toY);
  }
};

const drawHsLine = (ctx, toX, toY) => {
  ctx.beginPath();
  ctx.moveTo(ori.x, ori.y);
  ctx.lineTo(toX, toY);
  ctx.lineWidth = 1;
  ctx.strokeStyle = 'white';
  ctx.stroke();
};

const onPositionChanged = (
  x,
  isVisible,
  size,
  up,
  cvRef,
  hsRef,
  visibleAt,
  opacity = 1
) => {
  hsRef.parentNode.style.visibility = isVisible ? 'visible' : 'hidden';
  cvRef.style.opacity = opacity;

  const ctx = cvRef.getContext('2d');
  ctx.clearRect(0, 0, CANVAS_W, CANVAS_H);
  if (isVisible) {
    const target = {
      x: getCanvasX(x, size.width),
      y: up ? 0 : CANVAS_H,
    };
    const hs2d = {
      x: target.x - CANVAS_W / 2,
      y: (up ? -1 : 1) * 0.5 * CANVAS_H,
    };
    drawHsLinePercent(ctx, target.x, target.y, visibleAt);
    const elapsed = getElapsed(visibleAt);
    if (elapsed < ANIMATION_DURATION) {
      hsRef.style.opacity = '0';
    } else {
      hsRef.style.opacity = opacity;
    }
    hsRef.style.transform = `translate(${hs2d.x}px,${hs2d.y}px)`;
  }
};

const skytagTransition = (el, isVisible) => {
  const childEl = el.querySelector('.PopOver');

  childEl.style.opacity = isVisible ? '1' : '0';
};

export default React.forwardRef(
  (
    {
      children,
      eps = 0.001,
      style,
      className,
      prepend,
      center,
      fullscreen,
      portal,
      scaleFactor,
      zIndexRange = [16777271, 0],
      hotspotRef,
      canvasRef,
      isSkytag,
      isAerialHs,
      ...props
    },
    ref
  ) => {
    const { gl, scene, camera, size } = useThree();
    const [el] = useState(() => document.createElement('div'));
    const group = useRef(null);
    const old = useRef([0, 0]);
    const up = useMemo(() => {
      if (isAerialHs) return true;
      return props.position[1] > 0;
    }, [props.position, isAerialHs]);
    const target = portal?.current ?? gl.domElement.parentNode;
    const interval = useRef(null);
    const visibleAt = useRef(null);

    useEffect(() => {
      if (group.current) {
        scene.updateMatrixWorld();
        const [x, y, isVisible] = calculatePosition(
          group.current,
          camera,
          size
        );
        if (canvasRef.current && hotspotRef.current) {
          if (isVisible) {
            visibleAt.current = visibleAt.current || new Date();
          } else {
            visibleAt.current = null;
          }
          onPositionChanged(
            x,
            isVisible,
            size,
            up,
            canvasRef.current,
            hotspotRef.current,
            visibleAt.current
          );
        }
        el.style.cssText = `position:absolute;top:0;left:0;transform:translate3d(${x}px,${y}px,0);transform-origin:0 0;`;
        if (target) {
          if (prepend) target.prepend(el);
          else target.appendChild(el);
        }
        return () => {
          if (target) target.removeChild(el);
          ReactDOM.unmountComponentAtNode(el);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target]);

    const styles = useMemo(
      () => ({
        position: 'absolute',
        transform: center ? 'translate3d(-50%,-50%,0)' : 'none',
        ...(fullscreen && {
          top: -size.height / 2,
          left: -size.width / 2,
          width: size.width,
          height: size.height,
        }),
        ...style,
      }),
      [style, center, fullscreen, size]
    );

    useEffect(
      () =>
        void ReactDOM.render(
          <div
            ref={ref}
            style={styles}
            className={className}
            children={children}
          />,
          el
        )
    );

    useFrame(() => {
      if (group.current) {
        const [x, y] = calculatePosition1(group.current, camera, size);
        if (
          Math.abs(old.current[0] - x) > eps ||
          Math.abs(old.current[1] - y) > eps
        ) {
          const [isVisible, isBehind, opacity] = calculatePosition2(
            group.current,
            x,
            y,
            camera,
            size,
            isSkytag
          );
          el.style.visibility = isBehind ? 'hidden' : 'visible';
          if (canvasRef.current && hotspotRef.current) {
            if (isVisible) {
              visibleAt.current = visibleAt.current || new Date();
            } else {
              visibleAt.current = null;
            }
            if (interval.current) {
              clearInterval(interval.current);
              interval.current = null;
            }
            onPositionChanged(
              x,
              isVisible,
              size,
              up,
              canvasRef.current,
              hotspotRef.current,
              visibleAt.current,
              opacity
            );
            if (interval.current === null) {
              interval.current = setInterval(() => {
                if (!canvasRef.current || !hotspotRef.current) {
                  return clearInterval(interval.current);
                }
                const elapsed = getElapsed(visibleAt.current);
                onPositionChanged(
                  x,
                  isVisible,
                  size,
                  up,
                  canvasRef.current,
                  hotspotRef.current,
                  visibleAt.current,
                  opacity
                );
                if (elapsed > ANIMATION_DURATION) {
                  clearInterval(interval.current);
                }
              }, 10);
            }
          }
          const scale =
            scaleFactor === undefined
              ? 1
              : objectScale(group.current, camera) * scaleFactor;
          isSkytag && skytagTransition(el, isVisible);
          el.style.transform = `translate3d(${x}px,${y}px,0) scale(${scale})`;
          el.style.zIndex = `${objectZIndex(
            group.current,
            camera,
            zIndexRange
          )}`;
        }
        old.current = [x, y];
      }
    });

    return <group {...props} ref={group} />;
  }
);
