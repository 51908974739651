import React from 'react';

const Tip = () => (
  <svg
    data-name="Tip 2M"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 178"
  >
    <path
      fill="#fff"
      d="M822.58,609.38h2.64a5,5,0,0,0,3.94-1.57,6.27,6.27,0,0,0,1.38-4.29q0-6-4.64-6a4.2,4.2,0,0,0-3.49,1.61,6.71,6.71,0,0,0-1.26,4.24H817a9.48,9.48,0,0,1,2.5-6.75,9.17,9.17,0,0,1,12.77-.14q2.37,2.5,2.37,7.13a8.41,8.41,0,0,1-1.23,4.41,7.57,7.57,0,0,1-3.2,3q4.83,1.79,4.83,8a10.21,10.21,0,0,1-2.54,7.27,9.59,9.59,0,0,1-13.19.14,9.48,9.48,0,0,1-2.49-6.9H821a6.65,6.65,0,0,0,1.31,4.32,4.4,4.4,0,0,0,3.59,1.61,4.69,4.69,0,0,0,3.69-1.57,7.14,7.14,0,0,0,1.37-4.78q0-6.21-5.88-6.21h-2.48Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M855.39,594.41v3.68h-.68a8.39,8.39,0,0,0-6.49,2.8q-2.34,2.7-2.69,8.14a7.08,7.08,0,0,1,5.8-2.86,7,7,0,0,1,6,3.1,13.75,13.75,0,0,1,2.21,8.14c0,3.58-.81,6.4-2.45,8.48a7.9,7.9,0,0,1-6.56,3.11,7.76,7.76,0,0,1-6.68-3.67q-2.54-3.68-2.53-9.77V613.9a33.64,33.64,0,0,1,1.52-11.2,12.32,12.32,0,0,1,4.49-6.19,13.15,13.15,0,0,1,7.36-2.1Zm-4.76,15.37a4.59,4.59,0,0,0-3.11,1.28,7.25,7.25,0,0,0-2.06,3.28v1.71a13.69,13.69,0,0,0,1.46,6.76c1,1.74,2.17,2.61,3.6,2.61a4,4,0,0,0,3.62-2.14,10.7,10.7,0,0,0,1.32-5.66,10.81,10.81,0,0,0-1.32-5.65A4,4,0,0,0,850.63,609.78Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M882.86,614q0,7.54-2.2,11.28a8.28,8.28,0,0,1-13.73.15q-2.25-3.58-2.34-10.77v-5.86q0-7.47,2.21-11.15a8.37,8.37,0,0,1,13.73-.18q2.21,3.48,2.33,10.56Zm-4.15-5.92q0-5.42-1.19-8A4.09,4.09,0,0,0,870,600a19.06,19.06,0,0,0-1.24,7.6v7q0,5.39,1.24,8.09a4,4,0,0,0,3.8,2.71,3.92,3.92,0,0,0,3.72-2.56q1.2-2.55,1.24-7.79Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M888.62,600.06a6,6,0,0,1,1.74-4.27,5.66,5.66,0,0,1,4.23-1.82,5.53,5.53,0,0,1,4.16,1.81,6.05,6.05,0,0,1,1.72,4.28,5.87,5.87,0,0,1-1.72,4.24,5.62,5.62,0,0,1-4.16,1.75,6,6,0,0,1-6-6Zm6,3a3,3,0,0,0,2.13-.82,2.86,2.86,0,0,0,.87-2.16,3,3,0,0,0-.87-2.23,2.9,2.9,0,0,0-2.13-.87,2.87,2.87,0,0,0-2.17.92,3.06,3.06,0,0,0-.87,2.18,2.86,2.86,0,0,0,.87,2.12A3,3,0,0,0,894.59,603.05Z"
      transform="translate(-815.69 -451.31)"
    />
    <rect fill="#fff" opacity="0" width="288" height="178" />
    <path
      fill="#fff"
      d="M970.78,572.2c.76.36,1.47.62,2.1,1a7.15,7.15,0,0,1,1.65,10.86q-9.76,10.38-19.41,20.86a20.58,20.58,0,0,1-12.78,6.59,29.07,29.07,0,0,1-12.89-1.3,5.36,5.36,0,0,1-1.91-1.37l-13.72-12.73a9.86,9.86,0,0,1-3-5.86,27.34,27.34,0,0,1,.41-9.33,100.73,100.73,0,0,1,3.21-12c1.2-3.77,2.47-7.52,3.61-11.3.53-1.74.86-3.54,1.25-5.32a7.65,7.65,0,0,1,4.64-6,7.07,7.07,0,0,1,9.57,6.51q.06,3.11.14,6.21c0,.18,0,.36,0,.64.21-.21.36-.35.5-.51q12.81-13.76,25.6-27.53a7.21,7.21,0,0,1,10.66,0,7,7,0,0,1,0,9.45c-3.35,3.54-6.64,7.13-10,10.7-.15.15-.29.31-.49.54.23.1.39.18.57.24a7.2,7.2,0,0,1,4.8,8.07c-.06.36,0,.48.37.55a7.21,7.21,0,0,1,5.41,10.18C971.07,571.72,970.92,572,970.78,572.2Zm-42.31-22.12c-2.31-1.18-4.37-.48-5.22,1.66a19.12,19.12,0,0,0-.73,3,112.49,112.49,0,0,1-3.24,10.94c-1.49,4.56-3,9.11-4.06,13.8a32,32,0,0,0-1,8.71,7.48,7.48,0,0,0,2.21,5.46l13.78,12.79a2.3,2.3,0,0,0,.84.49,25.32,25.32,0,0,0,9.51,1.16,17.33,17.33,0,0,0,11.83-5.47q9.74-10.44,19.44-20.9a3.45,3.45,0,0,0,.64-3.91A3.68,3.68,0,0,0,969,575.7a3.42,3.42,0,0,0-2.47,1.21q-2.43,2.6-4.87,5.18a1.75,1.75,0,0,1-2.87-.42,1.83,1.83,0,0,1,.42-2.14c2.68-2.89,5.38-5.77,8-8.67a4,4,0,0,0,.81-1.29,3.54,3.54,0,0,0-1.64-4.31,3.4,3.4,0,0,0-4.34.68c-1.87,2-3.78,4-5.68,6.06a2.56,2.56,0,0,1-.73.58,1.73,1.73,0,0,1-2.19-.62,1.81,1.81,0,0,1,.21-2.24c2.45-2.64,4.91-5.27,7.35-7.91a3.59,3.59,0,0,0-4.11-5.65,8.29,8.29,0,0,0-1.78,1.47c-1.45,1.51-2.85,3-4.27,4.58a1.73,1.73,0,0,1-1.9.52,1.76,1.76,0,0,1-.65-3q9.69-10.44,19.4-20.87a3.59,3.59,0,1,0-5.48-4.63l-28.85,31a1.75,1.75,0,0,1-2,.61,1.78,1.78,0,0,1-1.19-1.77c-.05-1.77-.08-3.54-.12-5.32,0-2-.08-4.07-.15-6.1A3.07,3.07,0,0,0,928.47,550.08Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M905.55,485.75c.61,2.17,1.17,4.18,1.75,6.18a1.73,1.73,0,0,1-.27,1.6,1.65,1.65,0,0,1-1.61.68,1.61,1.61,0,0,1-1.38-1.07c-.06-.16-.11-.32-.15-.47q-1.39-4.92-2.77-9.82a1.75,1.75,0,0,1,1.35-2.38l10.1-2.85a1.82,1.82,0,0,1,2.09,1,1.75,1.75,0,0,1-.86,2.23,3.81,3.81,0,0,1-.57.19l-5.36,1.51-.57.19.65.36,78.38,43.95a8.3,8.3,0,0,1,.81.49,1.68,1.68,0,0,1,.56,2.33,1.72,1.72,0,0,1-2.38.59l-14.1-7.9-65-36.46Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M1013.09,541.88c-.15-.45-.32-.9-.45-1.35-.45-1.55-.89-3.1-1.31-4.65a1.72,1.72,0,0,1,1.1-2.19,1.74,1.74,0,0,1,2.23,1.17l2.82,10a1.76,1.76,0,0,1-1.36,2.46l-9.81,2.76a1.88,1.88,0,0,1-1.54-.08,1.72,1.72,0,0,1-.92-1.68,1.7,1.7,0,0,1,1.24-1.51c1.88-.54,3.77-1.07,5.65-1.59a2,2,0,0,1,.54,0l0-.26-.5-.29-10.93-6.12a1.66,1.66,0,0,1-.93-1.63,1.6,1.6,0,0,1,1.15-1.53,2.48,2.48,0,0,1,1.15,0,2.69,2.69,0,0,1,.8.39c3.5,1.95,7,3.92,10.49,5.88l.46.25Z"
      transform="translate(-815.69 -451.31)"
    />
  </svg>
);

export default Tip;
