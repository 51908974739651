import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { isMobile } from 'deviceDetect';
import './media.scss';
import MEDIA_TYPES from '../../../consts/mediaType.const';
import IconMediaInfo from './iconMediaInfo';
import IconMediaVideo from './iconMediaVideo';
import IconMediaGallery from './iconMediaGallery';
import IconMediaMatterport from './iconMediaMatterport';
import IconMedia2D from './iconMedia2D';
import IconMediaGoogle360 from './iconMedia360';
import IconMediaTypeForm from './iconMediaTypeForm';
import CheckMark from '../../../components/icons/checked';
import IconSketchFab from './iconMediaSketchFab';

const getIcon = (type) => {
  switch (type) {
    case MEDIA_TYPES.GALLERY:
      return IconMediaGallery;
    case MEDIA_TYPES.MATTERPORT:
      return IconMediaMatterport;
    case MEDIA_TYPES.MAPFLOORPLAN:
      return IconMedia2D;
    case MEDIA_TYPES.GOOGLE360:
      return IconMediaGoogle360;
    case MEDIA_TYPES.TYPEFORM:
      return IconMediaTypeForm;
    case MEDIA_TYPES.CUSTOM_HTML:
      return IconMediaInfo;
    case MEDIA_TYPES.SKETCH_FAB:
      return IconSketchFab;
    case MEDIA_TYPES.VIDEO:
      return IconMediaVideo;
    default:
      return IconMediaInfo;
  }
};

const MediaHotspot = (props) => {
  const hostpostRef = useRef();

  const [count, setCount] = useState(0);

  const { color } = props;
  const { title, type } = props.data;

  const Icon = getIcon(type);

  const onClickHotspot = useCallback(
    (e) => {
      e.stopPropagation();
      if (isMobile()) {
        setCount((prevCount) => prevCount + 1);
        if (count === 0) {
          hostpostRef.current.classList.add('hovering');
        } else {
          hostpostRef.current.classList.remove('hovering');
          props.onClick();
        }
      } else {
        if (props.isHover) {
          props.toggleHover(false);
        }
        props.onClick();
      }
    },
    [count, props]
  );

  useEffect(() => {
    const hideFlyouts = () => {
      setCount(0);
      hostpostRef.current.classList.remove('hovering');
    };

    const handleClickOutside = (event) => {
      if (!hostpostRef?.current?.contains(event.target)) {
        hideFlyouts();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('touchstart', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const mediaTypeStr = useMemo(() => {
    if (type === MEDIA_TYPES.IMAGE) return 'INFO';
    if (type === MEDIA_TYPES.CUSTOM_HTML) return '';
    return type;
  }, [type]);

  return (
    <div
      ref={hostpostRef}
      className={`media-hotspot ${props.isHover ? 'hovering' : ''}`}
      onClick={() => props.toggleHover(true)}
      onMouseEnter={props.hoverIn}
      onMouseLeave={props.hoverOut}
    >
      <div className="m-info">
        {mediaTypeStr && (
          <div className="m-type">
            <p style={color ? { color } : {}}>{mediaTypeStr}</p>
          </div>
        )}
        <div className="m-title">
          <p>{title}</p>
        </div>
      </div>
      <div className="m-icon" onClickCapture={onClickHotspot}>
        <Icon />
      </div>
      {props.visited && (
        <div className="s-visited">
          <CheckMark />
        </div>
      )}
    </div>
  );
};

export default MediaHotspot;
