import React from 'react';

import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Color from 'color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toggleMenu } from 'store/actions';

import NavMenuItem from './menuItem';

import './style.scss';

const getMenuColor = (clr) => Color(clr).alpha(0.4).string();

const MENU_LOCATION = {
  LEFT: 'left',
  BOTTOM: 'bottom',
};

function NavMenu(props) {
  const { isOpen, location, menu, color, onClose, tour } = props;
  const title = `${tour.name}, ${tour.location}`;
  const isMenuLeft = location === MENU_LOCATION.LEFT;
  const onClickMenu = (item) => {
    props.toggleMenu(false);
    props.onSelect(item.groupId, item.id);
  };
  let index = 0;
  return (
    <div
      className={`Nav-Pan-Menu ${isOpen ? 'Nav-Pan-open' : 'Nav-Pan-close'} ${
        isMenuLeft ? 'Nav-Pan-left' : 'Nav-Pan-bottom'
      }`}
    >
      <div className="Nav-Pan-Menu-container">
        <div className="Menu-bg">
          <div className="Menu-bg-card">&nbsp;</div>
        </div>
        <div
          className="Menu-header"
          style={{
            color: 'white',
            background: `linear-gradient(0deg, ${getMenuColor(
              color
            )} 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)`,
          }}
        >
          <h1 className="title">{title}</h1>
          <p className="sub-title">{tour.tagline}</p>
        </div>
        <div className="Menu-body">
          <div className="Menu-groups">
            <PerfectScrollbar>
              {menu.map((group, i) => (
                <div className="Menu-group" key={'Menu-group-' + i}>
                  <div
                    className="Menu-group-title font-roboto"
                    style={{ color: 'white' }}
                  >
                    {group.name}
                  </div>
                  <div className="Menu-group-items">
                    {group.scenes.map((item, j) => (
                      <NavMenuItem
                        key={'Menu-item-' + j}
                        index={++index}
                        item={item}
                        active={props.panoId === item.id}
                        viewed={props.historyLogs.indexOf(item._id) !== -1}
                        onSelect={() => onClickMenu(item)}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </div>
        <div className="Menu-close-btn left" onClick={() => onClose()}>
          <FontAwesomeIcon icon="caret-left" />
        </div>
        <div className="Menu-close-btn bottom" onClick={() => onClose()}>
          <FontAwesomeIcon icon="caret-down" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ hotspotHistory, currentScene, json }) => ({
  panoId: currentScene.id,
  historyLogs: hotspotHistory,
  tour: json.tour,
  customer: json.customer,
  color: json.tour.menuHighlightColor,
});

const mapDispatchToProps = {
  toggleMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
