import React from 'react';
import { getHtml } from 'utils/popupHelper/popup.video-content';
import './popup.content-video.scss';

function PopupContentVideo(props) {
  return (
    <div className={`Popup-content Popup-content-video`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={getHtml(props.media)}
        ></div>
      </div>
    </div>
  );
}

export default PopupContentVideo;
