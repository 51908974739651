import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import './style.scss';

const Logo = (props) => {
  const {
    logoURL,
    title,
    menuPosition,
    websiteUrl,
    introStatus,
    showSpinner,
    hasIntroImg,
    mediaLogoOverride,
  } = props;

  const { hotspotId } = useParams();
  const altText = useMemo(() => title || 'SKYNAV', [title]);

  const status = useMemo(() => {
    if (hasIntroImg) {
      return hotspotId ? 'in' : introStatus;
    }
    return showSpinner || hotspotId ? 'in' : 'out';
  }, [introStatus, showSpinner, hasIntroImg, hotspotId]);

  return (
    <div className={`brand brand-menu-${menuPosition} intro-${status}`}>
      <div className="brand-logo">
        <div className="brand-logo-img">
          <a
            href={websiteUrl}
            alt={altText}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={!!mediaLogoOverride ? mediaLogoOverride : logoURL}
              alt={altText}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ menu, json, tour, currentScene }) => ({
  menuPosition: menu.position,
  websiteUrl: json.tour.websiteUrl,
  title: tour.name,
  logoURL: tour.logoURL,
  hasIntroImg: !!tour.introImageUrl,
  mediaLogoOverride: currentScene.mediaLogoOverride,
});

export default connect(mapStateToProps)(Logo);
