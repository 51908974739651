import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './styles.scss';

function DropupNavigator({ variants, onClickVariant, variantIndex }) {
  const [openVariantsList, setOpenVariantsList] = useState(false);

  return (
    <div className="DropUp">
      <button
        className="DropUp--btn"
        onClick={() => setOpenVariantsList(!openVariantsList)}
      >
        <div className="DropUp--btn__title">{variants[variantIndex].title}</div>
        <FontAwesomeIcon
          className="DropUp--btn__icon"
          icon={['fas', 'caret-up']}
        />
      </button>
      <div className="DropUp--wrapper">
        <div
          className={`DropUp--content ${
            openVariantsList
              ? 'DropUp--content__active'
              : 'DropUp--content__inactive'
          }`}
        >
          {variants.map((i, index) => (
            <div
              className="DropUp--content__item"
              key={i.id}
              onClick={() => {
                onClickVariant(index);
                setOpenVariantsList(!openVariantsList);
              }}
            >
              {i.title}
            </div>
          ))}
          <div className="DropUp--content__item">
            {variants[variantIndex].title}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropupNavigator;
