import React from 'react';

const SceneIcon = (props) => {
  const color = props.color || 'white';
  return (
    <svg
      data-name="Menu Icon Ground"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76.95 76.95"
      width="100%"
    >
      <path
        d="M959.69,501.83a38.48,38.48,0,1,0,38.48,38.48A38.52,38.52,0,0,0,959.69,501.83Zm0,73.95a35.48,35.48,0,1,1,35.48-35.47A35.51,35.51,0,0,1,959.69,575.78Z"
        transform="translate(-921.22 -501.83)"
        fill={color}
      />
      <circle cx="44.57" cy="15.48" r="5.21" fill={color} />
      <path
        d="M952,553.89l-8,11.16a1.77,1.77,0,0,0,.42,2.46,1.75,1.75,0,0,0,1,.33,1.78,1.78,0,0,0,1.44-.74l8.46-11.86.59-3.91-3.06-3.06Z"
        transform="translate(-921.22 -501.83)"
        fill={color}
      />
      <path
        d="M979.52,537.7l-10.2-3.55L966.23,530l-1.51,4.13,2,2.5,0,0a1.7,1.7,0,0,0,.59.54l.12.06.16.07L978.36,541a1.69,1.69,0,0,0,.58.1,1.76,1.76,0,0,0,.58-3.43Z"
        transform="translate(-921.22 -501.83)"
        fill={color}
      />
      <path
        d="M965.38,550.79l-7.63-8,6-17s0-.05,0-.07a1.21,1.21,0,0,0,0-.18h0a5.87,5.87,0,0,1-3.18-1.53L952,529.12h0a2.48,2.48,0,0,0-.26.19l0,0-.12.13a1.5,1.5,0,0,0-.11.13l0,0a1.91,1.91,0,0,0-.16.27h0l-5.07,10.93a1.76,1.76,0,1,0,3.2,1.49l4.84-10.44,4.54-2.67-4.74,13.43a1.7,1.7,0,0,0-.09.5v.08a1.61,1.61,0,0,0,.1.58s0,.06,0,.08a1.57,1.57,0,0,0,.33.53l0,0,7.77,8.16,4,14a1.77,1.77,0,0,0,1.7,1.27,1.66,1.66,0,0,0,.49-.07,1.75,1.75,0,0,0,1.2-2.19Z"
        transform="translate(-921.22 -501.83)"
        fill={color}
      />
    </svg>
  );
};

export default SceneIcon;
