import React from 'react';
import { connect } from 'react-redux';
import './style.scss';

function Breadcrumb(props) {
  const { title, subTitle, menuPosition, introStatus } = props;
  return (
    <div className={`breadcrumb ${menuPosition} intro-${introStatus}`}>
      <div className="b-title">{title}</div>
      <div className="b-subTitle">{subTitle}</div>
    </div>
  );
}

const mapStateToProps = ({ menu }) => ({
  menuPosition: menu.position,
});

export default connect(mapStateToProps)(Breadcrumb);
