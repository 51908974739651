import React from 'react';

function IconGyro(props) {
  const color = props.color || '#fff';
  return (
    <svg
      className="sn-icon sn-icon-gyro"
      data-name="Gyro Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47.47 49.21"
    >
      <path
        fill={color}
        d="M386.53,288.38a3,3,0,0,1,3-3h13a3,3,0,0,1,3,3v5a35.2,35.2,0,0,1,4,.73v-5.74a7,7,0,0,0-7-7h-13a7,7,0,0,0-7,7v5.74a35.2,35.2,0,0,1,4-.73Z"
        transform="translate(-372.26 -281.39)"
      />
      <path
        fill={color}
        d="M405.47,305.19v18.43a3,3,0,0,1-3,3h-13a3,3,0,0,1-3-3V305.19c-1.41-.31-2.18-.06-4,.95v17.48a7,7,0,0,0,7,7h13a7,7,0,0,0,7-7V306.14A6.63,6.63,0,0,0,405.47,305.19Z"
        transform="translate(-372.26 -281.39)"
      />
      <path
        fill={color}
        d="M376.26,304.76c.2-.95,5.26-3.12,13.32-4v2.54l3.74-4.74-3.74-4.68v2.87c-8.76.91-17.32,3.49-17.32,8,0,2.72,2.76,4.74,6.88,6.11v-4.22C377.31,305.91,376.36,305.2,376.26,304.76Z"
        transform="translate(-372.26 -281.39)"
      />
      <path
        fill={color}
        d="M402.42,296.75v-2.87l-3.74,4.68,3.74,4.74v-2.54c8,.88,13.08,3,13.32,4-.1.41-.91,1-2.41,1.68v4.27c3.85-1.37,6.41-3.34,6.41-5.95C419.74,300.24,411.18,297.66,402.42,296.75Z"
        transform="translate(-372.26 -281.39)"
      />
    </svg>
  );
}

export default IconGyro;
