import { useMemo } from 'react';

import { useHistory } from 'react-router';

export function useParamsByLocation() {
  const {
    location: { pathname },
  } = useHistory();

  return useMemo(() => {
    if (!pathname)
      return { tourId: '', groupId: '', sceneId: '', hotspotId: '' };
    const [, tourId, groupId, sceneId, hotspotId] = pathname.split('/');
    return { tourId, groupId, sceneId, hotspotId };
  }, [pathname]);
}
