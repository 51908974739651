import * as APP_ACTION from './actionTypes';
import initialState from './state';

function appReducer(state = initialState, action) {
  const { type, payload } = action;
  window.logMessage('action.type', type, payload);
  switch (type) {
    case APP_ACTION.INITIATED:
      return {
        ...state,
        ready: true,
      };
    case APP_ACTION.UPDATE_DIMENTIONS:
      return {
        ...state,
        dimentions: { ...payload },
      };
    case APP_ACTION.TOGGLE_PANO_MODE:
      return {
        ...state,
        panoMode: payload,
      };
    case APP_ACTION.TOGGLE_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          isOpen: payload,
        },
      };

    case APP_ACTION.SET_MENU_POSITION:
      return {
        ...state,
        menu: {
          ...state.menu,
          position: payload,
        },
      };
    case APP_ACTION.SET_TOUR_DATA:
      const { scenes, groups, tour } = payload;
      return {
        ...state,
        json: { ...payload },
        tour: { ...tour },
        shouldBlur: tour.lightboxBlur || false,
        groups: groups ? [...groups] : [],
        scenes: [...scenes],
      };
    case APP_ACTION.GO_TO_PANO:
      const scene = state.scenes.find(
        (s) => s.id === payload.id && s.groupId === payload.groupId
      );
      return {
        ...state,
        currentScene: { ...scene },
        currentHotspot: null,
        menu: {
          ...state.menu,
          isOpen: false,
          social: false,
          contact: false,
          isoMetricMap: false,
        },
        hotspotHistory: [...state.hotspotHistory, scene._id],
      };
    case APP_ACTION.ADD_HOTSPOT_HISTORY:
      return { ...state, hotspotHistory: [...state.hotspotHistory, payload] };
    case APP_ACTION.CLOSE_LANDING:
      return {
        ...state,
        showLanding: false,
        blur: false,
      };
    case APP_ACTION.SET_AERIAL_MAP_DATA:
      return {
        ...state,
        aerialMapData: payload,
        aerialDataReady: true,
      };
    case APP_ACTION.TOGGLE_BLUR:
      return {
        ...state,
        blur: payload !== undefined ? payload : !state.blur,
      };
    case APP_ACTION.SET_AUDIO_AVAILABLE:
      return {
        ...state,
        audio: {
          ...state.audio,
          available: payload,
        },
      };
    case APP_ACTION.TOGGLE_AUDIO:
      return {
        ...state,
        audio: {
          ...state.audio,
          enabled: payload,
        },
      };

    case APP_ACTION.GO_TO_VARIANTS:
      return {
        ...state,
        variantIndex: payload,
      };

    case APP_ACTION.SET_CONTROL_MODE:
      return {
        ...state,
        controlMode: payload,
      };
    case APP_ACTION.TOGGLE_LANDING:
      return {
        ...state,
        isLandingOpen: payload,
      };
    case APP_ACTION.SET_CENTER_LOOKAT:
      return { ...state, centerLookAt: payload };
    case APP_ACTION.SET_HD_LOADED:
      return { ...state, hdLoaded: payload };
    case APP_ACTION.SET_SD_LOADED:
      return { ...state, sdLoaded: payload };
    case APP_ACTION.SET_RESOLUTIONS:
      return { ...state, resolution: payload };
    default:
      return state;
  }
}

export default appReducer;
