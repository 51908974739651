import qs from 'qs';
import {
  formatJsonImgUrl,
  formatOldJson,
  updateDefaultValues,
  updateScenesAndHotspots,
  updateMenuItemsWithScene,
  updateItemId,
  parseMediaCustom,
  updateFeaturedMedia,
  updateSocialData,
} from './jsonHelper';
import configs from '../configs';

const searchString =
  (window.location.search && window.location.search.slice(1)) || '';
const searchData = qs.parse(searchString);
window.logMessage('searchData', searchData);
const PANO_DATA_KEY = 'panoData';
const cachedPanoUrl = localStorage.getItem(PANO_DATA_KEY) || configs.tourJson;
const panoUrl = searchData[PANO_DATA_KEY] || cachedPanoUrl;
if (searchData[PANO_DATA_KEY]) {
  localStorage.setItem(PANO_DATA_KEY, searchData[PANO_DATA_KEY]);
}

export const loadJson = async (url) => {
  try {
    const response = await fetch(url + '?v=' + new Date().getTime());
    return await response.json();
  } catch (error) {
    window.logMessage('load.json.error:', error);
    return null;
  }
};

export const ensureData = (data) => {
  parseMediaCustom(data.media);
  updateDefaultValues(data);
  updateFeaturedMedia(data);
  updateScenesAndHotspots(data);
  updateMenuItemsWithScene(data);
  updateSocialData(data);
  updateItemId(data);
  window.data = data;
  window.logMessage('window.data', data);
  return data;
};

export const loadFromUrl = async (jsonUrl) => {
  const json = await loadJson(jsonUrl);
  if (json) {
    const data = formatOldJson(formatJsonImgUrl(jsonUrl, json));
    return ensureData(data);
  }
  return null;
};

export const loadFromQueryString = () => {
  return loadFromUrl(panoUrl);
};

export const loadTourDetail = async (tourId) => {
  const response = await fetch(`${configs.apiUrl}Tours/${tourId}/detail`);
  const data = await response.json();
  return data.data;
};

export const loadTourJsons = async (tourId) => {
  try {
    const reqBody = {
      tours: [tourId],
    };
    if (configs.viewMode) {
      reqBody.isPublished = true;
    }
    const response = await fetch(`${configs.apiUrl}Tours/json`, {
      method: 'PATCH',
      body: JSON.stringify(reqBody),
    });
    const { data } = await response.json();
    if (data && data.length) {
      return data;
    }
  } catch (error) {
    window.logMessage('loadTourJsons.error', error);
    console.log(error);
  }
  return [];
};

export const loadPreviewJson = async (tourId) => {
  const data = await loadTourJsons(tourId);
  if (data && data.length) {
    return data.pop();
  }
  return null;
};

export const loadPublishedJson = async (tourId) => {
  const data = await loadTourJsons(tourId);
  if (data && data.length) {
    return data.find((d) => d.isPublished) || null;
  }
  return null;
};

var parsePreloadJsonScript = (jsonStr) => {
  const jsonStrFormatted = jsonStr
    .replace(/__OPEN_TAG__/g, '<')
    .replace(/__CLOSE_TAG__/g, '>');
  return JSON.parse(jsonStrFormatted);
};

export const loadTourJson = async (tourId) => {
  const data = {
    tour: null,
    json: null,
  };

  const preloadEl = document.getElementById('json-data');

  if (preloadEl) {
    data.json = ensureData(parsePreloadJsonScript(preloadEl.textContent));
    data.tour = data.json.tour;

    return Promise.resolve(data);
  }

  try {
    const tour = await loadTourDetail(tourId);
    if (tour && tour.id) {
      const jsonRecord = configs.viewMode
        ? await loadPublishedJson(tour.id)
        : await loadPreviewJson(tour.id);
      window.logMessage('jsonRecord', jsonRecord);
      if (jsonRecord && jsonRecord.jsonUrl) {
        const json = await loadFromUrl(jsonRecord.jsonUrl);
        if (json) {
          data.tour = tour;
          data.json = json;
        }
      }
    }
  } catch (error) {
    window.logMessage('loadTourJson.error', error);
  }
  return data;
};
