import { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useAddHotspotHistory } from './useAddHotspotHistory';
import { useParamsByLocation } from './useParamsByLocation';

export const useNavigateRoute = () => {
  const { tourId, groupId, sceneId } = useParamsByLocation();
  const addHotspotHistory = useAddHotspotHistory();

  const [activeHotspot, setActiveHotspot] = useState('');

  const history = useHistory();

  const handleUpdateRoute = useCallback(
    (hsId = '') => {
      if (hsId) {
        history.push(`/${tourId}/${groupId}/${sceneId}/${hsId}`);
        setActiveHotspot(hsId);
        addHotspotHistory(hsId);
        return;
      }
      history.push(`/${tourId}/${groupId}/${sceneId}`);
      setActiveHotspot('');
    },
    [addHotspotHistory, groupId, history, sceneId, tourId]
  );

  return [handleUpdateRoute, activeHotspot];
};
