import { extend } from '@react-three/fiber';
import * as THREE from 'three';
export default class SkynavFadeMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        // lights: { value: false },
        mixFactor: { value: 0 },
        texture1: { type: 't', value: undefined },
        texture2: { type: 't', value: undefined },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,
      fragmentShader: `
        varying vec2 vUv;
        uniform sampler2D texture1;
        uniform sampler2D texture2;
        uniform float mixFactor;
        void main() {
          vec4 _texture1 = texture2D(texture1, vUv);
          vec4 _texture2 = texture2D(texture2, vUv);
          vec4 finalTexture = mix(_texture1, _texture2, mixFactor);
          gl_FragColor = finalTexture;
        }
      `,
    });
  }

  get texture1() {
    return this.uniforms.texture1.value;
  }
  set texture1(v) {
    this.uniforms.texture1.value = v;
  }
  get texture2() {
    return this.uniforms.texture2.value;
  }
  set texture2(v) {
    this.uniforms.texture2.value = v;
  }
  get mixFactor() {
    return this.uniforms.mixFactor.value;
  }
  set mixFactor(v) {
    this.uniforms.mixFactor.value = v;
  }
}

// register element in react-spring (a.fadeMaterial)
// register element in r3f (<fadeMaterial />)
extend({ SkynavFadeMaterial });
