import React, { Suspense } from 'react';

import * as THREE from 'three';
import { connect, Provider, ReactReduxContext } from 'react-redux';
import { Canvas } from '@react-three/fiber';
import { Stats } from '@react-three/drei';
import { Router } from 'react-router-dom';

import configs from 'configs';
import { getCameraPositionFromLookAtCoordinates } from 'utils/positionHelper';
import HOTSPOT_TYPE from 'consts/hotspotType.const';
import { setResolutions } from 'store/actions';
import { getResolutionByFov } from 'utils/resolutions';

import Cube360 from './cube360';
import WebLensflare from './lensflare';
import WebSunFlare from './lensflare/sunflare';
import LineListHandler from './lineListHandler';

import Controls from '../controls';
import TriangleBlurEffect from '../effects/triangleBlurEffect';

class World3D extends React.Component {
  state = {
    hotspots: [],
    linkedMedias: [],
    linkedScenes: [],
    showHP: false,
    interactionOn: false,
  };
  camera = new THREE.Camera();
  size = {
    width: 0,
    height: 0,
  };
  lastPanTime = null;
  lastPos = {
    x: 0,
    y: 0,
    z: 0,
  };

  onSelectPano = (pano) => {
    this.props.onSwitchPano(pano);
  };

  onSelectPanoById = (gId, sId) => {
    const scene = this.props.json.scenes.find(
      ({ id, groupId }) => id === sId && groupId === gId
    );
    window.logMessage(gId, sId, scene);
    this.onSelectPano(scene);
  };

  onInitResolution = () => {
    const { defaultViewingAngle } = this.props.json.tour;
    const resolution = getResolutionByFov(defaultViewingAngle);
    this.props.setResolutions(resolution);
  };

  componentDidMount() {
    this.onInitResolution();
  }

  componentDidUpdate(preProps) {
    if (
      preProps.currentPano &&
      this.props.currentPano &&
      preProps.currentPano.id !== this.props.currentPano.id
    ) {
      this.setState({ hotspots: [], showHP: false, interactionOn: false });
      this.updateHotspots();
    }
    if (!preProps.currentPano && this.props.currentPano) {
      this.updateHotspots();
    }
  }

  updateHotspots = () => {
    const { variantIndex, currentPano } = this.props;
    const { hotspots, variants } = currentPano;
    if (hotspots && hotspots.constructor === Array) {
      const allHotspots = hotspots.filter(
        (hs) =>
          (hs.type === HOTSPOT_TYPE.MEDIA &&
            hs.media &&
            hs.media.type !== HOTSPOT_TYPE.AUDIO) ||
          (hs.type === HOTSPOT_TYPE.SCENE && hs.scene)
      );
      let selectedHotspots = [];
      const defaultHotspots = allHotspots.filter((h) => !h.sceneVariantId);
      const defaultJumpSpots = defaultHotspots.filter(
        (hs) => hs.type === HOTSPOT_TYPE.SCENE
      );
      const defaultMediaSpots = defaultHotspots.filter(
        (hs) => hs.type === HOTSPOT_TYPE.MEDIA
      );
      const selectedVariant = variants[variantIndex];
      if (variantIndex === currentPano.defaultVariantIndex) {
        selectedHotspots = defaultHotspots;
      } else if (selectedVariant) {
        selectedHotspots = allHotspots.filter(
          (h) => h.sceneVariantId === selectedVariant.id
        );
        if (selectedVariant.useDefaultJumpSpot) {
          selectedHotspots = [...selectedHotspots, ...defaultJumpSpots];
        }
        if (selectedVariant.useDefaultMediaSpot) {
          selectedHotspots = [...selectedHotspots, ...defaultMediaSpots];
        }
      }
      this.setState({ hotspots: selectedHotspots });
    }
  };

  enableInteraction = () => {
    this.setState({ interactionOn: true });
  };

  render() {
    const { hotspots, showHP, interactionOn } = this.state;
    const { currentPano, popupOpen, autoRotate } = this.props;
    const { scenes, tour } = this.props.json;
    const { defaultViewingAngle } = tour;

    if (currentPano) {
      const { x, y, z } = currentPano.defaultOrientation;
      const camPos = getCameraPositionFromLookAtCoordinates(x, y, z);
      return (
        <div className="World-Canvas">
          <div className="Canvas-3D">
            <ReactReduxContext.Consumer>
              {({ store }) => (
                <Canvas
                  camera={{
                    position: [camPos.x, camPos.y, camPos.z],
                    fov: defaultViewingAngle,
                    zoom: 0.8,
                  }}
                >
                  <Provider store={store}>
                    <Router history={this.props.history}>
                      <Suspense fallback={null}>
                        {interactionOn && (
                          <WebLensflare
                            pano={currentPano}
                            variantIndex={this.props.variantIndex}
                          />
                        )}
                      </Suspense>
                      <Suspense fallback={null}>
                        {interactionOn && (
                          <WebSunFlare
                            pano={currentPano}
                            variantIndex={this.props.variantIndex}
                          />
                        )}
                      </Suspense>
                      <Controls
                        mode={this.props.controlMode}
                        autoRotate={autoRotate}
                        enableDamping={interactionOn}
                        enable={interactionOn && !popupOpen}
                      />
                      <Suspense fallback={null}>
                        <Cube360
                          {...this.props.pointerEvents}
                          variantIndex={this.props.variantIndex}
                          pano={currentPano}
                          scenes={scenes}
                          introClosed={this.props.introClosed}
                          onShowHotspots={() => this.updateHotspots()}
                          onChangeLookAt={() => {
                            this.enableInteraction();
                            this.setState({ showHP: true });
                          }}
                        />
                      </Suspense>
                      <Suspense fallback={null}>
                        <pointLight args={[0xffffff]} intensity={1} />
                      </Suspense>
                      <LineListHandler
                        showHP={showHP}
                        hotspots={hotspots}
                        tour={tour}
                        currentSceneCoords={currentPano.coordinates}
                        hotspotHistory={this.props.hotspotHistory}
                        onClickJumpSpot={this.onSelectPanoById}
                        brandColor={
                          this.props.tour && this.props.tour.menuHighlightColor
                        }
                      />
                      {!configs.prod && <Stats showPanel={0} />}
                      <Suspense fallback={null}>
                        <TriangleBlurEffect />
                      </Suspense>
                    </Router>
                  </Provider>
                </Canvas>
              )}
            </ReactReduxContext.Consumer>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ controlMode }) => ({
  controlMode,
});

const mapDispatchToProps = {
  setResolutions,
};

export default connect(mapStateToProps, mapDispatchToProps)(World3D);
