import { RESOLUTIONS } from 'consts/resolutions.const';

const initialState = {
  ready: false,
  tour: null,
  json: null,
  groups: [],
  scenes: [],
  panoMode: 'cube',
  controlMode: 'orbit',
  showLanding: true,
  centerLookAt: null,
  aerialMapData: [],
  aerialDataReady: false,
  menu: {
    isOpen: false,
    position: 'left',
  },
  currentScene: {},
  hotspotHistory: [],
  dimentions: {
    width: 0,
    height: 0,
    dimention: 1,
  },
  shouldBlur: true,
  audio: {
    available: false,
    enabled: true,
  },
  variantIndex: 0,
  isLandingOpen: false,
  sdLoaded: false,
  hdLoaded: false,
  resolution: RESOLUTIONS.SD,
};

export default initialState;
