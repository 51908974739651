import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

window.haveTourAudioSrc = false;

function TourAudio(props) {
  const { featuredMedia } = useSelector((state) => state.json);
  const { enabled } = useSelector((state) => state.audio);
  const audioRef = useRef(null);

  const checkTourAudio = useCallback(() => {
    window.haveTourAudioSrc = Boolean(featuredMedia.audio?.soundUrl);
  }, [featuredMedia.audio]);

  useEffect(() => {
    checkTourAudio();
  }, [checkTourAudio]);

  const audioUrl = useMemo(() => {
    if (featuredMedia?.audio?.soundUrl && enabled)
      return featuredMedia.audio.soundUrl;
    return '';
  }, [featuredMedia, enabled]);

  return (
    <>
      {featuredMedia?.audio && (
        <audio
          ref={audioRef}
          id="tourAudio"
          loop
          controls={false}
          onPlay={() => {
            window.tourAudioPlayed = true;
          }}
          autoPlay={true}
          src={audioUrl}
        />
      )}
    </>
  );
}

export default TourAudio;
