export const RESOLUTIONS = {
  SD: 'size1024',
  MD: 'size1024_f4',
  HD: 'size1024_f16',
};

export const SIZES = ['px', 'nx', 'py', 'ny', 'pz', 'nz'];

export const FRAGS = {
  [RESOLUTIONS.SD]: 1,
  [RESOLUTIONS.MD]: 4,
  [RESOLUTIONS.HD]: 16,
};
