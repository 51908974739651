import React, { useEffect, useMemo, useRef, useState } from 'react';

import { extend, useFrame, useThree } from '@react-three/fiber';
import { useSelector } from 'react-redux';

import configs from 'configs';

import { OrbitControls } from './OrbitControls';

window.toggleSpeed = null;
window.toggleOverride = null;

extend({ OrbitControls });

const isMobileDevice = Math.min(window.innerWidth, window.innerHeight) <= 1024;
const ROTATE_SPEED = isMobileDevice ? -0.5 : -0.25;
const ZOOM_SPEED = isMobileDevice ? 2 : 10;

function Controls({ enableDamping, ...props}) {
  const controlRef = useRef();
  const [autoRotateSpeed, setAutoRotateSpeed] = useState(-1);
  const [autoOverride, setAutoOverride] = useState(false);

  const { camera, gl } = useThree();
  const { centerLookAt } = useSelector((state) => state);

  useEffect(() => {
    if (centerLookAt) {
      const { x, y, z } = centerLookAt;
      camera.position.set(x, y, z);
      camera.updateProjectionMatrix();
    }
  }, [camera, centerLookAt]);

  window.toggleOverride = (val) => {
    if (typeof val === 'undefined') {
      setAutoOverride(!autoOverride);
    } else {
      setAutoOverride(val);
    }
  };
  window.toggleSpeed = () => {
    if (autoRotateSpeed === -1) {
      window.logMessage('DOUBLE SPEED');
      setAutoRotateSpeed(-100);
    } else {
      window.logMessage('NORMAL SPEED');
      setAutoRotateSpeed(-1);
    }
  };
  const autoRotate = useMemo(() => {
    if (autoOverride) {
      return false;
    }
    return !!props.autoRotate;
  }, [props.autoRotate, autoOverride]);
  const enable = useMemo(() => {
    return !!props.enable;
  }, [props.enable]);

  useFrame(() => {
    if (!autoOverride) {
      controlRef.current && controlRef.current.update();
    }
  });
  return (
    <orbitControls
      ref={controlRef}
      args={[camera, gl.domElement]}
      autoRotate={autoRotate}
      autoRotateSpeed={autoRotateSpeed}
      rotateSpeed={ROTATE_SPEED}
      enableZoom={true}
      zoomSpeed={ZOOM_SPEED}
      minZoom={configs.zoom.min}
      maxZoom={configs.zoom.max}
      enableDamping={!!enableDamping}
      enableKeys={true}
      keyPanSpeed={15}
      enabled={enable}
    />
  );
}

export default Controls;
