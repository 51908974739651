import React from 'react';
import * as THREE from 'three';
import { extend } from '@react-three/fiber';
import { loadTextures } from '../../../utils/textureHelper';
import {
  Lensflare,
  LensflareElement,
} from 'three/examples/jsm/objects/Lensflare';
import configs from '../../../configs';

extend({ Lensflare, LensflareElement });

class LensflareComponent extends React.Component {
  constructor(props) {
    super(props);
    this.light = React.createRef();
    this.lensflare = null;
  }

  componentDidMount() {
    this.loadLenflare();
    window.logMessage('lensflare did mount');
  }

  loadLenflare = () => {
    const lensflareImgs = [
      configs.baseUrl +
        '/assets/images/lensflare/sunflare/sunflare-center-blur.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-red.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-yellow.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-green.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-blue.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/rainbow-blur.png',
    ];
    loadTextures(lensflareImgs, 2).then((textures) => {
      const [
        flareSun,
        flareRed,
        flareYellow,
        flareGreen,
        flareBlue,
        flareRainB,
      ] = textures;

      this.lensflare = new Lensflare();
      var color = new THREE.Color(0xffffff);
      this.lensflare.addElement(new LensflareElement(flareSun, 70, 0, color));
      this.lensflare.addElement(new LensflareElement(flareRed, 40, 0.5));
      this.lensflare.addElement(new LensflareElement(flareYellow, 40, 0.515));
      this.lensflare.addElement(new LensflareElement(flareGreen, 40, 0.53));
      this.lensflare.addElement(new LensflareElement(flareBlue, 50, 0.6));
      this.lensflare.addElement(new LensflareElement(flareRainB, 1500, 0.4));
      if (this.light.current) {
        this.light.current.add(this.lensflare);
      }
    });
  };

  getLightPosition = () => {
    const aspect = 0.99;
    // const aspect = 1.1;
    const [x, y, z] = this.props.position;
    return {
      x: x * aspect,
      y: y * aspect,
      z: z * aspect,
    };
  };

  componentDidUpdate(preProps) {
    if (
      JSON.stringify(preProps.position) !== JSON.stringify(this.props.position)
    ) {
      window.logMessage('lensflare position updated!');
      const { x, y, z } = this.getLightPosition();
      if (this.light.current) {
        this.light.current.position.set(x, y, z);
      }
    }
  }

  render() {
    const { x, y, z } = this.getLightPosition();
    return (
      <pointLight
        ref={this.light}
        color={0xffffff}
        intensity={1}
        distance={2000}
        decay={2}
        position={[x, y, z]}
      />
    );
  }
}

export default LensflareComponent;
