import React from 'react';

function IconClose2D(props) {
  const color = props.color || '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.49 56.58"
      height="2rem"
    >
      <path
        opacity={0.5}
        d="M1027.39,511.78c-51.74,0-93.83.11-104.79.22-17.73.18-30.11,15.65-27.3,33,2.17,13.41,13.93,23.2,28.21,23.29,15.49.09,12.63,0,28.13,0h75.75Z"
        transform="translate(-894.9 -511.78)"
      />
      <path
        fill={color}
        d="M923.2,527.41a12.66,12.66,0,1,0,12.65,12.66A12.65,12.65,0,0,0,923.2,527.41ZM928.76,544a1.16,1.16,0,1,1-1.65,1.64l-3.91-3.9-3.91,3.9a1.16,1.16,0,0,1-1.65-1.64l3.91-3.91-3.91-3.91a1.18,1.18,0,0,1,0-1.65,1.16,1.16,0,0,1,1.65,0l3.91,3.91,3.91-3.91a1.16,1.16,0,0,1,1.65,0,1.18,1.18,0,0,1,0,1.65l-3.91,3.91Z"
        transform="translate(-894.9 -511.78)"
      />
      <path
        fill={color}
        d="M922.6,512c-17.73.18-30.11,15.65-27.3,33,2.17,13.41,13.93,23.2,28.21,23.29,15.49.09,12.63,0,28.13,0h75.75V511.78C975.65,511.81,933.56,511.89,922.6,512Zm.6,42.94a14.88,14.88,0,1,1,14.88-14.87A14.87,14.87,0,0,1,923.2,554.94Zm30.16-11.88a2.64,2.64,0,0,0,2.33,1.17,3.1,3.1,0,0,0,2.12-.63,3.26,3.26,0,0,0,.89-2.1h2.38a5,5,0,0,1-1.62,3.42,5.56,5.56,0,0,1-3.77,1.24,5.37,5.37,0,0,1-2.89-.78,5.13,5.13,0,0,1-1.92-2.21,7.92,7.92,0,0,1-.71-3.33v-1.28a8,8,0,0,1,.69-3.43,5.24,5.24,0,0,1,2-2.28,5.56,5.56,0,0,1,3-.8,5.33,5.33,0,0,1,3.66,1.23,5.15,5.15,0,0,1,1.61,3.48H958.7a3.39,3.39,0,0,0-.86-2.13,2.83,2.83,0,0,0-2-.66,2.77,2.77,0,0,0-2.39,1.14,5.72,5.72,0,0,0-.86,3.34v1.21A6,6,0,0,0,953.36,543.06ZM971.83,546h-8.62V532.24h2.38v11.82h6.24ZM984,539.46a8.31,8.31,0,0,1-.7,3.54,5.28,5.28,0,0,1-2,2.34,5.82,5.82,0,0,1-6,0,5.34,5.34,0,0,1-2-2.32,8,8,0,0,1-.72-3.48v-.78a8.38,8.38,0,0,1,.71-3.54,5.23,5.23,0,0,1,2-2.35,5.39,5.39,0,0,1,3-.82,5.53,5.53,0,0,1,3,.81,5.25,5.25,0,0,1,2,2.32,8.37,8.37,0,0,1,.72,3.52Zm10.62,5.68a5.69,5.69,0,0,1-3.57,1,6.68,6.68,0,0,1-2.74-.56,4.52,4.52,0,0,1-1.94-1.52,3.81,3.81,0,0,1-.69-2.26h2.38a2.24,2.24,0,0,0,.77,1.81,3.4,3.4,0,0,0,2.22.64,3,3,0,0,0,1.87-.5,1.64,1.64,0,0,0,.63-1.34,1.66,1.66,0,0,0-.64-1.4,7.77,7.77,0,0,0-2.29-1,11.16,11.16,0,0,1-2.64-1.12,3.52,3.52,0,0,1-1.88-3.07,3.36,3.36,0,0,1,1.35-2.74,5.55,5.55,0,0,1,3.52-1.07,6,6,0,0,1,2.55.53,4.11,4.11,0,0,1,1.76,1.5,3.84,3.84,0,0,1,.64,2.17h-2.37a2.17,2.17,0,0,0-.68-1.69A2.78,2.78,0,0,0,991,534a2.94,2.94,0,0,0-1.82.5,1.67,1.67,0,0,0-.64,1.4,1.52,1.52,0,0,0,.69,1.26,8.18,8.18,0,0,0,2.3,1,11,11,0,0,1,2.58,1.08,4,4,0,0,1,1.42,1.4,3.64,3.64,0,0,1,.45,1.84A3.24,3.24,0,0,1,994.63,545.14ZM1007,546h-9V532.24H1007v1.92h-6.53V538h5.64v1.89h-5.64v4.22H1007Z"
        transform="translate(-894.9 -511.78)"
      />
      <path
        fill={color}
        d="M978.31,534a2.82,2.82,0,0,0-2.42,1.21,6,6,0,0,0-.89,3.43v.79a6,6,0,0,0,.88,3.5,2.86,2.86,0,0,0,2.45,1.23,2.81,2.81,0,0,0,2.44-1.2,6.09,6.09,0,0,0,.85-3.53v-.72a5.94,5.94,0,0,0-.86-3.49A2.83,2.83,0,0,0,978.31,534Z"
        transform="translate(-894.9 -511.78)"
      />
    </svg>
  );
}

export default IconClose2D;
