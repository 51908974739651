import React, { useCallback, useEffect, useState } from 'react';

import Color from 'color';
import { connect, useDispatch } from 'react-redux';

import { useNavigateRoute, useParamsByLocation } from 'common/hooks';
import configs from 'configs';
import { isMobile } from 'deviceDetect';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';
import {
  setControlMode,
  setMenuPosition,
  toggleAudio,
  toggleMenu,
  togglePanoMode,
} from 'store/actions';
import { handleSwitchControlMode } from 'utils/controlHelper';
import { isMenuLeft } from 'utils/menuHelper';

import Icon2dMap from './images/icon2dMap';
import IconAudio from './images/iconAudio';
import IconAutoRotate from './images/iconAutoRotate';
import IconContact from './images/iconContact';
import IconFullScreen from './images/iconFullScreen';
import IconGyro from './images/iconGyro';
import IconLink from './images/iconLink';
import IconMenu from './images/iconMenu';
import IconSky from './images/iconSky';
import PanoMenu from './panoMenu';

import './style.scss';

const getActiveStartColor = (clr) => Color(clr).darken(0.7).alpha(1).string();
const getActiveEndColor = (clr) => Color(clr).darken(0.95).alpha(0.8).string();

const enableGyro = !!isMobile();

const defaultMenuBgColor = 'rgba(0,0,0,0.8)';
const activeMenuBgColor = (color, isLeft) =>
  `linear-gradient(${isLeft ? '90' : '0'}deg, ${getActiveStartColor(
    color
  )} 0%, ${getActiveEndColor(color)} 100%)`;

const isFullscreen = () =>
  (document.fullscreenElement && document.fullscreenElement !== null) ||
  (document.webkitFullscreenElement &&
    document.webkitFullscreenElement !== null) ||
  (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
  (document.msFullscreenElement && document.msFullscreenElement !== null);

const canFullscreen = () => {
  const docElm = document.documentElement;
  if (
    docElm.requestFullscreen ||
    docElm.mozRequestFullScreen ||
    docElm.webkitRequestFullScreen ||
    docElm.msRequestFullscreen
  ) {
    return true;
  }
  return false;
};

function toggleFullScreen() {
  const docElm = document.documentElement;
  if (!isFullscreen()) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    } else {
      window.logMessage('cannot do full screen');
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else {
      window.logMessage('cannot exit full screen');
    }
  }
}

function Menu(props) {
  const {
    autoRotate,
    dimentions,
    menuList,
    isNavOpen,
    menuLeft,
    menuPosition,
    color,
    audio,
    showSpinner,
    featuredMedia,
    gyroEnabled,
    controlMode,
  } = props;

  const [handleUpdateRoute] = useNavigateRoute();
  const { hotspotId: activeHotspot } = useParamsByLocation();
  const dispatch = useDispatch();

  const isLeft = isMenuLeft(dimentions);
  if (isLeft !== menuLeft) {
    props.setMenuPosition(isLeft ? 'left' : 'bottom');
  }
  const [spinnerState, setSpinnerState] = useState(showSpinner ? 'on' : 'off');
  useEffect(() => {
    setTimeout(() => setSpinnerState(showSpinner ? 'on' : 'off'), 1500);
  }, [showSpinner]);

  const menuOpenScene = (gId, sId) => {
    props.onSelect(gId, sId);
    trackEventByType(EVENT_TYPE.SCENE_FROM_MENU, sId);
  };

  const switchControlMode = useCallback(async () => {
    const switchToMode = await handleSwitchControlMode(controlMode);
    if (switchToMode !== controlMode) dispatch(setControlMode(switchToMode));
  }, [controlMode, dispatch]);

  return (
    <>
      <div
        className={`Nav-Menu Menu-${menuPosition} Nav-Menu-${
          isNavOpen ? 'open' : 'close'
        } spinner-${spinnerState}`}
      >
        <div className="Menu-items">
          <div
            className={`Menu-item ${isNavOpen ? 'highlighted' : ''}`}
            onClick={() => props.toggleMenu(!isNavOpen)}
          >
            <div className="Menu-item-content">
              <p className="Menu-item-icon">
                <IconMenu color={isNavOpen ? color : 'white'} />
              </p>
            </div>
          </div>
          <div
            className="Menu-item"
            style={{
              background:
                activeHotspot === configs.mapRoute
                  ? activeMenuBgColor(color, isLeft)
                  : defaultMenuBgColor,
            }}
            onClick={() => handleUpdateRoute(configs.mapRoute)}
          >
            <p className="Menu-item-icon">
              <IconSky color="white" />
            </p>
          </div>
          {/* <div className="Menu-item">
            <p className="Menu-item-icon">
              <IconShare />
            </p>
          </div> */}
          <div
            className="Menu-item"
            style={{
              background:
                activeHotspot === configs.socialRoute
                  ? activeMenuBgColor(color, isLeft)
                  : defaultMenuBgColor,
            }}
            onClick={() => handleUpdateRoute(configs.socialRoute)}
          >
            <p className="Menu-item-icon">
              <IconLink color="white" />
            </p>
          </div>
          {featuredMedia.map && (
            <div
              className="Menu-item"
              style={{
                background:
                  activeHotspot === featuredMedia.map.id
                    ? activeMenuBgColor(color, isLeft)
                    : defaultMenuBgColor,
              }}
              onClick={() => handleUpdateRoute(featuredMedia.map.id)}
            >
              <p className="Menu-item-icon">
                <Icon2dMap color="white" />
              </p>
            </div>
          )}
          {featuredMedia.contact && (
            <div
              className="Menu-item"
              style={{
                background:
                  activeHotspot === featuredMedia.contact.id
                    ? activeMenuBgColor(color, isLeft)
                    : defaultMenuBgColor,
              }}
              onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
            >
              <p className="Menu-item-icon">
                <IconContact color="white" />
              </p>
            </div>
          )}
          <div className="Menu-item-space"></div>
          {/* <div className="Menu-item Menu-vr">
            <p className="Menu-item-icon">
              <IconVR color={'white'} />
            </p>
          </div> */}
          {audio.available && (
            <div
              className="Menu-item"
              onClick={() => props.toggleAudio(!audio.enabled)}
            >
              <p className="Menu-item-icon">
                <IconAudio
                  muted={!audio.enabled}
                  color={audio.enabled ? color : 'white'}
                />
              </p>
            </div>
          )}
          {enableGyro && (
            <div className="Menu-item" onClick={switchControlMode}>
              <p className="Menu-item-icon">
                <IconGyro color={gyroEnabled ? color : 'white'} />
              </p>
            </div>
          )}
          <div className="Menu-item" onClick={() => props.toggleRotate()}>
            <p className="Menu-item-icon">
              <IconAutoRotate color={autoRotate ? color : 'white'} />
            </p>
          </div>
          {canFullscreen() && (
            <div className="Menu-item" onClick={toggleFullScreen}>
              <p className="Menu-item-icon">
                <IconFullScreen color={isFullscreen() ? color : 'white'} />
              </p>
            </div>
          )}
        </div>
      </div>
      <PanoMenu
        isOpen={isNavOpen}
        menu={menuList}
        location={menuPosition}
        onClose={() => props.toggleMenu(false)}
        onSelect={menuOpenScene}
      />
    </>
  );
}

const mapStateToProps = ({
  dimentions,
  menu,
  panoMode,
  audio,
  tour,
  json,
  currentHotspot,
  controlMode,
}) => ({
  dimentions,
  isNavOpen: menu.isOpen,
  menuLeft: menu.position === 'left',
  menuPosition: menu.position,
  color: (tour && tour.menuHighlightColor) || 'white',
  audio,
  panoMode,
  currentHotspot,
  featuredMedia: json.featuredMedia,
  gyroEnabled: controlMode === 'device',
  controlMode,
});

const mapDispatchToProps = {
  toggleMenu,
  togglePanoMode,
  setMenuPosition,
  toggleAudio,
  setControlMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
