import React from 'react';

function IconZoomIn(props) {
  const color = props.color || '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 56.61"
      height="2rem"
    >
      <path
        opacity={0.5}
        d="M1004.94,511.79c-30.55,0-61.72,0-90,0v56.58h90Z"
        transform="translate(-914.94 -511.79)"
      />
      <path
        fill={color}
        d="M917.76,511.81v56.58h87.18V511.79C975.4,511.79,945.29,511.79,917.76,511.81Zm57.86,43.25a1.2,1.2,0,0,1-1,.87,1.44,1.44,0,0,1-1.21-.43L966.89,549a3.07,3.07,0,0,0-.26-.25c-.33.25-.63.49-1,.7a12.64,12.64,0,0,1-6.54,2.15,12.31,12.31,0,0,1-6.67-1.55,12.51,12.51,0,0,1-5.42-5.83,12.7,12.7,0,0,1,9.59-17.84c.56-.08,1.12-.12,1.68-.18h.49l.33,0c.65.07,1.31.11,2,.24a12.46,12.46,0,0,1,6.91,3.94,12.33,12.33,0,0,1,3.12,6.56,12.56,12.56,0,0,1-1.88,8.81c-.27.41-.56.79-.87,1.23a2.65,2.65,0,0,1,.26.21c2.11,2.11,4.23,4.21,6.33,6.33a6.69,6.69,0,0,1,.66.92Z"
        transform="translate(-914.94 -511.79)"
      />
      <path
        fill={color}
        d="M958.58,528.75a10.2,10.2,0,1,0,10.19,10.18A10.21,10.21,0,0,0,958.58,528.75Zm4.72,11.32a1.78,1.78,0,0,1-.65.11c-.93,0-1.86,0-2.82,0,0,.13,0,.23,0,.34,0,.86,0,1.72,0,2.58a1.23,1.23,0,0,1-1.49,1.28,1.19,1.19,0,0,1-1-1.19c0-.88,0-1.76,0-2.64v-.37c-1,0-2,0-3,0a1.24,1.24,0,1,1,0-2.48h2.92v-.35c0-.91,0-1.82,0-2.73a1.23,1.23,0,0,1,2.34-.42,2.35,2.35,0,0,1,.12.71c0,.8,0,1.61,0,2.42v.37h.35c.87,0,1.74,0,2.61,0a1.25,1.25,0,0,1,1.25,1A1.22,1.22,0,0,1,963.3,540.07Z"
        transform="translate(-914.94 -511.79)"
      />
    </svg>
  );
}

export default IconZoomIn;
