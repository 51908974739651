/**
 * Debounce Function
 * @param {function} func function execute
 * @param {number} wait miliseconds
 * @param {boolean} immediate check to run function immediate
 * @returns void
 */
export function debounce(func, wait, immediate = false) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
