import React from 'react';

const Icon2dMap = (props) => {
  const color = props.color || '#fff';
  return (
    <svg
      id="Icon2dMap"
      data-name="Layer Map Floor"
      xmlns="http://www.w3.org/2000/svg"
      className="sn-icon sn-icon-2d"
      viewBox="0 0 50.93 47.86"
    >
      <path
        fill={color}
        d="M984,557c.87-.35,1.12-.6,1.11-1.57,0-10,0-20.06,0-29.81V516.4l0,0-3.89,1.67c-3.75,1.63-7.57,3.28-11.38,4.86a4.3,4.3,0,0,1-2.87.1c-4.63-1.68-9.46-3.51-14.38-5.44a4.43,4.43,0,0,0-1.6-.34,3.78,3.78,0,0,0-1.58.37c-5.36,2.41-9.8,4.32-14,6-1,.4-1.16.78-1.16,1.86,0,9.76,0,19.68,0,29.29v9.47L937.1,563c4.25-1.82,8.27-3.55,12.37-5.25a4.42,4.42,0,0,1,1.66-.31,4.09,4.09,0,0,1,1.35.22c5.51,2,10.08,3.71,14.38,5.44A3.67,3.67,0,0,0,970,563C975.6,560.55,979.92,558.7,984,557Zm-34.48-3.39-.21,1-.89.58a4,4,0,0,1-.7.35c-2.27.91-4.45,1.86-6.76,2.86l-4,1.77v-6c0-8.71,0-17.71,0-26.56l.07-.56.4-1.32,1-.46c2.46-1.13,4.88-2.17,7.44-3.28l3.7-1.58v11c0,7,0,14.19,0,21.28A4.22,4.22,0,0,1,949.54,553.62Zm17.56.61v6l-3.78-1.41c-3.29-1.23-6.36-2.38-9.46-3.57l-1.6-.61V553c0-8.76,0-17.7,0-26.37v-6.2l3.37,1.26c3.12,1.17,6.6,2.47,9.9,3.77l1.56.62,0,1.69C967.11,536.57,967.1,545.55,967.1,554.23Zm2.64-5c0-7,0-14.19,0-21.3v-.5h0c0-.12,0-.23,0-.35l.2-1,.88-.58a4.39,4.39,0,0,1,.72-.36c2.27-.92,4.46-1.87,6.79-2.88l4-1.75v10.44c0,7.19,0,14.62,0,21.93a4.32,4.32,0,0,1,0,.66l-.18,1.18-1,.6c-.1.05-.26.14-.53.25-2.32,1-4.64,2-6.87,2.93l-4,1.75Z"
        transform="translate(-934.2 -516.4)"
      />
    </svg>
  );
};

export default Icon2dMap;
