import React, { useEffect, useRef, useState } from 'react';
import { isPhone } from 'deviceDetect';

import { DeviceOrientationReact } from './deviceOrientationControls/DeviceOrientationReact';
import ControlV2 from './orbitControlsV2';
import ControlV1 from './orbitControls';

const isMobileDevice = Boolean(isPhone());

const Controls = ({ mode, ...props }) => {
  const [enableDevice, setEnableDevice] = useState(() => mode === 'device');

  const deviceRef = useRef(null);

  // Handle set mode
  useEffect(() => {
    setTimeout(() => {
      setEnableDevice(mode === 'device');
    }, 100);
  }, [mode]);

  if (enableDevice) {
    return <DeviceOrientationReact ref={deviceRef} {...props} />;
  }

  if (isMobileDevice) {
    return <ControlV1 {...props} />;
  }

  return <ControlV2 {...props} />;
};

export default Controls;
