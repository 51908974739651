import MEDIA_TYPE from '../../consts/mediaType.const';
// YOUTUBE
const getYoutubeVideoId = (videoUrl) => {
  if (!videoUrl) {
    return 'false';
  }
  // eslint-disable-next-line
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
  const match = videoUrl.match(regExp);
  return match && match[7].length === 11 ? match[7] : 'false';
};
const getYouTubeEmbeddedUrl = (videoId) =>
  `https://www.youtube.com/embed/${videoId}?autoplay=1`;
// VIMEO
const getVimeoEmbeddedUrl = (vimeoId) =>
  `https://player.vimeo.com/video/${vimeoId}`;

// INDEX
const getVideoEmbeddedFrame = (videoEmbeddedUrl) => {
  return `<iframe src="${videoEmbeddedUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
};

const getVideoEmbeddedIframe = {
  [MEDIA_TYPE.YOUTUBE]: (media) => {
    const youtubeUrl = media.videoUrl || media.videoId;
    return getVideoEmbeddedFrame(
      getYouTubeEmbeddedUrl(getYoutubeVideoId(youtubeUrl))
    );
  },
  [MEDIA_TYPE.VIMEO]: (media) =>
    getVideoEmbeddedFrame(getVimeoEmbeddedUrl(media.videoId)),
};

export const getHtml = (media) => ({
  __html: getVideoEmbeddedIframe[media.videoPlatform](media),
});
