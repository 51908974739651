import { RESOLUTIONS } from 'consts/resolutions.const';
import { isPhone } from 'deviceDetect';

export const getPhoneResolutionByFov = (fov) => {
  if (fov < 60) return RESOLUTIONS.MD;
  return RESOLUTIONS.SD;
};

export const getResolutionByFov = (fov) => {
  if (isPhone()) {
    return getPhoneResolutionByFov(fov);
  }
  if (fov >= 0 && fov < 45) return RESOLUTIONS.HD;
  if (fov < 90) return RESOLUTIONS.MD;
  return RESOLUTIONS.SD;
};
