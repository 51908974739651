import React, { useRef, useEffect } from 'react';
import { CANVAS_W, CANVAS_H, CANVAS_STYLE } from './utils';

export const CanvasCircle = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      var ctx = ref.current.getContext('2d');
      ctx.clearRect(0, 0, CANVAS_W, CANVAS_H);
      ctx.beginPath();
      ctx.moveTo(CANVAS_W / 2 + 2, CANVAS_H / 2);
      ctx.arc(CANVAS_W / 2, CANVAS_H / 2, 2, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.moveTo(CANVAS_W / 2 + 5, CANVAS_H / 2);
      ctx.arc(CANVAS_W / 2, CANVAS_H / 2, 5, 0, 2 * Math.PI);
      ctx.strokeStyle = 'red';
      ctx.stroke();
    }
  }, [ref]);

  return (
    <canvas ref={ref} width={CANVAS_W} height={CANVAS_H} style={CANVAS_STYLE} />
  );
};

export default React.memo(CanvasCircle);
