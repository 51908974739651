import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { loadImageData } from 'utils/imageHelper';
import ScenesDragged from './scenesDragged';
import './style.scss';
import IconClose from './iconClose2D';
import IconResetSize from './iconResetSize';
import IconZoomIn from './iconZoomIn';
import IconZoomOut from './iconZoomOut';

const ZOOM_ANIMATION_TIME = 400;
const ZOOM_EVENT_TIME_START = 200;
const DEFAULT_SCALE = 1;
const MIN_SCALE = 0.5;
const MAX_SCALE = 5;
const MapScenePreview = ({
  media,
  menuList,
  scenes,
  hotspotHistory,
  tour,
  closeHotspotPopup,
}) => {
  const imgRef = useRef();
  const container = useRef();
  const [center, setCenter] = useState({ x: 0, y: 0 });
  const [imgReady, setImgReady] = useState(false);
  const [zooming, setZooming] = useState(false);

  const { image } = useMemo(() => media.mapFloorPlan, [media]);

  const { newMedia } = useMemo(() => {
    const allScenes = cloneDeep(menuList).reduce((agg, menu) => {
      agg.push(...menu.scenes);
      return agg;
    }, []);
    const cloneMedia = cloneDeep(media);

    const newMedia = {
      ...cloneMedia,
      mapFloorPlan: {
        ...cloneMedia.mapFloorPlan,
        objects: cloneMedia.mapFloorPlan.objects.map((obj, index) => {
          const mapIndexWithMenu = findIndex(
            allScenes,
            (e) => e._id === obj.id
          );
          if (mapIndexWithMenu > -1) {
            obj.index = mapIndexWithMenu;
          } else {
            obj.index = index;
          }
          return obj;
        }),
      },
    };

    return { newMedia };
  }, [media, menuList]);

  const [dataImage, setDataImage] = useState(null);

  useEffect(() => {
    setDataImage(null);
  }, [media]);

  useEffect(() => {
    const loadImage = async () => {
      const img = await loadImageData(image.url);
      setDataImage(img);
    };
    loadImage();
  }, [image.url]);

  const [size, setSize] = useState({ width: 0, height: 0 });
  const onResize = useCallback(() => {
    if (container.current && (!size.width || !size.height)) {
      const { width, height } = container.current.getBoundingClientRect();
      const containerRatio = width / height;
      const imgRatio = image.width / image.height;
      const divSize = { width: 0, height: 0 };
      if (containerRatio > imgRatio) {
        // should take width of container
        divSize.width = width;
        divSize.height = width / imgRatio;
      } else {
        // should take height of container
        divSize.height = height;
        divSize.width = imgRatio * height;
      }
      setSize(divSize);
      const centerNew = {
        x: (divSize.width - width) / 2,
        y: (divSize.height - height) / 2,
      };

      setCenter(centerNew);
    }
  }, [image, size]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  const onClickZoom = (event, zoomEvent) => {
    setZooming(true);
    setTimeout(() => {
      zoomEvent();
    }, ZOOM_EVENT_TIME_START);
    setTimeout(() => {
      setZooming(false);
    }, ZOOM_ANIMATION_TIME + ZOOM_EVENT_TIME_START);
  };

  const onClickResetZoom = (zoomEvent) => {
    setZooming(true);
    setTimeout(() => {
      zoomEvent(-1 * center.x, -1 * center.y, 1);
    }, ZOOM_EVENT_TIME_START);
    setTimeout(() => {
      setZooming(false);
    }, ZOOM_ANIMATION_TIME + ZOOM_EVENT_TIME_START);
  };

  return (
    <div className="container-2d-map" ref={container}>
      <TransformWrapper
        initialScale={DEFAULT_SCALE}
        wheel={{ step: 0.5 }}
        minScale={MIN_SCALE}
        maxScale={MAX_SCALE}
        velocityAnimation={{
          animationType: 'easeOutCubic',
          equalToMove: true,
        }}
        doubleClick={{ mode: 'reset' }}
        limitToBounds={false}
      >
        {({
          setTransform,
          zoomIn,
          zoomOut,
          state: { scale, positionX, positionY },
        }) => {
          return (
            <>
              {dataImage && imgReady && (
                <div className="tools">
                  <div
                    className="item close"
                    onClick={() => closeHotspotPopup()}
                  >
                    <IconClose />
                  </div>
                  <div
                    className="item zoom-in"
                    onClick={(event) => {
                      if (scale < MAX_SCALE) {
                        onClickZoom(event, zoomIn);
                      }
                    }}
                  >
                    <IconZoomIn />
                  </div>
                  <div
                    className="item zoom-out"
                    onClick={(event) => {
                      if (scale > MIN_SCALE) {
                        onClickZoom(event, zoomOut);
                      }
                    }}
                  >
                    <IconZoomOut />
                  </div>
                  <div
                    className="item full-view"
                    onClick={() => {
                      onClickResetZoom(setTransform);
                    }}
                  >
                    <IconResetSize />
                  </div>
                </div>
              )}
              <TransformComponent>
                <img
                  src={dataImage}
                  alt={media.title}
                  ref={imgRef}
                  style={{
                    width: size.width + 'px',
                    height: size.height + 'px',
                  }}
                  onLoad={() => {
                    setTimeout(() => {
                      setImgReady(true);
                    }, 1000);
                    imgRef.current.style.opacity = 1;
                    setTransform(-1 * center.x, -1 * center.y, 1, 0);
                  }}
                />
              </TransformComponent>
              {imgReady && !zooming && dataImage
                ? newMedia.mapFloorPlan.objects.map((item) => (
                    <ScenesDragged
                      item={item}
                      tour={tour}
                      scale={scale}
                      positionX={positionX}
                      positionY={positionY}
                      hotspotHistory={hotspotHistory}
                      size={size}
                      key={item.id}
                      media={media}
                      scenes={scenes}
                      imgRoot={image}
                    />
                  ))
                : null}
              {!dataImage ? (
                <p className="loading-placeholder">Loading</p>
              ) : null}
            </>
          );
        }}
      </TransformWrapper>
    </div>
  );
};

export default memo(MapScenePreview);
