import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Callout Info Box"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.66 32.61"
    >
      <path
        fill={bgColor}
        d="M973.15,528.72a16.32,16.32,0,0,0-11.59-5h-.2a16.38,16.38,0,0,0-16.3,15.65,16.55,16.55,0,0,0,4.63,12,16,16,0,0,0,11.64,5h.23a16.37,16.37,0,0,0,16.14-16.15A16,16,0,0,0,973.15,528.72Zm-11.59,24.62h-.23a13.3,13.3,0,1,1,0-26.6h.15a13.3,13.3,0,0,1,0,26.6Z"
        transform="translate(-945.05 -523.74)"
      />
      <path
        opacity="0.65"
        d="M972.07,529.77a14.85,14.85,0,0,0-10.53-4.53h-.17a14.89,14.89,0,0,0-14.82,14.2,15.07,15.07,0,0,0,4.22,10.91,14.56,14.56,0,0,0,10.56,4.49h.23a14.86,14.86,0,0,0,14.64-14.67A14.41,14.41,0,0,0,972.07,529.77Zm-10.7-3h.15c-.26,0-.51,0-.77,0C961,526.76,961.16,526.74,961.37,526.74Zm.19,26.6h-.23c-.24,0-.47,0-.71,0,.26,0,.52,0,.78,0a6.78,6.78,0,0,0,.78,0C962,553.32,961.77,553.34,961.56,553.34Z"
        transform="translate(-945.05 -523.74)"
      />
      <path
        fill={bgColor}
        d="M961.52,526.74a13.3,13.3,0,1,0,13.18,13.4A13.36,13.36,0,0,0,961.52,526.74ZM958.41,548a1.82,1.82,0,0,1-.53-2.06c.33-1.41.66-2.82,1.06-4.22.22-.79,0-1.22-.78-1.4-.21,0-.55-.22-.54-.32a.93.93,0,0,1,.33-.64,6.68,6.68,0,0,1,3.9-1.29,1.68,1.68,0,0,1,1.78,2.25c-.31,1.38-.68,2.76-1,4.13-.29,1.2-.23,1.3.92,1.69a2,2,0,0,1,.42.25C963.31,547.92,959.62,549,958.41,548Zm4.2-12a2.51,2.51,0,1,1,2.44-2.53A2.5,2.5,0,0,1,962.61,536Z"
        transform="translate(-945.05 -523.74)"
      />
    </svg>
  );
};

export default Icon;
