import React, { useEffect, useState, useCallback } from 'react';

import { useThree, useFrame } from '@react-three/fiber';
import { gsap, Power4 } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';

import configs from 'configs';
import { getCameraPositionFromLookAtCoordinates } from 'utils/positionHelper';
import { getResolutionByFov } from 'utils/resolutions';
import { setResolutions } from 'store/actions';

import Cube360 from './cube';
import { getCurvePoints } from './lookUtils';

var position;
var coords = [];
var startTime;

const updated = false;
const fovObj = { fov: 0 };

function Cube(props) {
  const { introClosed } = props;

  const threeContext = useThree();
  const { camera } = threeContext;
  // if (!window.gl) {
  //   window.gl = gl;
  // }

  const { id: sceneId } = useSelector(({ currentScene }) => currentScene);
  const { defaultViewingAngle } = useSelector(({ tour }) => tour);
  const dispatch = useDispatch();

  // state
  const [isFirst, setIsFirst] = useState(true);

  useFrame(({ gl, scene, camera }) => {
    if (!window.gl) {
      window.gl = gl;
    }
    gl.render(scene, camera);
  }, true);

  // handle update camera fov
  const handleUpdateCameraFov = useCallback(
    (fov) => {
      camera.fov = fov;
      camera.updateProjectionMatrix();
    },
    [camera]
  );

  const handleUpdatedCameraFov = useCallback(() => {
    const resolution = getResolutionByFov(defaultViewingAngle);
    dispatch(setResolutions(resolution));
  }, [defaultViewingAngle, dispatch]);

  const animateCameraFov = useCallback(
    (firstLook) => {
      const currentFov = camera.getEffectiveFOV();
      if (
        !sceneId ||
        !defaultViewingAngle ||
        currentFov === defaultViewingAngle ||
        firstLook
      )
        return;

      fovObj.fov = currentFov;
      gsap.to(fovObj, {
        fov: defaultViewingAngle,
        duration: configs.crossfadeSpeed,
        onUpdate: () => handleUpdateCameraFov(fovObj.fov),
        onComplete: handleUpdatedCameraFov,
      });
    },
    [
      camera,
      defaultViewingAngle,
      handleUpdateCameraFov,
      handleUpdatedCameraFov,
      sceneId,
    ]
  );

  const setCamPosition = useCallback(
    (x, y, z) => {
      camera.position.set(x, y, z);
    },
    [camera.position]
  );

  const handleLinear = () => {
    const { x, y, z } = position;
    setCamPosition(x, y, z);
    window.logMessage('position', x, y, z);
  };

  const linearUpdate = (newPos) => {
    position = updated ? camera.position.clone() : { x: 0, y: 0, z: 1 };
    window.logMessage('position, newPos', position, newPos);
    gsap.to(position, {
      x: newPos.x,
      y: newPos.y,
      z: newPos.z,
      duration: configs.crossfadeSpeed,
      onUpdate: handleLinear,
      onComplete: () => {
        window.logMessage('here');
        props.onChangeLookAt();
      },
    });
  };

  const updateLookAt = ([x, y, z]) => {
    window.logMessage('updateLookAt');
    const newPos = getCameraPositionFromLookAtCoordinates(x, y, z);
    linearUpdate(newPos);
  };

  const firstLinearUpdate = () => {
    if (coords.length) {
      var indexing = { i: coords.length - 1 };
      gsap.to(indexing, {
        i: 0,
        duration: 6,
        ease: Power4.easeInOut,
        onUpdate: () => {
          const round = Math.round(indexing.i);
          if (indexing.i === round) {
            const { x, y, z } = coords[round];
            setCamPosition(x, y, z);
          } else {
            const ceil = Math.ceil(indexing.i);
            const floor = Math.floor(indexing.i);
            const cPercent = indexing.i - floor;
            const fPercent = ceil - indexing.i;
            const x = coords[ceil].x * cPercent + coords[floor].x * fPercent;
            const y = coords[ceil].y * cPercent + coords[floor].y * fPercent;
            const z = coords[ceil].z * cPercent + coords[floor].z * fPercent;
            setCamPosition(x, y, z);
          }
        },
        onComplete: props.onChangeLookAt,
      });
    } else {
      props.onChangeLookAt();
      window.logMessage('totalTime', new Date() - startTime);
    }
  };

  const firstAnimateCamera = ([x, y, z]) => {
    coords = getCurvePoints(x, y, z).map((p) =>
      getCameraPositionFromLookAtCoordinates(p.x, p.y, p.z)
    );
    window.logMessage('coords', coords.length, coords);
    coords.pop(); // last coord is wrong
    position = coords.pop();
    handleLinear();
  };

  useEffect(() => {
    if (isFirst && introClosed && coords.length) {
      window.logMessage('introClosed', introClosed);
      startTime = new Date();
      firstLinearUpdate();
      setIsFirst(false);
    }
    // eslint-disable-next-line
  }, [introClosed]);

  const animateCamera = (lookAtCoords, firstLook = false) => {
    if (firstLook) {
      firstAnimateCamera(lookAtCoords);
    } else {
      updateLookAt(lookAtCoords);
    }
  };

  return (
    <Cube360
      {...props}
      {...threeContext}
      animateCamera={animateCamera}
      animateCameraFov={animateCameraFov}
    />
  );
}

export default Cube;
