import React from 'react';

function IconZoomOut(props) {
  const color = props.color || '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 56.61"
      height="2rem"
    >
      <path
        opacity={0.5}
        d="M1004.94,511.84c-26.63,0-58,0-90,0V568.4h90Z"
        transform="translate(-914.94 -511.79)"
      />
      <path
        fill={color}
        d="M958.58,528.74a10.2,10.2,0,1,0,10.19,10.19A10.22,10.22,0,0,0,958.58,528.74Zm4.72,11.32a1.78,1.78,0,0,1-.65.11h-8.27a1.26,1.26,0,0,1-1.22-.87,1.24,1.24,0,0,1,1.26-1.61h8.35a1.25,1.25,0,0,1,1.25,1A1.21,1.21,0,0,1,963.3,540.06Z"
        transform="translate(-914.94 -511.79)"
      />
      <path
        fill={color}
        d="M917.76,511.79V568.4h87.18V511.84C979.1,511.81,948.81,511.79,917.76,511.79Zm57.86,43.26a1.21,1.21,0,0,1-1,.88,1.51,1.51,0,0,1-1.21-.43L966.89,549l-.26-.24c-.33.24-.63.48-1,.69a12.74,12.74,0,0,1-6.54,2.16,12.32,12.32,0,0,1-6.67-1.56,12.49,12.49,0,0,1-5.42-5.82,12.7,12.7,0,0,1,9.59-17.84c.56-.09,1.12-.12,1.68-.18h.49l.33,0c.65.08,1.31.11,2,.24a12.48,12.48,0,0,1,6.91,4,12.3,12.3,0,0,1,3.12,6.55,12.56,12.56,0,0,1-1.88,8.81c-.27.41-.56.8-.87,1.24l.26.2c2.11,2.11,4.23,4.22,6.33,6.34a6.14,6.14,0,0,1,.66.92Z"
        transform="translate(-914.94 -511.79)"
      />
    </svg>
  );
}

export default IconZoomOut;
