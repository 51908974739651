const MEDIA_TYPE = {
  IMAGE: 'Image',
  YOUTUBE: 'Youtube',
  AUDIO: 'Audio',
  GALLERY: 'Gallery',
  MATTERPORT: 'Matterport',
  MAPFLOORPLAN: 'MapFloorPlan',
  GOOGLE360: 'Google360',
  CUSTOM: 'Custom',
  TYPEFORM: 'TypeForm',
  LOGO_OVERRIDE: 'LogoOverride',
  POPOVER: 'Popover',
  SKYTAG: 'Skytag',
  CUSTOM_HTML: 'CustomHtml',
  SKETCH_FAB: 'SketchFab',
  VIMEO: 'Vimeo',
  VIDEO: 'Video',
  TOUR_AUDIO: 'TourAudio',
};

export default MEDIA_TYPE;
