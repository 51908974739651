import MEDIA_TYPES from './mediaType.const';

const TOUR_MEDIA = {
  AUDIO: 'Audio',
  MAP: 'Map',
  FORM: 'Form',
};

const TOUR_MEDIA_TYPES = {
  [MEDIA_TYPES.MAPFLOORPLAN]: TOUR_MEDIA.MAP,
  [MEDIA_TYPES.TYPEFORM]: TOUR_MEDIA.FORM,
  [MEDIA_TYPES.TOUR_AUDIO]: TOUR_MEDIA.AUDIO,
};

export default TOUR_MEDIA_TYPES;
