import React from 'react';

const Tip = () => (
  <svg
    data-name="Tip 2D"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 178"
  >
    <path
      fill="#fff"
      d="M822.58,609.38h2.64a5,5,0,0,0,3.94-1.57,6.27,6.27,0,0,0,1.38-4.29q0-6-4.64-6a4.2,4.2,0,0,0-3.49,1.61,6.71,6.71,0,0,0-1.26,4.24H817a9.48,9.48,0,0,1,2.5-6.75,9.17,9.17,0,0,1,12.77-.14q2.37,2.5,2.37,7.13a8.41,8.41,0,0,1-1.23,4.41,7.57,7.57,0,0,1-3.2,3q4.83,1.79,4.83,8a10.21,10.21,0,0,1-2.54,7.27,9.59,9.59,0,0,1-13.19.14,9.48,9.48,0,0,1-2.49-6.9H821a6.65,6.65,0,0,0,1.31,4.32,4.4,4.4,0,0,0,3.59,1.61,4.69,4.69,0,0,0,3.69-1.57,7.14,7.14,0,0,0,1.37-4.78q0-6.21-5.88-6.21h-2.48Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M855.39,594.41v3.68h-.68a8.39,8.39,0,0,0-6.49,2.8q-2.34,2.7-2.69,8.14a7.08,7.08,0,0,1,5.8-2.86,7,7,0,0,1,6,3.1,13.75,13.75,0,0,1,2.21,8.14c0,3.58-.81,6.4-2.45,8.48a7.9,7.9,0,0,1-6.56,3.11,7.76,7.76,0,0,1-6.68-3.67q-2.54-3.68-2.53-9.77V613.9a33.64,33.64,0,0,1,1.52-11.2,12.32,12.32,0,0,1,4.49-6.19,13.15,13.15,0,0,1,7.36-2.1Zm-4.76,15.37a4.59,4.59,0,0,0-3.11,1.28,7.25,7.25,0,0,0-2.06,3.28v1.71a13.69,13.69,0,0,0,1.46,6.76c1,1.74,2.17,2.61,3.6,2.61a4,4,0,0,0,3.62-2.14,10.7,10.7,0,0,0,1.32-5.66,10.81,10.81,0,0,0-1.32-5.65A4,4,0,0,0,850.63,609.78Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M882.86,614q0,7.54-2.2,11.28a8.28,8.28,0,0,1-13.73.15q-2.25-3.58-2.34-10.77v-5.86q0-7.47,2.21-11.15a8.37,8.37,0,0,1,13.73-.18q2.21,3.48,2.33,10.56Zm-4.15-5.92q0-5.42-1.19-8A4.09,4.09,0,0,0,870,600a19.06,19.06,0,0,0-1.24,7.6v7q0,5.39,1.24,8.09a4,4,0,0,0,3.8,2.71,3.92,3.92,0,0,0,3.72-2.56q1.2-2.55,1.24-7.79Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M888.62,600.06a6,6,0,0,1,1.74-4.27,5.66,5.66,0,0,1,4.23-1.82,5.53,5.53,0,0,1,4.16,1.81,6.05,6.05,0,0,1,1.72,4.28,5.87,5.87,0,0,1-1.72,4.24,5.62,5.62,0,0,1-4.16,1.75,6,6,0,0,1-6-6Zm6,3a3,3,0,0,0,2.13-.82,2.86,2.86,0,0,0,.87-2.16,3,3,0,0,0-.87-2.23,2.9,2.9,0,0,0-2.13-.87,2.87,2.87,0,0,0-2.17.92,3.06,3.06,0,0,0-.87,2.18,2.86,2.86,0,0,0,.87,2.12A3,3,0,0,0,894.59,603.05Z"
      transform="translate(-815.69 -451.31)"
    />
    <rect fill="#fff" opacity="0" width="288" height="178" />
    <path
      fill="#fff"
      d="M930,575.18c.44-.78.83-1.59,1.34-2.32a17.53,17.53,0,0,1,1.86-2.34q8.63-8.87,17.31-17.68a14.57,14.57,0,0,1,2.16-1.66,14.73,14.73,0,0,1,2.12-1.33,27,27,0,0,1,3.49-1.4,18.83,18.83,0,0,1,7.08-.75,17.54,17.54,0,0,1,5.39,1.23,31.05,31.05,0,0,1,3.19,1.52,12.07,12.07,0,0,1,2.71,2.23c.86.86,1.76,1.68,2.59,2.58a10.07,10.07,0,0,1,1.19,1.69,15.61,15.61,0,0,1,1.2,2.29,28.33,28.33,0,0,1,1.26,3.81,17.55,17.55,0,0,1,.08,7.8,27.81,27.81,0,0,1-1.17,3.84,18.9,18.9,0,0,1-1.25,2.56,9.7,9.7,0,0,1-1,1.47c-4.8,5.72-10.35,10.74-15.46,16.17-1,1-1.95,2.07-3,3a24,24,0,0,1-2.58,2,16.74,16.74,0,0,1-2.78,1.66,29.4,29.4,0,0,1-4.09,1.39,18.25,18.25,0,0,1-6.21.39,17.8,17.8,0,0,1-5.33-1.31,33.08,33.08,0,0,1-3.42-1.83A15.52,15.52,0,0,1,932.7,597a21,21,0,0,1-1.3-1.79c-.25-.36-.47-.73-.71-1.09a17.9,17.9,0,0,1-2.11-4.8,19.43,19.43,0,0,1-.64-7.35,19.08,19.08,0,0,1,1.26-5.21c.2-.54.47-1.06.71-1.58Zm47.83-1.08.09,0a6.25,6.25,0,0,1,.24-.6,14.56,14.56,0,0,0,1.3-5A15.47,15.47,0,0,0,976,557.29a15,15,0,0,0-4.74-4,15.33,15.33,0,0,0-4-1.48,15.63,15.63,0,0,0-10.93,1.5,14.64,14.64,0,0,0-3.6,2.67q-8.31,8.54-16.66,17a15.89,15.89,0,0,0-2.74,3.82,15.73,15.73,0,0,0-1.22,10.93,12.43,12.43,0,0,0,1.41,3.56,19.74,19.74,0,0,0,1.65,2.54,16.11,16.11,0,0,0,5.79,4.4,15.57,15.57,0,0,0,5.1,1.29,15.36,15.36,0,0,0,8.73-1.84,13.47,13.47,0,0,0,3.22-2.32l3.17-3.24,14.25-14.58a8.23,8.23,0,0,0,1-1.2C976.93,575.67,977.35,574.87,977.78,574.1Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M905.48,485.35c.49,1.78,1,3.55,1.48,5.33.08.29.18.58.24.88a1.71,1.71,0,0,1-1.18,2,1.67,1.67,0,0,1-2.17-1.07c-.25-.74-.44-1.5-.65-2.26L901,482.35a1.74,1.74,0,0,1,1.39-2.47l10.11-2.81a1.77,1.77,0,0,1,2.09,1.12,1.73,1.73,0,0,1-1.13,2.23c-1.06.32-2.13.6-3.19.89l-3.13.86.53.3,47.45,26.83L986.34,527l.63.39a1.69,1.69,0,0,1,.54,2.33,1.74,1.74,0,0,1-2.35.62L970.52,522,906,485.51l-.41-.23Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M1011.13,545l-.52-.3-11-6.24a1.74,1.74,0,0,1,1.16-3.23,3.86,3.86,0,0,1,1,.43l10.62,6,.44.25.18-.1a1.92,1.92,0,0,1-.23-.42q-.8-2.82-1.58-5.66a1.73,1.73,0,0,1,2-2.19,1.64,1.64,0,0,1,1.31,1.2l2.81,10.16a1.76,1.76,0,0,1-1.34,2.39L1006,550a1.77,1.77,0,0,1-2.27-.9,1.69,1.69,0,0,1,1.1-2.38c1.9-.54,3.81-1.06,5.71-1.59Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M968.92,565.36l-.33.38-6.46,6.6a2.12,2.12,0,0,1-2.68.44,2,2,0,0,1-.7-2.22,4.42,4.42,0,0,1,.74-1.1q3.12-3.23,6.26-6.42a2.11,2.11,0,0,1,2.72-.45,2,2,0,0,1,.7,2.19C969.1,565,969,565.13,968.92,565.36Z"
      transform="translate(-815.69 -451.31)"
    />
  </svg>
);

export default Tip;
