import React from 'react';

function IconLink(props) {
  const color = props.color || '#fff';
  return (
    <svg
      className="sn-icon sn-icon-link"
      data-name="Icon Link"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50.47 43.96"
    >
      <path
        fill={color}
        d="M985.19,550.74c-.15-.84-.25-1.69-.45-2.51a12.29,12.29,0,0,0-6.53-8.1c-.6-.33-1.22-.63-1.86-1a4.56,4.56,0,0,1,.31-.42,13.51,13.51,0,0,0-.52-16.53,9.2,9.2,0,0,0-14.72.54c-.54.72-1,1.51-1.5,2.31l-.25-.47a10.22,10.22,0,0,0-6.08-5.36A8.84,8.84,0,0,0,944,522a13.4,13.4,0,0,0-.72,16.7l.32.42c-.92.51-1.85,1-2.73,1.51a12.27,12.27,0,0,0-6.08,10.67c0,1.26,0,2.53,0,3.84h9.66c-.8,2.51-.6,5-.62,7.58h32.36c0-2.53.19-5.06-.61-7.56h9.67ZM945.59,552H938s-.06,0-.14-.06a9.58,9.58,0,0,1,2.84-7.32,14.26,14.26,0,0,1,3.54-2.3c1-.48,2-1,2.93-1.49a1.79,1.79,0,0,0,1-1.63.49.49,0,0,0-.14-.36,8.56,8.56,0,0,1-4.11-6.51,9.89,9.89,0,0,1,1.82-7.64c2.84-3.78,7.71-3.68,10.43.19.4.57.71,1.21,1.09,1.86-.52.23-.95.39-1.35.61a11.18,11.18,0,0,0-5.8,7.69,13.17,13.17,0,0,0,2.37,11.54,1.22,1.22,0,0,1,.09.17l-1.08.55a14.44,14.44,0,0,0-5.52,4.49A.57.57,0,0,1,945.59,552Zm1.34,7.58a10.48,10.48,0,0,1,1.12-5.14,9.6,9.6,0,0,1,4.28-4c1-.52,2-1,3-1.49.4-.2.76-.46,1.14-.68a1.75,1.75,0,0,0,.78-1.55.4.4,0,0,0-.18-.29,8.51,8.51,0,0,1-4-6.33,9.87,9.87,0,0,1,1.88-7.91c2.78-3.58,7.33-3.55,10.14,0a10.37,10.37,0,0,1-.25,12.61,11.31,11.31,0,0,1-1.74,1.49.64.64,0,0,0-.3.63,2,2,0,0,0,1.27,1.72c1.26.64,2.54,1.24,3.78,1.9,3.59,1.92,5.22,5,5.18,9ZM981.46,552h-7a.72.72,0,0,1-.54-.21,15,15,0,0,0-6-4.71l-.66-.32a13.29,13.29,0,0,0,2.59-11.2,11.3,11.3,0,0,0-7.22-8.8c1-2.3,2.5-4.09,5-4.68a5.88,5.88,0,0,1,6.07,2.16,9.61,9.61,0,0,1,2.29,7.17,9.32,9.32,0,0,1-2.79,6.38,7.8,7.8,0,0,1-1.22.92c-.26.16-.27.34-.26.6a1.93,1.93,0,0,0,1.26,1.72c1.26.64,2.54,1.24,3.78,1.91a8.89,8.89,0,0,1,4.83,6c.23,1,.31,2,.47,3.11Z"
        transform="translate(-934.71 -518.78)"
      />
    </svg>
  );
}

export default IconLink;
