import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import MEDIA_TYPES from '../../consts/mediaType.const';
import AmbientAudio from './ambient';

const Audio = (props) => {
  const {
    available,
    enabled,
    scene: { hotspots, defaultVariantIndex, variants },
    variantIndex,
  } = props;

  const [audioUrl, loop] = useMemo(() => {
    if (
      available &&
      enabled &&
      hotspots &&
      hotspots.constructor === Array &&
      variants &&
      variants[variantIndex]
    ) {
      const audioMediaHs = hotspots.filter(
        (h) => h.media && h.media.type === MEDIA_TYPES.AUDIO
      );
      const defaultAudioHotspot = audioMediaHs.find((h) => !h.sceneVariantId);
      if (
        defaultVariantIndex === variantIndex ||
        variants[variantIndex].useDefaultMediaSpot
      ) {
        if (defaultAudioHotspot) {
          const { soundUrl, loop } = defaultAudioHotspot.media;
          return [soundUrl || '', loop || false];
        }
      }
      const variantAudioHotspot = audioMediaHs.find(
        (h) => h.sceneVariantId === variants[variantIndex].id
      );
      if (variantAudioHotspot) {
        const { soundUrl, loop } = variantAudioHotspot.media;
        return [soundUrl || '', loop || false];
      }
    }
    return ['', false];
  }, [
    available,
    enabled,
    hotspots,
    variants,
    defaultVariantIndex,
    variantIndex,
  ]);
  return <AmbientAudio src={audioUrl} loop={loop} />;
};

const mapStateToProps = ({ audio: { enabled, available } }) => ({
  enabled,
  available,
});

export default connect(mapStateToProps)(Audio);
