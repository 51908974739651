import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addHotspotHistory } from 'store/actions';
import configs from 'configs';

export function useAddHotspotHistory() {
  const dispatch = useDispatch();
  const { media } = useSelector((state) => state.json);

  return useCallback(
    (hsId) => {
      if ([configs.mapRoute, configs.socialRoute].includes(hsId)) return;
      const mediaHotspot = media.find((m) => m.id === hsId);
      if (mediaHotspot) dispatch(addHotspotHistory(mediaHotspot._id));
    },
    [dispatch, media]
  );
}
