import * as APP_ACTION from './actionTypes';

export const initiate = () => ({ type: APP_ACTION.INITIATE });
export const updateDimentions = (payload) => ({
  type: APP_ACTION.UPDATE_DIMENTIONS,
  payload,
});
export const togglePanoMode = (mode) => ({
  type: APP_ACTION.TOGGLE_PANO_MODE,
  payload: mode,
});
export const toggleMenu = (isOpen) => ({
  type: APP_ACTION.TOGGLE_MENU,
  payload: isOpen,
});
export const setMenuPosition = (payload) => ({
  type: APP_ACTION.SET_MENU_POSITION,
  payload,
});
export const setTourData = (payload) => ({
  type: APP_ACTION.SET_TOUR_DATA,
  payload,
});
export const setPanoData = (payload) => ({
  type: APP_ACTION.SET_PANO_DATA,
  payload,
});
export const showHotspot = (hotspot) => ({
  type: APP_ACTION.SHOW_HOTSPOT,
  payload: { hotspot },
});
export const closeHotspotPopup = () => ({
  type: APP_ACTION.CLOSE_HOTSPOT_POPUP,
});
export const goToScene = (groupId, id) => ({
  type: APP_ACTION.GO_TO_PANO,
  payload: { id, groupId },
});

export const closeLanding = () => ({ type: APP_ACTION.CLOSE_LANDING });
export const setAerialMapData = (payload) => ({
  type: APP_ACTION.SET_AERIAL_MAP_DATA,
  payload,
});

export const setAudioAvailable = (payload) => ({
  type: APP_ACTION.SET_AUDIO_AVAILABLE,
  payload,
});
export const toggleAudio = (isOn) => ({
  type: APP_ACTION.TOGGLE_AUDIO,
  payload: isOn,
});
export const goToVariants = (payload) => ({
  type: APP_ACTION.GO_TO_VARIANTS,
  payload,
});
export const setControlMode = (payload) => ({
  type: APP_ACTION.SET_CONTROL_MODE,
  payload,
});
export const toggleLanding = (payload) => ({
  type: APP_ACTION.TOGGLE_LANDING,
  payload,
});
export const addHotspotHistory = (payload) => ({
  type: APP_ACTION.ADD_HOTSPOT_HISTORY,
  payload,
});
export const setCenterLookat = (payload) => ({
  type: APP_ACTION.SET_CENTER_LOOKAT,
  payload,
});

export const setResolutions = (payload) => ({
  type: APP_ACTION.SET_RESOLUTIONS,
  payload,
});

export const setSDLoaded = (payload) => ({
  type: APP_ACTION.SET_SD_LOADED,
  payload,
});
export const setHDLoaded = (payload) => ({
  type: APP_ACTION.SET_HD_LOADED,
  payload,
});
