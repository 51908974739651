import React from 'react';

const PoweredBySvg = () => (
  <svg
    data-name="SKYNAV Logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 217.33 30.48"
  >
    <path
      fill="#fff"
      d="M935.37,526.06h7.81a.49.49,0,0,1,.5.29c2.84,4.94,5.69,9.88,8.51,14.83.74,1.3,1.37,2.65,2,4,0,.1.11.19.14.24V526.07h6.37v28.18H953a.48.48,0,0,1-.5-.29c-2.82-4.92-5.67-9.84-8.47-14.77-.75-1.31-1.39-2.67-2.07-4-.06-.11-.11-.21-.15-.27q.06,9.62.12,19.32h-6.51Z"
      transform="translate(-851.03 -525.07)"
    />
    <path
      fill="#fff"
      d="M890.25,542.14c-1,1.2-1.92,2.38-2.91,3.51a2,2,0,0,0-.53,1.49c0,2.21,0,4.42,0,6.62v.46h-7.33V526.08h7.33v12.46l.27-.3,9.5-11.79a.9.9,0,0,1,.82-.4h7.65l-.26.32q-4.51,5.4-9,10.79a.48.48,0,0,0-.06.69l9.63,16,.22.39-.39,0h-7.55a.57.57,0,0,1-.56-.31q-3.29-5.73-6.6-11.43Z"
      transform="translate(-851.03 -525.07)"
    />
    <path
      fill="#fff"
      d="M851,549.7l3.76-4.61c.21.19.4.39.6.56a13.39,13.39,0,0,0,10.53,3.21,5.37,5.37,0,0,0,1.66-.53,2.19,2.19,0,0,0,0-3.84,11.55,11.55,0,0,0-3-.91c-2.13-.52-4.28-1-6.39-1.59a11.52,11.52,0,0,1-2.71-1.21,6.47,6.47,0,0,1-3.26-6.34c.18-3.44,2-5.76,5-7.3a13.56,13.56,0,0,1,5.1-1.39,20.4,20.4,0,0,1,7.42.58,14.68,14.68,0,0,1,6.47,3.77l.28.28-3.69,4.52c-.53-.44-1-.89-1.53-1.28a11.17,11.17,0,0,0-9-2.12,4.09,4.09,0,0,0-1.53.62,2,2,0,0,0,.09,3.5,9.38,9.38,0,0,0,2.78,1c2.09.5,4.19.95,6.26,1.51a10.66,10.66,0,0,1,3.93,1.95,6.27,6.27,0,0,1,2.4,4.65,9.13,9.13,0,0,1-.38,3.56,8.34,8.34,0,0,1-4.76,5.1,15,15,0,0,1-5.6,1.26,21.36,21.36,0,0,1-9.91-1.75A14.53,14.53,0,0,1,851,549.7Z"
      transform="translate(-851.03 -525.07)"
    />
    <path
      fill="#fff"
      d="M987.42,525.66h7.39c.23,0,.34.05.41.28q3.22,10.92,6.48,21.83c0,.12.07.23.13.43a2,2,0,0,0,.13-.33c1.79-6.81,4-13.49,6-20.23.18-.58.36-1.16.53-1.75a.27.27,0,0,1,.31-.23h7.07l.16,0-.12.36q-4.87,13.73-9.74,27.47a.47.47,0,0,1-.53.37H997.8c-.25,0-.38,0-.47-.31q-4.91-13.8-9.82-27.59C987.48,525.9,987.46,525.8,987.42,525.66Z"
      transform="translate(-851.03 -525.07)"
    />
    <path
      fill="#fff"
      d="M932.87,526.05l-.19.37q-4.77,8.58-9.54,17.17a1.83,1.83,0,0,0-.22.82c0,3.12,0,6.23,0,9.34v.47h-7.35v-.4q0-4.77,0-9.54a1.27,1.27,0,0,0-.17-.63q-4.75-8.58-9.52-17.14c-.08-.13-.15-.27-.24-.44l.34,0h6.93a.45.45,0,0,1,.47.28c1.24,2.44,2.52,4.86,3.73,7.31.72,1.43,1.33,2.92,2,4.39.06.12.12.24.2.42l.16-.28c1.72-4,3.8-7.88,5.78-11.78a.55.55,0,0,1,.57-.35c2.23,0,4.46,0,6.69,0Z"
      transform="translate(-851.03 -525.07)"
    />
    <circle fill="#ff362c" cx="180.51" cy="15.24" r="15.24" />
    <circle fill="#99dcd6" cx="202.09" cy="15.24" r="15.24" />
    <path
      fill="#992f25"
      d="M1037.87,540.31a15.18,15.18,0,0,0,4.46,10.76,15.25,15.25,0,0,0,0-21.53A15.21,15.21,0,0,0,1037.87,540.31Z"
      transform="translate(-851.03 -525.07)"
    />
    <path
      fill="#fff"
      d="M991.82,553.93l-9.64-27.54a.41.41,0,0,0-.47-.34h-8.12a.4.4,0,0,0-.46.32q-4.74,13.53-9.5,27c-.1.26-.19.52-.29.83h7c.28,0,.41-.06.5-.35.57-1.89,1.17-3.77,1.75-5.67a.42.42,0,0,1,.49-.36h9c.28,0,.41.07.49.36.56,1.88,1.15,3.76,1.71,5.64a.44.44,0,0,0,.5.39c2.26,0,4.51,0,6.77,0h.39C991.88,554.12,991.85,554,991.82,553.93Zm-17.51-11q1.63-5.58,3.28-11.15,1.63,5.55,3.29,11.15Z"
      transform="translate(-851.03 -525.07)"
    />
  </svg>
);

export default PoweredBySvg;
