import React from 'react';

const MenuIcon = (props) => {
  const color = props.color || 'white';
  return (
    <svg
      data-name="Menu Icon Aerial"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76.95 76.95"
      width="100%"
    >
      <path
        d="M959.69,501.83a38.48,38.48,0,1,0,38.48,38.48A38.52,38.52,0,0,0,959.69,501.83Zm0,73.95a35.48,35.48,0,1,1,35.48-35.47A35.51,35.51,0,0,1,959.69,575.78Z"
        transform="translate(-921.22 -501.83)"
        fill={color}
      />
      <path
        d="M990.69,539.43l-8.1-1.67.15-.76a1.63,1.63,0,1,0-3.2-.67l-.16.77-8.1-1.67a1.64,1.64,0,0,0-1.93,1.27,1.62,1.62,0,0,0,1.27,1.93l8.1,1.68-.84,4.06-36.41-7.52.84-4.06,8.1,1.67a1.63,1.63,0,0,0,.66-3.2l-8.1-1.68.16-.76a1.64,1.64,0,1,0-3.21-.66l-.15.76-8.1-1.67a1.64,1.64,0,1,0-.66,3.21l8.09,1.67-1.17,5.66a1.65,1.65,0,0,0,1.28,1.94l9.74,2a1.61,1.61,0,0,0-.1,1c0,.2.12.39.18.59H949a7.42,7.42,0,0,0-5.5,5.86,1.64,1.64,0,0,0,1.28,1.9h0a1.63,1.63,0,0,0,1.9-1.32,4.14,4.14,0,0,1,3.07-3.27,1.87,1.87,0,0,0,.57-.27,11,11,0,0,0,15.7,3.24,1.53,1.53,0,0,0,.41.47,4.15,4.15,0,0,1,1.53,4.22,1.62,1.62,0,0,0,1.22,2h0a1.64,1.64,0,0,0,1.93-1.23,7.44,7.44,0,0,0-2.74-7.57l0,0c.13-.16.27-.31.39-.48a1.62,1.62,0,0,0,.31-1l9.74,2a1.63,1.63,0,0,0,1.94-1.27l1.16-5.66,8.1,1.67a1.64,1.64,0,1,0,.67-3.21ZM958.14,548a7.88,7.88,0,0,1-6-5.64l13.71,2.83A7.89,7.89,0,0,1,958.14,548Z"
        transform="translate(-921.22 -501.83)"
        fill={color}
      />
    </svg>
  );
};

export default MenuIcon;
