import React, { useEffect, useRef } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { connect } from 'react-redux';

import { useNavigateRoute } from 'common/hooks';
import useTogglePopup from 'common/hooks/useTogglePopup';
import IconClose from 'containers/world/mediaPopup/iconClose';
import { formatPopupStyle } from 'utils/cssHelper';
import { isMenuLeft } from 'utils/menuHelper';

import './style.scss';

function Popup(props) {
  const popupBody = useRef(null);
  const [handleUpdateRoute] = useNavigateRoute();
  const {
    closePopup,
    dimentions,
    hideCloseBtn,
    containerStyle,
    color1,
    color2,
    color3,
  } = props;
  const [mounted] = useTogglePopup(closePopup);
  useEffect(() => {
    const popupBodyEl = popupBody && popupBody.current;
    if (popupBodyEl) disableBodyScroll(popupBodyEl);
    return () => popupBodyEl && enableBodyScroll(popupBodyEl);
  }, []);

  // lightboxHeaderColor
  return (
    <div
      className={`popup-container Hotspot-transition ${
        mounted && 'Hotspot-transition__blur'
      }`}
      style={containerStyle || formatPopupStyle(color1, color2, color3)}
    >
      {!hideCloseBtn && (
        <div className="popup-header">
          <span className="Close-button" onClick={() => handleUpdateRoute()}>
            <IconClose />
          </span>
        </div>
      )}
      <div
        ref={popupBody}
        className={`popup-body ${
          isMenuLeft(dimentions) ? 'Menu-left' : 'Menu-bottom'
        }`}
      >
        <div className="popup-body-content">{props.children}</div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ dimentions, tour }) => ({
  dimentions,
  color1: (tour && tour.lightboxBackgroundColor1) || '',
  color2: (tour && tour.lightboxBackgroundColor2) || '',
  color3: (tour && tour.lightboxBackgroundColor3) || '',
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
