import TOUR_MEDIA_TYPES from 'consts/tourMediaType.const';
import MEDIA_TYPES from 'consts/mediaType.const';

const emptyFeaturedMedia = {
  map: null,
  contact: null,
  form: null,
};

/**
 * Get feature media from list media
 * @param {String} mediaId Feature Media Id
 * @param {Array} media List of Media
 * @returns {Object}
 *
 * ```js
 * const contact = getFeaturedMedia(contactId, json.media)
 * ```
 */
const getFeaturedMedia = (mediaId, media) =>
  media.find((m) => m.id === mediaId);

/**
 * Get Featured Media by format
 * @param {Object} media featured media
 * @returns {Object} featuredMediaHotspotItem
 *
 * ```js
 * const mapFeaturedHotspot = getFeaturedHotspotItem(media)
 * ```
 */
const getFeaturedHotspotItem = (media) => ({
  id: media.friendlyName,
  _id: media.id,
  media,
});

/**
 * Handle update featuredMedia with Array data type
 * @param {Object} data Tour json data
 * @return {void} void
 *
 * ```js
 * data.featured = [{
 * mediaId: "611e2761ebcfd24d1ca262d0"
 * others: null
 * tourId: "607672c81f63cd04d823597f"
 * type: "Map"
 * }]
 * ```
 */
export const handleUpdateArrayFeaturedMedia = (data) => {
  if (data.featured.length === 0) {
    data.featuredMedia = { ...emptyFeaturedMedia };
    return;
  }
  data.featured.forEach((media) => {
    const featuredMedia = getFeaturedMedia(media.mediaId, data.media);
    if (featuredMedia) {
      data.featuredHotspots.push(getFeaturedHotspotItem(featuredMedia));
      const mediaType = TOUR_MEDIA_TYPES[featuredMedia?.type]
        ? TOUR_MEDIA_TYPES[featuredMedia?.type].toLowerCase()
        : TOUR_MEDIA_TYPES[MEDIA_TYPES.MAPFLOORPLAN].toLowerCase();

      if (mediaType) {
        data.featuredMedia[mediaType] = featuredMedia;
        // HARDCODE
        if (mediaType === 'form') data.featuredMedia.contact = featuredMedia;
      }
    }
  });
};

/**
 * Handle update featuredMedia with Object data type
 * @param {Object} data Tour json data
 * @return {void}
 */
export const handleUpdateObjectFeaturedMedia = (data) => {
  const mapId = data.featured.mediaId || '';
  const map = mapId ? getFeaturedMedia(mapId, data.media) || null : null;

  const contactId = (data.featured.others && data.featured.others[0]) || '';
  const contact = contactId
    ? getFeaturedMedia(contactId, data.media) || null
    : null;

  data.featuredMedia.map = map;
  data.featuredMedia.contact = contact;

  if (map) {
    data.featuredHotspots.push(getFeaturedHotspotItem(map));
  }
  if (contact) {
    data.featuredHotspots.push(getFeaturedHotspotItem(contact));
  }
};
