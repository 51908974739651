import React from 'react';

function IconRotate(props) {
  const color = props.color || '#fff';
  return (
    <svg
      className="sn-icon sn-icon-rotate"
      data-name="Icon Rotate"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.34 45.35"
    >
      <path
        fill={color}
        d="M978.82,538.82v.26h3.5a9.67,9.67,0,0,0-.26-1.66l-.17-.88c0-.18-.08-.36-.12-.55l-.09-.44a21.82,21.82,0,0,0-5.16-9.83l-.57-.61A23.6,23.6,0,0,0,950.9,519a26.27,26.27,0,0,0-8.74,5.34L940.31,526,937,522.33v10.14h.16l10,0-4-3.68,1.83-1.85a20.14,20.14,0,0,1,16.37-5.47,20.93,20.93,0,0,1,10.77,5l1.36,1.22,0,.1C977.6,532.13,978.5,534.84,978.82,538.82Z"
        transform="translate(-937 -517.66)"
      />
      <path
        fill={color}
        d="M937.44,544.12l.12.56.09.43a21.78,21.78,0,0,0,5.17,9.84l.56.61a23.63,23.63,0,0,0,25.06,6.09h0a26.34,26.34,0,0,0,8.74-5.35l1.85-1.65,3.31,3.69,0-10v-.2H972.18l4.05,3.68-1.84,1.85A20.21,20.21,0,0,1,958,559.18a21,21,0,0,1-10.77-5l-1.36-1.23,0-.09c-4.2-4.32-5.1-7-5.41-11,0-.07,0-.17,0-.27H937a9.83,9.83,0,0,0,.26,1.66Z"
        transform="translate(-937 -517.66)"
      />
    </svg>
  );
}

export default IconRotate;
