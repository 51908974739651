import React from 'react';
import { connect } from 'react-redux';
import './style.scss';
import IconAerial from '../../../../components/icons/SceneIconAerial';
import IconGround from '../../../../components/icons/SceneIconGround';

function NavMenuItem(props) {
  const { item, index, active, onSelect, viewed } = props;
  const onClickMenuItem = () => onSelect && onSelect();
  return (
    <div
      className={`Pan-Menu-item available ${active ? 'active' : ''}`}
      onClickCapture={onClickMenuItem}
    >
      <span
        className={`Pan-Menu-item-check ${viewed ? 'checked' : ''}`}
        style={{ color: 'white' }}
      >
        &nbsp;
      </span>
      <span className={`Pan-Menu-item-type`}>
        {item.type === 'AERIAL' ? <IconAerial /> : <IconGround />}
      </span>
      <span className="Pan-Menu-item-title font-roboto">{`${index} . ${item.title}`}</span>
    </div>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(NavMenuItem);
