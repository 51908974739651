import React, { useState, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';

import { isMenuLeft } from 'utils/menuHelper';
import { isPhone } from 'deviceDetect';

import IntroSlider from './introSlider';
import BtnGetStarted from './btnGetStarted';
import IntroImage from './introImage';

import './intro.scss';

const PHONE = isPhone();

export const INTRO_STEP = {
  RENDER: 0,
  FADING_IN: 1,
  FADED_IN: 2,
  FADING_OUT: 3,
  FADED_OUT: 4,
};

const playAudio = (audioDomId) => {
  const playPromise = document.getElementById(audioDomId).play();
  if (playPromise !== undefined) {
    playPromise
      .catch((err) => {
        console.log(err);
        // Auto-play was prevented
        // Show a UI element to let the user manually start playback
      })
      .then(() => {
        // Auto-play started
      });
  }
};

const playAudioFirstTime = () => {
  if (!window.globalAudioPlayed || !window.tourAudioPlayed) {
    if (window.haveTourAudioSrc) {
      playAudio('tourAudio');
    } else {
      playAudio('globalAudio');
    }
    if (window.haveAudioSrc) {
      playAudio('sceneAudio');
    }
  }
};

const Intro = (props) => {
  const { spinnerVisible, dimentions, onUpdate } = props;
  const portrait = window.innerWidth < window.innerHeight;
  const [step, setStep] = useState(INTRO_STEP.RENDER);
  const [status, setStatus] = useState('out');
  const [enabled, setEnabled] = useState(true);
  useEffect(() => {
    if (step === INTRO_STEP.FADING_IN) {
      setStatus('in');
      setTimeout(() => setStep(INTRO_STEP.FADED_IN), 1000);
    }
    if (step === INTRO_STEP.FADING_OUT) {
      setStatus('out');
      setTimeout(() => setStep(INTRO_STEP.FADED_OUT), 1000);
    }
    onUpdate(step);
    // eslint-disable-next-line
  }, [step]);
  useEffect(() => {
    if (!spinnerVisible && step === INTRO_STEP.RENDER) {
      setStep(INTRO_STEP.FADING_IN);
    }
  }, [step, spinnerVisible]);
  const onHide = useCallback(() => {
    setEnabled(false);
    setStep(INTRO_STEP.FADING_OUT);
  }, []);

  const onClickHide = useCallback(() => {
    if (enabled) {
      playAudioFirstTime();
      onHide();
    }
  }, [enabled, onHide]);

  return (
    <>
      <div className="landing-intro-back">&nbsp;</div>
      <div
        className={`landing-intro ${status} menu-${
          isMenuLeft(dimentions) ? 'left' : 'bottom'
        } ${PHONE ? 'phone' : 'desktop'} ${
          portrait ? 'portrait' : 'landscape'
        }`}
      >
        <div className="intro-title" onClick={onClickHide}>
          <div className="intro-img-wp">
            <IntroImage />
          </div>
          <div className="intro-btn" onClick={onClickHide}>
            <BtnGetStarted />
          </div>
        </div>
        <div className="intro-slide">
          <IntroSlider
            onClick={onClickHide}
            phone={PHONE}
            portrait={portrait}
            image={props.image}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dimentions }) => ({
  dimentions,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
