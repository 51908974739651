/* eslint-disable react/no-direct-mutation-state */
import React from 'react';

import * as THREE from 'three';
import { connect } from 'react-redux';

import configs from 'configs';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';

import { setSDLoaded, setHDLoaded } from 'store/actions';
import {
  cleanLoadTexture,
  cleanLoadTextures,
  loadCanvasTexture,
} from 'utils/textureHelper';
import CubeEffect from './cubeEffect';
import { log } from 'utils/global.utils';
import { /*FRAGS, */ SIZES } from 'consts/resolutions.const';
import { getImgForSide } from 'utils/imageHelper';
import { isPhone } from 'deviceDetect';

const isMobilePhone = Boolean(isPhone());

const cubeMaterials = {
  px: null,
  nx: null,
  py: null,
  ny: null,
  pz: null,
  nz: null,
};

const materials = {
  material0: { ...cubeMaterials },
  material1: { ...cubeMaterials },
};

class Cube360 extends React.Component {
  queue = [];
  toClean = [];
  processQueue = false;
  maxAnisotropy = 1;

  constructor(props) {
    super(props);
    this.state = {
      firstLook: true,
      material0: { ...cubeMaterials },
      material1: { ...cubeMaterials },
      mixFactor: 1,
      curMixFactor: 0,
    };
  }

  componentDidMount() {
    this.props.gl.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    this.maxAnisotropy = this.props.gl.capabilities.getMaxAnisotropy();
    if (this.props.variantIndex === 0) {
      this.loadImages(this.state.mixFactor);
    } else {
      this.loadVariantImages(this.state.mixFactor, this.props.variantIndex);
    }
    log('window.devicePixelRatio', window.devicePixelRatio);
    log('this.maxAnisotropy', this.maxAnisotropy);
  }

  componentDidUpdate(preProps) {
    const mixFactor = this.state.mixFactor ? 0 : 1;
    if (preProps.pano && this.props.pano) { // check if this is the case where 
      if (preProps.pano.id !== this.props.pano.id) {
        this.setState({ mixFactor });
        this.loadImages(mixFactor);
      } else if (preProps.variantIndex !== this.props.variantIndex) {
        this.setState({ mixFactor });
        this.loadVariantImages(mixFactor);
      }
      // else if (preProps.resolution !== this.props.resolution && !isMobilePhone) {
      //   const { variantIndex, pano } = this.props;
      //   const { id, variants } = pano;
      //   const textureData = variants[variantIndex].cubeMapImages;
      //   this.loadHiResTexture({
      //     panoId: id,
      //     mixFactor,
      //     vaIndex: variantIndex,
      //     textureData,
      //   });
      // }
    }
  }

  loadImages = (mixFactor) => {
    // stop all-loading
    this.processQueue = false;
    const { variantIndex, pano } = this.props;
    const { id, variants, defaultOrientation } = pano;
    const { cubeMapImages } = variants[variantIndex];
    // load low & render low
    this.loadImageAsTextures(
      cubeMapImages.size1024,
      id,
      variantIndex,
      mixFactor,
      true
    )
      .then(() => this.props.setSDLoaded(true))
      // show orientation
      .then(() => log('Set orientation'))
      .then(() =>
        this.props.animateCamera([...defaultOrientation], this.state.firstLook)
      )
      .then(() => this.props.animateCameraFov(this.state.firstLook))
      .then(() => log('Finish set orientation'))
      .then(() => this.setState({ firstLook: false }))
      .then(this.cleanHdCache)
      .then(() => window.waitASecond(0.5))
      // show hotspots
      .then(() => log('Showing hotspots'))
      .then(this.props.onShowHotspots)
      .then(() => log('Finished showing hotspots'))
      .then(() => window.waitASecond(0.5))
      .then(() => this.handlePreload(id, true))
      .then(() => window.waitASecond(1.5))
      .then(() => log('load & render high'))
      .then(() =>
        this.loadHiResTexture({
          panoId: id,
          vaIndex: variantIndex,
          mixFactor,
          textureData: cubeMapImages,
        })
      )
      .then(() => this.props.setHDLoaded(true))
      .then(() => window.waitASecond(1))
      // start loading
      // load linked low
      .then(() => this.handlePreload(id, false));
    // GA Tracking
    trackEventByType(EVENT_TYPE.SCENE, id);
  };

  loadVariantImages = (mixFactor) => {
    // stop all-loading
    this.processQueue = false;
    const { variantIndex, pano } = this.props;
    const { id, variants } = pano;
    const currentVariant = variants[variantIndex];
    log('CURRENT VARIANTS', currentVariant);
    const { cubeMapImages } = currentVariant;
    // load low & render low
    this.loadImageAsTextures(
      cubeMapImages.size1024,
      id,
      variantIndex,
      mixFactor,
      true
    )
      // show hotspots
      .then(() => log('Showing hotspots'))
      .then(this.props.onShowHotspots)
      .then(() => window.waitASecond(0.5))
      .then(() => this.handlePreload(id, true))
      .then(() => window.waitASecond(1.5))
      .then(() => log('load & render high'))
      .then(() =>
        this.loadHiResTexture({
          panoId: id,
          vaIndex: variantIndex,
          mixFactor,
          textureData: cubeMapImages,
        })
      )
      .then(() => window.waitASecond(0.5))
      .then(() => this.handlePreload(id, false));
  };

  handlePreload = (curSceneId, linkedOnly = true) => {
    return new Promise(resolve => {
      if (!configs.preload) return resolve(false);
      if (curSceneId !== this.props.pano.id) {
        return resolve(false);
      }
      if (linkedOnly) {
        log(`Start load linked images`);
        this.loadLinkedImages(() => {
          log('Finish load linked images');
          return resolve(true);
        });
      } else {
        log(`Start load non-linked images`);
        this.loadNonLinkedImages(() => {
          log('Finish load non-linked images');
          return resolve(true);
        });
      }
    });
  }

  loadLinkedImages = (callback) => {
    const { id, hotspots } = this.props.pano;
    const linkedSceneIds = (hotspots || [])
      .filter((h) => h.sceneLinkId)
      .map((h) => h.scene.id);
    const panoList = this.props.scenes.filter((p) => p.id !== id);
    const linkedScenes = panoList.filter(
      (p) => linkedSceneIds.indexOf(p.id) !== -1
    );

    this.queue = [...linkedScenes, this.props.pano];
    this.processQueue = true;
    this.loadQueue(callback);
  };

  loadNonLinkedImages = (callback) => {
    const { id, hotspots } = this.props.pano;
    const linkedSceneIds = (hotspots || [])
      .filter((h) => h.sceneLinkId)
      .map((h) => h.sceneLinkId);

    const menuSceneIds = this.props.json.menu.reduce((agg, group) => {
      const groupScenes = ((group && group.scenes) || []);
      return [...agg, ...groupScenes.map(s => s.id)]
    }, []);

    // filter scenes that're in menu and not current scene, not is hotspot of current scene.
    const otherScenes = this.props.scenes.filter((p) => (
      p.id !== id && linkedSceneIds.indexOf(p.id) === -1 && menuSceneIds.indexOf(p.id) !== -1
    ));
    this.queue = [...otherScenes, ...this.queue];
    this.processQueue = true;
    this.loadQueue(callback);
  };

  loadQueue = (callback) => {
    if (this.processQueue) {
      if (this.queue.length) {
        log('Preloading textures queue: ', [...this.queue]);
        const currQueueScene = this.queue.pop();
        let imagesToLoad = [];
        currQueueScene.variants.forEach((v, index) => {
          const IdIndex = `${currQueueScene.id}-${index}`;
          if (IdIndex === this.getCurrentIdIndex()) {
            // skip current scene variants (already loaded)
            return;
          }
          const size1024 = v.cubeMapImages.size1024 || [];
          imagesToLoad = [...imagesToLoad, ...size1024];
        });
        log('Preloading texture for scene: ', currQueueScene.id);
        cleanLoadTextures(imagesToLoad, this.maxAnisotropy)
          .then(() => this.loadQueue(callback))
          .catch(() => this.loadQueue(callback));
      } else {
        if (callback) {
          callback();
        }
      }
    }
  };

  getCurrentIdIndex = () => `${this.props.pano.id}-${this.props.variantIndex}`;

  loadImageAsTextures = (
    imgs,
    panoId,
    vaIndex,
    mixFactor,
    allAtOnce = false
  ) => {
    const IdIndex = `${panoId}-${vaIndex}`;
    if (IdIndex !== this.getCurrentIdIndex()) {
      return null;
    }
    log(`Start loading ${allAtOnce ? 'low' : 'high'}`);
    const time = new Date();
    if (allAtOnce) {
      return new Promise((resolve) => {
        cleanLoadTextures(imgs, this.maxAnisotropy).then((res) => {
          if (IdIndex === this.getCurrentIdIndex()) {
            log(`Finished loading low. Tooks ${(new Date() - time) / 1000}s`);
            this.reloadState(res, mixFactor, () => {
              resolve(res);
            });
          }
        });
      });
    } else {
      const reqs = imgs.map(
        (img, index) =>
          new Promise((resolve) => {
            cleanLoadTexture(img, this.maxAnisotropy).then((texture) => {
              if (IdIndex === this.getCurrentIdIndex()) {
                log(`Img ${img} tooks ${(new Date() - time) / 1000}s to load`);
                this.setState(
                  {
                    [`material${mixFactor}`]: {
                      ...this.state[`material${mixFactor}`],
                      [SIZES[index]]: texture,
                    },
                  },
                  () => resolve(texture)
                );
              } else {
                log(`Img ${img} tooks ${(new Date() - time) / 1000}s to load1`);
                resolve(texture);
              }
            });
          })
      );
      return new Promise((resolve) => {
        Promise.all(reqs).then((res) => {
          if (IdIndex === this.getCurrentIdIndex()) {
            log(`Finished loading high. Tooks ${(new Date() - time) / 1000}s`);
            resolve(res);
            this.toClean = [...imgs];
          }
        });
      });
    }
  };

  handleApplyTexture = ({ texture, materialKey, side, resolve, IdIndex }) => {
    if (IdIndex !== this.getCurrentIdIndex()) {
      return null;
    }

    texture.anisotropy = this.maxAnisotropy;

    materials[materialKey][side] = texture;

    this.setState(
      {
        [materialKey]: { ...materials[materialKey] },
      },
      () => resolve && resolve(null)
    );
  };

  loadHiResTexture = ({ panoId, vaIndex, mixFactor, textureData }) => {
    if (isMobilePhone) {
      return this.loadImageAsTextures(
        textureData.size2048,
        panoId,
        vaIndex,
        mixFactor,
        false
      );
    }
    return this.loadMultiSplitTexture({ panoId, vaIndex, mixFactor, textureData });
  };

  loadMultiSplitTexture = ({ panoId, vaIndex, mixFactor, textureData }) => {
    return new Promise(resolveAll => {
      window.cancelSkynImageQueues();
      const IdIndex = `${panoId}-${vaIndex}`;

      if (IdIndex !== this.getCurrentIdIndex()) {
        return resolveAll(null);
      }

      // const { resolution } = this.props;
      // const newResolution = FRAGS[resolution];
      const newResolution = 16; // to ask the scene to always load HD at this point

      const materialKey = `material${mixFactor}`;

      const loaders = SIZES.map(
        (side) =>
          new Promise((resolve) => {
            // load hi-res texture by face
            const imgData = getImgForSide({ side, cubeMapImages: textureData });
            console.time(`loadHiRes-${IdIndex}-${side}-${newResolution}`);
            loadCanvasTexture({
              imgData,
              frag: newResolution,
              cbMD: (texture) => {
                if (newResolution === 4) return; // no need apply here since we will handle in "then"
                this.handleApplyTexture({
                  texture,
                  materialKey,
                  side,
                  resolve: null,
                  IdIndex,
                });
              },
              cdHD: (texture) => {
                if (newResolution === 16) return; // no need apply here since we will handle in "then"
                this.handleApplyTexture({
                  texture,
                  materialKey,
                  side,
                  resolve: null,
                  IdIndex,
                });
              },
            }).then((texture) => {
              this.handleApplyTexture({
                texture,
                materialKey,
                side,
                resolve,
                IdIndex,
              });
              console.timeEnd(
                `loadHiRes-${IdIndex}-${side}-${newResolution}`
              );
            });
          })
      );

      console.time(`loadHiRes-${IdIndex}-${newResolution}`);
      Promise.all(loaders).then(() => {
        console.timeEnd(`loadHiRes-${IdIndex}-${newResolution}`);
        resolveAll(true);
      }).catch(err => {
        resolveAll(err)
      });
    })
  };



  reloadState = (textures, mixFactor, callBack) => {
    const materialKey = `material${mixFactor}`;
    for (let i = 0; i < 6; i++) {
      materials[materialKey][SIZES[i]] = textures[i];
    }
    this.setState(
      { [materialKey]: { ...materials[materialKey] }, curMixFactor: mixFactor },
      () => {
        callBack();
        log(`Finished rendering textures`);
      }
    );
  };

  cleanHdCache = () => {
    log('remove previous scene high textures from cache');
    while (this.toClean.length) {
      const img = this.toClean.pop();
      THREE.Cache.remove(img);
    }
  };

  render() {
    const { material0, material1, curMixFactor } = this.state;
    return (
      <CubeEffect
        onClick={this.props.onClick}
        onPointerDown={this.props.onPointerDown}
        onPointerUp={this.props.onPointerUp}
        onPointerMove={this.props.onPointerMove}
        texture={material0}
        texture2={material1}
        mixFactor={curMixFactor}
      />
    );
  }
}

const mapStateToProps = ({ resolution, currentScene, json }) => ({
  resolution,
  textureData: currentScene.cubeMapImages,
  json,
});

const mapDispatchToProps = {
  setSDLoaded,
  setHDLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cube360);
