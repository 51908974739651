import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Callout Video"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.43 32.61"
    >
      <path
        opacity="0.65"
        d="M975.2,525.24H947.49c-5.86,0-5.86,7.19-5.86,14.8,0,6.94,0,14.8,5.86,14.8H975.2c5.86,0,5.86-7.86,5.86-14.8S981.06,525.24,975.2,525.24Z"
        transform="translate(-940.13 -523.74)"
      />
      <path
        fill={bgColor}
        d="M975.2,523.74H947.49c-7.36,0-7.36,8.66-7.36,16.3,0,7.27,0,16.3,7.36,16.3H975.2c7.36,0,7.36-9,7.36-16.3S982.56,523.74,975.2,523.74Zm0,31.1H947.49c-5.86,0-5.86-7.86-5.86-14.8,0-7.61,0-14.8,5.86-14.8H975.2c5.86,0,5.86,7.86,5.86,14.8S981.06,554.84,975.2,554.84Z"
        transform="translate(-940.13 -523.74)"
      />
      <path
        fill={bgColor}
        d="M975.2,526.74H947.49c-4.36,0-4.36,6-4.36,13.3,0,6.65,0,13.3,4.36,13.3H975.2c4.36,0,4.36-6.65,4.36-13.3S979.56,526.74,975.2,526.74Zm-18.34,19.93V533.42l11,6.55Z"
        transform="translate(-940.13 -523.74)"
      />
    </svg>
  );
};

export default Icon;
