import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Matterport"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.43 32.43"
    >
      <rect
        fill="none"
        stroke={bgColor}
        strokeMiterlimit="10"
        x="0.5"
        y="0.5"
        width="31.43"
        height="31.43"
        rx="3.64"
      />
      <rect
        opacity="0.5"
        x="0.99"
        y="0.99"
        width="30.45"
        height="30.45"
        rx="3.17"
      />
      <rect
        fill={bgColor}
        x="2.41"
        y="2.41"
        width="27.62"
        height="27.62"
        rx="1.86"
      />
      <polygon
        fill="#d0002d"
        points="16.25 9.89 26.99 7.02 26.99 25.72 23.94 26.56 23.8 11.69 16.25 13.78 16.25 9.89"
      />
      <polygon
        fill="#ff0053"
        points="16.25 13.78 8.55 11.69 8.55 26.56 5.44 25.73 5.44 6.99 16.25 9.89 16.25 13.78"
      />
      <polygon
        fill="#d0002d"
        points="16.16 24.47 8.55 26.56 8.55 11.69 11.59 12.52 11.56 21.8 16.16 20.58 16.16 24.47"
      />
      <polygon
        fill="#ff0053"
        points="16.16 20.58 20.83 21.79 20.8 12.52 23.8 11.69 23.94 26.56 16.16 24.47 16.16 20.58"
      />
      <polygon
        fill="#ff6c86"
        points="8.51 6.17 16.2 8.23 23.94 6.17 26.99 7.02 16.25 9.89 5.44 6.99 8.51 6.17"
      />
      <polygon
        fill="#ff6c86"
        points="11.56 21.8 16.2 20.58 20.83 21.79 20.82 20.14 16.25 18.92 11.57 20.17 11.56 21.8"
      />
    </svg>
  );
};

export default Icon;
