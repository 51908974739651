import React from 'react';

function IconFullScreen(props) {
  const color = props.color || '#fff';
  return (
    <svg
      className="sn-icon sn-icon-fullscreen"
      data-name="Icon Full Screen"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.8 51.44"
    >
      <path
        fill={color}
        d="M933.83,565.68h0l0,0,0,0h22.13s0-.08,0-.21V562a.31.31,0,0,0-.08-.22l-16.59,0a1.76,1.76,0,0,1-1.75-1.75V543.51s-.08,0-.2,0H934a.27.27,0,0,0-.21.08V565.7Z"
        transform="translate(-933.77 -514.61)"
      />
      <path
        fill={color}
        d="M956.19,518.51s0-.08,0-.21v-3.47a.33.33,0,0,0-.08-.22l-22.14.07h0l0,.1v22.12l0,0a1.06,1.06,0,0,0,.18,0h3.48a.28.28,0,0,0,.21-.07V520.26a1.75,1.75,0,0,1,1.75-1.75Z"
        transform="translate(-933.77 -514.61)"
      />
      <path
        fill={color}
        d="M984.87,543.64s-.08,0-.2,0h-3.48a.29.29,0,0,0-.22.08l0,16.58a1.75,1.75,0,0,1-1.75,1.75H962.67s0,.08,0,.21v3.47a.31.31,0,0,0,.08.22l22.07,0,0-.06.73.19-.69-.29Z"
        transform="translate(-933.77 -514.61)"
      />
      <path
        fill={color}
        d="M981.21,537a1.25,1.25,0,0,0,.19,0h3.48a.28.28,0,0,0,.21-.07L985,514.84v0l-.11,0H962.8s0,.07,0,.2v3.47a.28.28,0,0,0,.08.22h16.59a1.75,1.75,0,0,1,1.75,1.75V537S981.2,537,981.21,537Z"
        transform="translate(-933.77 -514.61)"
      />
    </svg>
  );
}

export default IconFullScreen;
