import React from 'react';

function IconClose(props) {
  const color = props.color || 'white';
  return (
    <svg
      data-name="Icon Close Media Popup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.5 56.61"
    >
      <path
        opacity="0.5"
        d="M951.33,568.51c-15.5,0-12.64.07-28.14,0-14.28-.08-26-9.87-28.2-23.28-2.82-17.39,9.57-32.85,27.29-33,31.81-.33,45.25-.32,77.06,0a28.06,28.06,0,0,1,27.73,28.35c-.14,15.42-12.69,27.82-28.55,28S967.06,568.51,951.33,568.51Z"
        transform="translate(-894.59 -511.94)"
      />
      <path
        fill={color}
        d="M922.89,527.59a12.66,12.66,0,1,0,12.65,12.65A12.66,12.66,0,0,0,922.89,527.59Zm5.55,16.56a1.16,1.16,0,0,1,0,1.65,1.16,1.16,0,0,1-1.64,0l-3.91-3.91L919,545.8a1.16,1.16,0,0,1-.83.34,1.14,1.14,0,0,1-.82-.34,1.16,1.16,0,0,1,0-1.65l3.91-3.91-3.91-3.91a1.15,1.15,0,0,1,0-1.64,1.16,1.16,0,0,1,1.65,0l3.91,3.9,3.91-3.9a1.16,1.16,0,1,1,1.64,1.64l-3.9,3.91Z"
        transform="translate(-894.59 -511.94)"
      />
      <path
        fill={color}
        d="M978,534.21a2.81,2.81,0,0,0-2.42,1.21,5.88,5.88,0,0,0-.89,3.43v.79a6,6,0,0,0,.88,3.5,2.84,2.84,0,0,0,2.45,1.23,2.78,2.78,0,0,0,2.43-1.21,6.05,6.05,0,0,0,.86-3.52v-.72a6,6,0,0,0-.86-3.5A2.83,2.83,0,0,0,978,534.21Z"
        transform="translate(-894.59 -511.94)"
      />
      <path
        fill={color}
        d="M999.34,512.18c-31.81-.32-45.25-.33-77.06,0-17.72.18-30.11,15.64-27.29,33,2.17,13.41,13.92,23.2,28.2,23.28,15.5.09,12.64,0,28.14,0,15.73,0,31.46.11,47.19,0s28.41-12.53,28.55-28A28.06,28.06,0,0,0,999.34,512.18Zm-76.45,42.94a14.88,14.88,0,1,1,14.87-14.88A14.88,14.88,0,0,1,922.89,555.12Zm30.16-11.88a2.64,2.64,0,0,0,2.33,1.17,3.08,3.08,0,0,0,2.11-.63,3.35,3.35,0,0,0,.9-2.11h2.37a4.93,4.93,0,0,1-1.62,3.43,5.51,5.51,0,0,1-3.76,1.23,5.34,5.34,0,0,1-2.89-.78,5,5,0,0,1-1.93-2.21,7.85,7.85,0,0,1-.7-3.33v-1.28a8,8,0,0,1,.68-3.42,5.15,5.15,0,0,1,2-2.28,5.46,5.46,0,0,1,3-.8,5.3,5.3,0,0,1,3.66,1.23,5.1,5.1,0,0,1,1.61,3.48h-2.37a3.34,3.34,0,0,0-.86-2.13,2.86,2.86,0,0,0-2-.66,2.8,2.8,0,0,0-2.39,1.14,5.71,5.71,0,0,0-.85,3.33v1.22A6,6,0,0,0,953.05,543.24Zm18.46,2.9H962.9V532.41h2.38v11.83h6.23Zm12.18-6.5a8.53,8.53,0,0,1-.69,3.54,5.35,5.35,0,0,1-2,2.34,5.89,5.89,0,0,1-6,0,5.34,5.34,0,0,1-2-2.33,8.1,8.1,0,0,1-.73-3.48v-.77a8.31,8.31,0,0,1,.72-3.55,5.39,5.39,0,0,1,2-2.35,5.9,5.9,0,0,1,6,0,5.26,5.26,0,0,1,2,2.32,8.25,8.25,0,0,1,.71,3.52Zm10.63,5.68a5.76,5.76,0,0,1-3.57,1,6.64,6.64,0,0,1-2.74-.55,4.61,4.61,0,0,1-1.94-1.52,3.75,3.75,0,0,1-.7-2.27h2.39a2.26,2.26,0,0,0,.77,1.82,3.45,3.45,0,0,0,2.22.64,2.92,2.92,0,0,0,1.87-.51,1.61,1.61,0,0,0,.63-1.33,1.68,1.68,0,0,0-.64-1.4,8.1,8.1,0,0,0-2.3-1,11.13,11.13,0,0,1-2.64-1.11A3.52,3.52,0,0,1,985.8,536a3.36,3.36,0,0,1,1.35-2.74,5.44,5.44,0,0,1,3.51-1.07,6,6,0,0,1,2.56.52,4.33,4.33,0,0,1,1.76,1.51,3.83,3.83,0,0,1,.64,2.16h-2.37a2.16,2.16,0,0,0-.68-1.68,2.76,2.76,0,0,0-1.93-.61,2.91,2.91,0,0,0-1.81.5,1.67,1.67,0,0,0-.65,1.4,1.48,1.48,0,0,0,.7,1.25,7.88,7.88,0,0,0,2.3,1,10,10,0,0,1,2.57,1.08,4.08,4.08,0,0,1,1.43,1.4,3.61,3.61,0,0,1,.45,1.84A3.29,3.29,0,0,1,994.32,545.32Zm12.38.82h-9V532.41h8.91v1.93h-6.52v3.79h5.64V540h-5.64v4.23h6.59Z"
        transform="translate(-894.59 -511.94)"
      />
    </svg>
  );
}

export default IconClose;
