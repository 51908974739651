import React from 'react';

const Tip = (props) => (
  <svg
    data-name="Tip 3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 178"
  >
    <path
      fill="#fff"
      d="M838,612.77H826v12.09h14v3.68H821.69v-34.1H839.8v3.67H826v11H838Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M855.09,607.5l6.42-13.06h5l-8.88,16.91,9.07,17.19h-5.08l-6.56-13.31-6.58,13.31h-5.08l9.11-17.19-8.93-16.91h5Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M876.21,615.19v13.35h-4.28v-34.1h10.86a9.82,9.82,0,0,1,7.47,2.84,10.48,10.48,0,0,1,2.72,7.58q0,4.92-2.59,7.57c-1.72,1.78-4.15,2.69-7.29,2.76Zm0-3.68h6.58a5.54,5.54,0,0,0,4.38-1.71,7.13,7.13,0,0,0,1.53-4.91,7.37,7.37,0,0,0-1.57-4.92,5.35,5.35,0,0,0-4.31-1.86h-6.61Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M902.82,624.86h13.37v3.68H898.51v-34.1h4.31Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M942,614.48q0,7-3,10.75a10.12,10.12,0,0,1-8.46,3.77,10.27,10.27,0,0,1-8.32-3.66Q919.14,621.68,919,615v-6.42q0-6.84,3-10.72a10.08,10.08,0,0,1,8.43-3.87,10.2,10.2,0,0,1,8.39,3.71q3,3.71,3.08,10.6Zm-4.28-6c0-3.6-.59-6.29-1.77-8a6.8,6.8,0,0,0-10.78,0c-1.21,1.78-1.82,4.41-1.83,7.87v6.11q0,5.22,1.81,8a6.09,6.09,0,0,0,5.42,2.74,6,6,0,0,0,5.34-2.53q1.74-2.53,1.81-7.75Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M958.88,614.74h-6v13.8h-4.31v-34.1h9.55c3.35,0,5.87.87,7.59,2.61s2.58,4.29,2.58,7.64a10.72,10.72,0,0,1-1.39,5.52,8.85,8.85,0,0,1-3.95,3.55l6.6,14.49v.29H965Zm-6-3.68h5.2a5.57,5.57,0,0,0,4.3-1.73,6.63,6.63,0,0,0,1.6-4.65q0-6.57-6-6.57h-5.15Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M990.38,612.77H978.32v12.09h14v3.68H974v-34.1h18.1v3.67H978.32v11h12.06Z"
      transform="translate(-815.69 -451.31)"
    />
    <rect fill="#fff" opacity="0" width="288" height="178" />
    <path
      fill="#fff"
      d="M1093.5,489.7h-34.61c-9.2,0-9.2,10.82-9.2,20.36,0,9.07,0,20.36,9.2,20.36h34.61c9.19,0,9.19-11.29,9.19-20.36S1102.69,489.7,1093.5,489.7Zm0,38.85h-34.61c-7.32,0-7.32-9.82-7.32-18.49,0-9.51,0-18.49,7.32-18.49h34.61c7.32,0,7.32,9.83,7.32,18.49S1100.82,528.55,1093.5,528.55Z"
      transform="translate(-815.69 -451.31)"
    />
    <path
      fill="#fff"
      d="M1093.5,493.45h-34.61c-5.45,0-5.45,7.44-5.45,16.61,0,8.3,0,16.62,5.45,16.62h34.61c5.45,0,5.45-8.32,5.45-16.62S1099,493.45,1093.5,493.45Zm-22.91,24.89V501.79l13.7,8.19Z"
      transform="translate(-815.69 -451.31)"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="2px"
      cx="145.25"
      cy="102.75"
      r="7.75"
    />
    <path
      fill="#fff"
      d="M867.69,472.81a44.5,44.5,0,1,1-44.5,44.5,44.5,44.5,0,0,1,44.5-44.5m0-3a47.5,47.5,0,1,0,47.5,47.5,47.56,47.56,0,0,0-47.5-47.5Z"
      transform="translate(-815.69 -451.31)"
    />
    <g>
      <clipPath id="circle-mask">
        <circle cx="52" cy="66" r="45" opacity="0.5" />
      </clipPath>
    </g>
    {props.image && (
      <image
        href={props.image}
        x="7"
        y="21"
        width="90"
        height="90"
        clipPath="url(#circle-mask)"
      />
    )}
    <circle fill="#fff" cx="145.25" cy="102.75" r="2.75" />
    <line
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="2px"
      x1="152"
      y1="100"
      x2="234"
      y2="59"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="2px"
      x1="95.26"
      y1="50.56"
      x2="204"
      y2="15"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="2px"
      cx="212.26"
      cy="12.06"
      r="7.75"
    />
    <circle fill="#fff" cx="212.26" cy="12.06" r="2.75" />
  </svg>
);

export default Tip;
