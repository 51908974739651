import React, { useMemo } from 'react';
import ReadMoreBtn from './ReadMoreBtn';
import './popover.info.scss';

function PopOverInfo(props) {
  const { media, tour, position } = props;
  const up = useMemo(() => position[1] > 0, [position]);

  return (
    <div className="Staging">
      <div
        className="PopOver fade-in"
        style={{
          bottom: up ? '0' : null,
        }}
      >
        <div className="Wrapper">
          <h3 className="Wrapper--title">{media.title}</h3>
          <p className="Wrapper--description">
            {media.skytag.description.length <= 255
              ? media.skytag.description
              : media.skytag.description.substr(0, 255) + '...'}
          </p>
          {media?.skytag?.readMoreURL && (
            <ReadMoreBtn
              color={tour.menuHighlightColor}
              href={media.skytag.readMoreURL}
              size={12}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PopOverInfo;
