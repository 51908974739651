import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isMobile } from 'deviceDetect';
import './scene.scss';
import SCENE_TYPE from '../../scene.types';
import SCENE_TYPES from '../../../consts/sceneType.const';
import IconAerialMap from './iconAerialMap';
import IconAerialScene from './iconAerialScene';
import IconGroundScene from './iconGroundScene';
import CheckMark from '../../../components/icons/checked';
import {
  getFriendlyDistance,
  feetBetween,
} from '../../../utils/distanceHelper';

const SceneHotspot = (props) => {
  const hostpostRef = useRef();
  const { currentSceneCoords, isActiveIcon } = props;
  const { title, sceneType, previewImgUrl, coordinates } = props.data;
  const [distance, setDistance] = useState('');
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (sceneType !== SCENE_TYPES.GROUND && currentSceneCoords) {
      const feet = feetBetween(currentSceneCoords, coordinates);
      if (feet) {
        const d = getFriendlyDistance(feet);
        setDistance(d);
      } else {
        setDistance('');
      }
    } else {
      setDistance('');
    }
  }, [currentSceneCoords, coordinates, sceneType]);

  let Icon = IconGroundScene;
  switch (sceneType) {
    case SCENE_TYPE.AERIAL_MAP:
      Icon = IconAerialMap;
      break;
    case SCENE_TYPE.AERIAL:
    case SCENE_TYPES.AERIAL:
      Icon = IconAerialScene;
      break;
    default:
      Icon = IconGroundScene;
      break;
  }

  const onClickHotspotParent = (e) => {
    window.logMessage('onClickHotspotParent', e);
    props.toggleHover(true);
  };

  const onClickHotspot = useCallback(
    (e) => {
      e.stopPropagation();
      if (isMobile()) {
        setCount((prevCount) => prevCount + 1);
        if (count === 0) {
          hostpostRef.current.classList.add('hovering');
        } else {
          hostpostRef.current.classList.remove('hovering');
          props.onClick();
        }
      } else {
        if (!props.isHover) {
          props.toggleHover(true);
        }
        props.onClick();
      }
    },
    [count, props]
  );

  useEffect(() => {
    const hideFlyouts = () => {
      setCount(0);
      hostpostRef.current.classList.remove('hovering');
    };

    const handleClickOutside = (event) => {
      if (!hostpostRef?.current?.contains(event.target)) {
        hideFlyouts();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('touchstart', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={hostpostRef}
      className={`scene-hotspot ${props.isHover ? 'hovering' : ''}`}
      onClick={onClickHotspotParent}
      onMouseEnter={props.hoverIn}
      onMouseLeave={props.hoverOut}
    >
      <div
        className={`s-animation ${props.isMapFloorPlan && 'position-unset'}`}
      >
        <div
          className={`s-animation-circle ${props.isHover ? '' : 'animating'}`}
        >
          &nbsp;
        </div>
      </div>
      <div className="s-info">
        <div className="s-info-title">
          <p style={{ color: props.tour?.calloutHeaderColor || 'black' }}>
            {title}
          </p>
        </div>
        {distance && (
          <div className="s-info-distance">
            <p style={{ color: props.tour?.calloutSubHeaderColor || 'white' }}>
              {distance || 'N/A'}
            </p>
          </div>
        )}
      </div>
      <div className="s-icon" onClickCapture={onClickHotspot}>
        {props.isMapFloorPlan && !isActiveIcon ? (
          <span className="badge-scene-text position-absolute">
            {props.index}
          </span>
        ) : (
          <Icon />
        )}
      </div>
      {props.visited && (
        <div className="s-visited">
          <CheckMark />
        </div>
      )}
      {previewImgUrl && (
        <img
          src={previewImgUrl}
          alt={title}
          draggable={false}
          onClickCapture={onClickHotspot}
        />
      )}
    </div>
  );
};

export default SceneHotspot;
