import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.83 29.5"
    >
      <path
        fill={bgColor}
        d="M979.36,554.36a.74.74,0,0,1-.25-.05l-5.13-2.2v1.62a.64.64,0,0,1-.62.63.54.54,0,0,1-.25-.06l-5.46-2.39v1.82a.63.63,0,0,1-.62.63H940a.63.63,0,0,1-.63-.63v-27a.63.63,0,0,1,.63-.62h27a.62.62,0,0,1,.62.62v1.82l5.46-2.39a.53.53,0,0,1,.25,0,.59.59,0,0,1,.34.1.6.6,0,0,1,.28.52v1.62l5.13-2.19a.56.56,0,0,1,.25,0,.59.59,0,0,1,.34.1.62.62,0,0,1,.28.52v27a.64.64,0,0,1-.62.63Z"
        transform="translate(-938.78 -525.48)"
      />
      <path
        fill={bgColor}
        d="M979.36,526.73v27l-6-2.56v2.56L967,551v2.78H940v-27h27v2.78l6.33-2.78v2.57l6-2.57m0-1.25a1.19,1.19,0,0,0-.49.1l-4.26,1.82v-.67a1.24,1.24,0,0,0-1.75-1.14l-4.58,2v-.87a1.25,1.25,0,0,0-1.25-1.25H940a1.25,1.25,0,0,0-1.25,1.25v27A1.25,1.25,0,0,0,940,555h27a1.25,1.25,0,0,0,1.25-1.25v-.86l4.58,2a1.24,1.24,0,0,0,.5.1,1.25,1.25,0,0,0,1.25-1.25v-.67l4.26,1.82a1.19,1.19,0,0,0,.49.1,1.26,1.26,0,0,0,1.25-1.25v-27a1.26,1.26,0,0,0-1.25-1.25Z"
        transform="translate(-938.78 -525.48)"
      />
      <polygon
        fill={bgColor}
        points="29.51 22.84 29.51 6.66 39.96 2.2 39.96 27.3 29.51 22.84"
      />
      <path
        d="M978.11,528.62v23.22l-9.2-3.93V532.56l9.2-3.94m1.25-1.89-11.7,5v17l11.7,5v-27Z"
        transform="translate(-938.78 -525.48)"
      />
      <polygon
        fill={bgColor}
        points="23.81 22.84 23.81 6.66 33.96 2.21 33.96 27.29 23.81 22.84"
      />
      <path
        d="M972.11,528.64v23.18l-8.9-3.91V532.55l8.9-3.91m1.25-1.91-11.4,5v17l11.4,5v-27Z"
        transform="translate(-938.78 -525.48)"
      />
      <rect fill={bgColor} x="1.87" y="1.88" width="25.75" height="25.75" />
      <path
        d="M965.78,528v24.5h-24.5V528h24.5m1.25-1.25H940v27h27v-27Z"
        transform="translate(-938.78 -525.48)"
      />
      <polygon points="17.85 11.75 13.75 18.75 9.34 15.75 5.34 22.95 23.85 22.95 17.85 11.75" />
      <circle cx="10.43" cy="10.31" r="2.07" />
    </svg>
  );
};

export default Icon;
