import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import './introImage.scss';

const IntroImage = ({ img }) => {
  const imageStyle = useMemo(
    () => ({
      backgroundImage: img ? `url(${img})` : 'none',
    }),
    [img]
  );

  return <div className="intro-img" style={imageStyle}></div>;
};

const mapStateToProps = ({ json }) => ({
  img: (json && json.tour && json.tour.introImageUrl) || '',
});

export default connect(mapStateToProps)(IntroImage);
