import React, { useMemo } from 'react';

import isArray from 'lodash/isArray';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import PoweredBy from 'components/powered';

import PopupHandler from './popupHandler';
import VariantsNavigator from './variantsNavigator';

import AmbientAudio from '../audio';
import Breadcrumb from '../breadcrumb';
import Logo from '../logo';
import Menu from '../menu';

import './style.scss';

const World2D = (props) => {
  const {
    json,
    tour,
    currentPano,
    autoRotate,
    showSpinner,
    showIntro,
    hotspotHistory,
    introStatus,
  } = props;

  const match = useRouteMatch();
  const location = useMemo(() => {
    const { groupId } = match.params;
    if (isArray(json.groups) && json.groups.length > 0 && groupId) {
      const currentGroup = json.groups.find((g) => g.id === groupId) || {};
      return currentGroup.location || '';
    }
    return tour.location;
  }, [json.groups, match.params, tour.location]);

  return (
    <div className="World-2D-Contents">
      {json.menu && (
        <Menu
          menuList={json.menu}
          scenes={json.scenes}
          currentPano={currentPano}
          autoRotate={autoRotate}
          showSpinner={showSpinner}
          toggleRotate={props.toggleRotate}
          toggleFeatured={props.toggleFeatured}
          onSelect={(gId, id) => props.selectSceneById(gId, id)}
          toggleMap={props.toggleMap}
          toggleSocial={props.toggleSocial}
        />
      )}
      {typeof tour === 'object' && tour.logoURL && (
        <Logo showSpinner={showSpinner} introStatus={introStatus} />
      )}
      {typeof tour === 'object' && currentPano && (
        <Breadcrumb
          title={currentPano.title}
          subTitle={location}
          introStatus={introStatus}
        />
      )}
      {typeof tour === 'object' &&
        currentPano &&
        currentPano.variants.length > 1 && (
          <VariantsNavigator
            tour={tour}
            introStatus={introStatus}
            variants={currentPano.variants}
            variantIndex={props.variantIndex}
            onChangeVariantIndex={props.onChangeVariantIndex}
          />
        )}
      <PoweredBy introStatus={introStatus} onClick={props.onClickPoweredBy} />
      <PopupHandler
        mediasList={json.media}
        mediaGroups={json.mediaGroups}
        menuList={json.menu}
        tour={json.tour}
        scenes={json.scenes}
        hotspotHistory={hotspotHistory}
      />

      {currentPano && (
        <AmbientAudio
          scene={currentPano}
          variantIndex={props.variantIndex}
          spinnerOn={showSpinner}
          introOn={showIntro}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  json: store.json || {},
  currentHotspot: store.currentHotspot,
  tour: (store.json && store.json.tour) || null,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(World2D);
