import React from 'react';

import PoweredSvg from './svg';

import './style.scss';

function PoweredBy(props) {
  const { introStatus, onClick } = props;

  return (
    <div className={`powered-container intro-${introStatus}`}>
      <div onClick={onClick}>
        <PoweredSvg />
      </div>
    </div>
  );
}

export default PoweredBy;
