export const INITIATE = 'INITIATE';
export const INITIATED = 'INITIATED';
export const SET_TOUR_DATA = 'SET_TOUR_DATA';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_PANO_MODE = 'TOGGLE_PANO_MODE';
export const SET_MENU_POSITION = 'SET_MENU_POSITION';
export const UPDATE_DIMENTIONS = 'UPDATE_DIMENTIONS';
export const SET_PANO_DATA = 'SET_PANO_DATA';
export const GO_TO_PANO = 'GO_TO_PANO';
export const SHOW_HOTSPOT = 'SHOW_HOTSPOT';
export const CLOSE_HOTSPOT_POPUP = 'CLOSE_HOTSPOT_POPUP';
export const TOGGLE_ISO_METRIC_MAP = 'TOGGLE_ISO_METRIC_MAP';
export const TOGGLE_SOCIAL_POPUP = 'TOGGLE_SOCIAL_POPUP';
export const TOGGLE_CONTACT_POPUP = 'TOGGLE_CONTACT_POPUP';
export const CLOSE_LANDING = 'CLOSE_LANDING';
export const SET_AERIAL_MAP_DATA = 'SET_AERIAL_MAP_DATA';
export const TOGGLE_BLUR = 'TOGGLE_BLUR';
export const SET_AUDIO_AVAILABLE = 'SET_AUDIO_AVAILABLE';
export const TOGGLE_AUDIO = 'TOGGLE_AUDIO';
export const GO_TO_VARIANTS = 'GO_TO_VARIANTS';
export const SET_CONTROL_MODE = 'SET_CONTROL_MODE';
export const TOGGLE_LANDING = 'TOGGLE_LANDING';
export const ADD_HOTSPOT_HISTORY = 'ADD_HOTSPOT_HISTORY';
export const SET_CENTER_LOOKAT = 'SET_CENTER_LOOKAT';
export const SET_RESOLUTIONS = 'SET_RESOLUTIONS';
export const SET_SD_LOADED = 'SET_SD_LOADED';
export const SET_HD_LOADED = 'SET_HD_LOADED';
