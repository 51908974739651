import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  // const borderColor = props.borderColor || '#447594';
  // const color = props.color || '#326f3f';
  return (
    <svg
      data-name="Ground Scene"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.5 113.5"
    >
      <circle fill="#a6a6a6" opacity="0.75" cx="56.67" cy="56.83" r="35.48" />
      <path
        fill="none"
        d="M986,515.5a1.51,1.51,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12l29-29a1.5,1.5,0,0,1,2.12,2.12l-29,29A1.51,1.51,0,0,1,986,515.5Z"
        transform="translate(-903 -483.5)"
      />
      <path
        fill="none"
        d="M904.5,597a1.51,1.51,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12l29-29a1.5,1.5,0,0,1,2.12,2.12l-29,29A1.51,1.51,0,0,1,904.5,597Z"
        transform="translate(-903 -483.5)"
      />
      <path
        opacity="0.75"
        fill={bgColor}
        d="M959.67,504.86a35.48,35.48,0,1,1-35.48,35.47,35.47,35.47,0,0,1,35.48-35.47m0-3a38.48,38.48,0,1,0,38.47,38.47,38.52,38.52,0,0,0-38.47-38.47Z"
        transform="translate(-903 -483.5)"
      />
      <g opacity="0.25">
        <path
          d="M949.65,550.83,943.09,560a3,3,0,0,0,4.88,3.49l7.4-10.37.66-4.42-5.2-5.21Z"
          transform="translate(-903 -483.5)"
        />
        <path
          d="M976.65,537.78a3,3,0,0,0-1.71-1.53l-8.23-2.86-4-5.43-2.53,6.92,2.17,2.74.06.09a3,3,0,0,0,.94.81l.19.1a1.69,1.69,0,0,0,.28.12l9.15,3.18a3,3,0,0,0,3.68-4.14Z"
          transform="translate(-903 -483.5)"
        />
        <path
          d="M963.77,548l-6.09-6.4,4.77-13.49.07-.25.06-.23.25-1.37a6,6,0,1,0-4.43-1.95l-8,4.7-.21.14a2,2,0,0,0-.26.19l-.11.09a2.18,2.18,0,0,0-.21.22,1.8,1.8,0,0,0-.19.21l-.09.14a1.81,1.81,0,0,0-.17.29l-4.36,9.39a3,3,0,0,0,1.46,4,2.94,2.94,0,0,0,1.27.28,3,3,0,0,0,2.72-1.74l3.61-7.79-2.45,6.93a3.21,3.21,0,0,0-.16.87v.12a2.84,2.84,0,0,0,.17,1l0,.13a2.94,2.94,0,0,0,.42.72l6.54,6.9,3.33,11.5a3,3,0,0,0,2.88,2.16,3.4,3.4,0,0,0,.84-.11,3,3,0,0,0,2-3.72Z"
          transform="translate(-903 -483.5)"
        />
      </g>
      <circle fill={bgColor} cx="60.78" cy="35.85" r="4.43" />
      <path
        fill={bgColor}
        d="M952.07,550.42l-6.76,9.47a1.51,1.51,0,0,0,.35,2.1,1.54,1.54,0,0,0,.87.27,1.48,1.48,0,0,0,1.22-.62l7.19-10.08.49-3.32-2.59-2.59Z"
        transform="translate(-903 -483.5)"
      />
      <path
        fill={bgColor}
        d="M975.44,536.67l-8.66-3-2.62-3.56-1.28,3.51,1.67,2.12,0,0a1.36,1.36,0,0,0,.5.45l.1,0,.13.06,9.15,3.18a1.4,1.4,0,0,0,.49.08,1.49,1.49,0,0,0,.49-2.91Z"
        transform="translate(-903 -483.5)"
      />
      <path
        fill={bgColor}
        d="M963.43,547.79,957,541l5.09-14.41a.13.13,0,0,0,0-.06.83.83,0,0,0,0-.15h0a5,5,0,0,1-2.7-1.3l-7.33,4.31h0a1.06,1.06,0,0,0-.21.16h0a.52.52,0,0,1-.1.11l-.1.11v0a1.76,1.76,0,0,0-.14.23h0l-4.3,9.28a1.49,1.49,0,0,0,.73,2,1.38,1.38,0,0,0,.63.14,1.51,1.51,0,0,0,1.36-.87l4.1-8.86,3.86-2.27-4,11.4a1.63,1.63,0,0,0-.08.43v.07a1.36,1.36,0,0,0,.08.49.25.25,0,0,1,0,.07,1.82,1.82,0,0,0,.29.45v0l6.61,6.93,3.44,11.85a1.49,1.49,0,0,0,1.85,1,1.51,1.51,0,0,0,1-1.86Z"
        transform="translate(-903 -483.5)"
      />
    </svg>
  );
};

export default Icon;
