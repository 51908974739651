import React from 'react';
import '../popup.iframe.scss';

function PopupSketchFab(props) {
  const { media } = props;
  return (
    <div className="Popup-content Popup-content-iframe">
      <div className="Popup-content-wp">
        <div className="html-embeded-content sketchfab-embed-wrapper">
          <iframe
            title={media.title}
            frameBorder="0"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="fullscreen; autoplay; vr"
            xr-spatial-tracking="true"
            execution-while-out-of-viewport="true"
            execution-while-not-rendered="true"
            web-share="true"
            src={media.sketchFab.src}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default PopupSketchFab;
