import React, { useRef, useEffect } from 'react';

window.haveAudioSrc = false;

const AmbientAudio = (props) => {
  const { src, loop } = props;
  const audioRef = useRef();

  useEffect(() => {
    window.haveAudioSrc = Boolean(src);
  }, [src]);

  return (
    <audio
      ref={audioRef}
      id="sceneAudio"
      src={src}
      autoPlay={true}
      loop={loop}
    ></audio>
  );
};

export default AmbientAudio;
