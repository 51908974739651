import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import World from './containers/world';
import NotFound from './NotFound';
import SizeDetector from './containers/detectors/size.detector';
import './App.css';
import configs from './configs';
import { cleanLoadTexture } from './utils/textureHelper';

window.defaultTexture = null;
cleanLoadTexture(configs.baseUrl + '/assets/images/white-square.png').then(
  (texture) => {
    window.defaultTexture = texture;
  }
);

document.title = 'SKYNAV';

function App() {
  return (
    <div className="App">
      <SizeDetector />
      <BrowserRouter basename={configs.baseUrl}>
        {(configs.singleTour && (
          <Switch>
            <Route exact path="/not-found" component={NotFound} />
            <Route path="/:sceneId/:hotspotId" component={World} />
            <Route path="/:sceneId" component={World} />
            <Route path="/" component={World} />
            <Route path="*" component={NotFound} />
          </Switch>
        )) || (
          <Switch>
            <Route exact path="/not-found" component={NotFound} />
            <Route
              path="/:tourId/:groupId/:sceneId/:hotspotId"
              component={World}
            />
            <Route
              path="/:tourId/:groupId/:sceneId/:hotspotId"
              component={World}
            />
            <Route path="/:tourId/:groupId/:sceneId" component={World} />
            <Route path="/:tourId/:groupId" component={World} />
            <Route path="/:tourId" component={World} />
            <Route path="/" component={NotFound} />
            <Route path="*" component={NotFound} />
          </Switch>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
