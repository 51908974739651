import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useThree } from '@react-three/fiber';
import { gsap } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';

import { setCenterLookat } from 'store/actions';

import configs from 'configs';

import { getCameraPositionFromLookAtCoordinates } from 'utils/positionHelper';

import './materials/fadeMaterial';

const obj = { factor: 0 };

function CubeEffect({
  texture,
  texture2,
  mixFactor,
  onClick,
  onPointerMove,
  onPointerDown,
  onPointerUp,
}) {
  // three
  const { camera, raycaster } = useThree();

  // store
  const { controlMode } = useSelector((state) => state);
  const dispatch = useDispatch();

  // state
  const [factor, setFactor] = useState(0);

  // ref
  const meshRef = useRef(null);
  const keepRef = useRef({});

  const getCenterLookAt = useCallback(() => {
    if (!camera || !raycaster || !meshRef.current) return null;
    raycaster.setFromCamera({ x: 0, y: 0 }, camera);
    const intersection = raycaster.intersectObject(meshRef.current);

    if (intersection.length === 0) return null;
    const { x, y, z } = intersection[0].point;
    return getCameraPositionFromLookAtCoordinates(x, y, z);
  }, [camera, raycaster]);

  const handleGetCenterLookAtWhenChangeControlMode = () => {
    const point = getCenterLookAt();
    dispatch(setCenterLookat(point));
  };

  keepRef.current.getCenterLookAtRef =
    handleGetCenterLookAtWhenChangeControlMode;

  useEffect(() => {
    if (controlMode === 'orbit') {
      const { getCenterLookAtRef } = keepRef.current;
      getCenterLookAtRef();
    }
  }, [controlMode]);

  useEffect(() => {
    obj.factor = factor;
    gsap.to(obj, {
      factor: mixFactor,
      duration: configs.crossfadeSpeed,
      onUpdate: () => setFactor(obj.factor),
    });
    // eslint-disable-next-line
  }, [mixFactor]);

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, 0]}
      scale-x={-1}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
    >
      <boxBufferGeometry attach="geometry" args={[1000, 1000, 1000, 1, 1, 1]} />
      <skynavFadeMaterial
        attachArray="material"
        side={1}
        texture1={texture.px}
        texture2={texture2.px}
        mixFactor={factor}
      />
      <skynavFadeMaterial
        attachArray="material"
        side={1}
        texture1={texture.nx}
        texture2={texture2.nx}
        mixFactor={factor}
      />
      <skynavFadeMaterial
        attachArray="material"
        side={1}
        texture1={texture.py}
        texture2={texture2.py}
        mixFactor={factor}
      />
      <skynavFadeMaterial
        attachArray="material"
        side={1}
        texture1={texture.ny}
        texture2={texture2.ny}
        mixFactor={factor}
      />
      <skynavFadeMaterial
        attachArray="material"
        side={1}
        texture1={texture.pz}
        texture2={texture2.pz}
        mixFactor={factor}
      />
      <skynavFadeMaterial
        attachArray="material"
        side={1}
        texture1={texture.nz}
        texture2={texture2.nz}
        mixFactor={factor}
      />
    </mesh>
  );
}

export default CubeEffect;
