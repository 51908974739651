import React from 'react';

const Tip = () => (
  <svg
    data-name="Tip 4"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 178"
  >
    <circle fill="none" cx="145.25" cy="102.75" r="2.75" />
  </svg>
);

export default Tip;
