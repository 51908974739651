import { isIOS, isMobile } from 'deviceDetect';

const isIPhone = isIOS();
/**
 * Request Device Orientation Event control on iOS device
 * @return {boolean} isGranted
 */
const requestDeviceOrientationPermission = async () => {
  let isGranted = false;
  if (typeof DeviceOrientationEvent?.requestPermission === 'function') {
    try {
      const status = await DeviceOrientationEvent.requestPermission();
      if (status === 'granted') isGranted = true;
    } catch (error) {}
  }
  return isGranted;
};

/**
 * Handle check and return control mode
 * @param {string} mode Control Mode `orbit` | `device`
 * @return {string} mode
 */
export const handleSwitchControlMode = async (mode) => {
  if (!isMobile) return 'orbit';
  if (isMobile && isIPhone && mode === 'orbit') {
    const isGranted = await requestDeviceOrientationPermission();
    if (isGranted) return 'device';
    return 'orbit';
  }
  return mode === 'orbit' ? 'device' : 'orbit';
};
