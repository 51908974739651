import * as THREE from 'three';

const TARGET_Y = 500;

export const rotate = (vec, angle) => {
  const qua = new THREE.Quaternion();
  qua.setFromAxisAngle(new THREE.Vector3(0, 1, 0), angle);
  vec.applyQuaternion(qua);
};

export const degInRad = (deg) => (deg * Math.PI) / 180;

const moveCloseToY = (vec, distance) => {
  const toCal = new THREE.Vector3(vec.x, 0, vec.z);
  toCal.normalize();
  toCal.multiplyScalar(-1 * distance);
  vec.add(toCal);
};

const getPoints = (start, nRound) => {
  const stepNum = nRound * 20;
  const yStep = (TARGET_Y - start.y) / stepNum;
  const totalDistance = start.distanceTo(new THREE.Vector3(0, start.y, 0));
  const dStep = totalDistance / stepNum;
  const points = [start];
  const ang = -Math.PI / 10;
  let i = 1;
  const curPoint = new THREE.Vector3(start.x, start.y, start.z);
  while (i <= nRound * 20) {
    curPoint.y = curPoint.y + yStep;
    moveCloseToY(curPoint, dStep);
    rotate(curPoint, ang);
    points.push(new THREE.Vector3(curPoint.x, curPoint.y, curPoint.z));
    i++;
  }
  return points;
};

export const getCurvePoints = (x, y, z) => {
  const point = new THREE.Vector3(x, y, z);
  const noRounds = 0.5;
  const basePoints = getPoints(point, noRounds);
  const curve = new THREE.CatmullRomCurve3(basePoints);
  return curve.getPoints(500);
};
