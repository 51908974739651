import React, { useState, useEffect, useCallback, useMemo } from 'react';
import HotspotIcon from 'containers/hotspots/icons';
import { useRouteMatch, useHistory } from 'react-router-dom';

const SCREEN_SIZE = 30;
const ScenesDragged = ({
  item,
  imgRoot,
  size,
  scenes,
  hotspotHistory,
  tour,
  positionY,
  positionX,
  scale,
}) => {
  const [ratioX, setRatioX] = useState(1);
  const [ratioY, setRatioY] = useState(1);
  const [hovering, setHovering] = useState(false);
  const match = useRouteMatch();
  const { sceneId } = match.params;
  const history = useHistory();
  const imgRootDimensions = useMemo(
    () => ({
      width: imgRoot.width,
      height: imgRoot.height,
    }),
    [imgRoot]
  );
  const updateImgViewDimenssion = useCallback(() => {
    const { width, height } = size;
    setRatioX((width * scale) / imgRootDimensions.width);
    setRatioY((height * scale) / imgRootDimensions.height);
  }, [imgRootDimensions, scale, size]);

  useEffect(() => {
    updateImgViewDimenssion();
    window.addEventListener('resize', updateImgViewDimenssion);
    return () => window.removeEventListener('resize', updateImgViewDimenssion);
  }, [updateImgViewDimenssion]);

  const currentScence = useMemo(() => {
    return scenes.find((s) => s._id === item.id) || {};
  }, [item.id, scenes]);

  const isActiveIcon = useMemo(
    () => currentScence && currentScence.id === sceneId,
    [currentScence, sceneId]
  );

  const navigateScene = () => {
    const { tourId } = match.params;
    history.push(`/${tourId}/${currentScence.groupId}/${currentScence.id}`);
  };

  const visited = useMemo(() => {
    return hotspotHistory.includes(item.id);
  }, [hotspotHistory, item.id]);

  const centerScene = SCREEN_SIZE / 2;
  const left = useMemo(
    () => item.coordinates[0] * ratioX + positionX - centerScene,
    [centerScene, item.coordinates, positionX, ratioX]
  );

  const top = useMemo(
    () => item.coordinates[1] * ratioY + positionY - centerScene,
    [centerScene, item.coordinates, positionY, ratioY]
  );
  return (
    <div
      className="position-absolute badge-scene cursor-pointer"
      style={{
        left,
        top,
        background: isActiveIcon ? 'transparent' : 'rgba(244, 136, 20, 0.9)',
        border: isActiveIcon ? 'none' : '1px solid white',
        width: SCREEN_SIZE,
        height: SCREEN_SIZE,
      }}
    >
      <HotspotIcon
        type={item.type}
        tour={tour}
        isActiveIcon={isActiveIcon}
        onClick={navigateScene}
        scene={currentScence}
        previewImgUrl={hovering ? imgRoot.url : null}
        isMapFloorPlan
        isHover={hovering}
        index={item.index + 1}
        hoverIn={() => {
          setHovering(true);
        }}
        hoverOut={() => {
          setHovering(false);
        }}
        visited={visited}
        color={'red' || ''}
      />
    </div>
  );
};

export default ScenesDragged;
