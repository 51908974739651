import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useTogglePopup from 'common/hooks/useTogglePopup';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MEDIA_TYPE from '../../../consts/mediaType.const';
import { EVENT_TYPE, trackEventByType } from '../../../gaTracking';
import { closeHotspotPopup } from '../../../store/actions';
import { formatPopupStyle } from '../../../utils/cssHelper';
import { isMenuLeft } from '../../../utils/menuHelper';
import IconClose from './iconClose';
import PopupCustomHtml from './popup.customHtml';
import PopupGallery from './popup.gallery';
import PopupGoogle360 from './popup.google360';
import PopupInfoBox from './popup.infoBox';
import PopupMapFloorPlan from './popup.mapFloorPlan';
import PopupMatterport from './popup.matterport';
import PopupSketchFab from './popup.sketchfab';
import PopupTypeForm from './popup.typeform';
import PopupContentVideo from './popup.video';
import '../../../scss/hotspot.scss';
import './style.scss';
import { useNavigateRoute } from 'common/hooks';

function HotspotPopup(props) {
  const {
    media,
    mediaGroups,
    mediasList,
    color1,
    color2,
    color3,
    headerColor,
    bodyColor,
    menuList,
    scenes,
    hotspotHistory,
    tour,
  } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const hotspotBody = useRef(null);
  const [mounted] = useTogglePopup(props.closeHotspotPopup);
  const [handleUpdateRoute] = useNavigateRoute();

  useEffect(() => {
    const hotspotBodyEl = hotspotBody && hotspotBody.current;
    if (hotspotBodyEl) {
      disableBodyScroll(hotspotBodyEl);
    }
    return () => {
      if (hotspotBodyEl) {
        enableBodyScroll(hotspotBodyEl);
      }
    };
  }, []);

  const additionalClass = useMemo(() => {
    if (media.type === MEDIA_TYPE.MAPFLOORPLAN) {
      return '';
    }
    if (isMenuLeft(props.dimentions)) {
      return 'Menu-left';
    } else {
      return 'Menu-bottom';
    }
  }, [props.dimentions, media.type]);

  useEffect(() => {
    // GA Tracking
    trackEventByType(EVENT_TYPE.HOTSPOT, media.id);
  }, [media.id]);

  const mapMediaData = (listMedia) => {
    const res = [];
    const mediasListClone = cloneDeep(mediasList);
    listMedia.forEach((m) => {
      mediasListClone.forEach((item) => {
        if (m.id === item._id) {
          if (m.title) {
            item.title = m.title;
          }
          res.push(item);
        }
      });
    });
    return res;
  };

  const getMediasSameGroup = (currentMedia) => {
    let medias = [];
    mediaGroups.forEach((gr, i) => {
      gr.mediaObjects.forEach((obj) => {
        if (obj.id === currentMedia._id) {
          medias = mediaGroups[i].mediaObjects;
        }
      });
    });
    const mediasObj = mapMediaData(medias);
    return mediasObj;
  };

  const navigateToRoute = (hsId) => {
    const { groupId, sceneId, tourId, hotspotId } = match.params;
    if (hsId !== hotspotId) {
      const routeParams = [];
      if (groupId) {
        routeParams.push(groupId);
        if (sceneId) {
          routeParams.push(sceneId);
          if (hsId) {
            routeParams.push(hsId);
          }
        }
      }
      history.push(`/${tourId}/${routeParams.join('/')}`);
    }
  };

  const renderMedia = () => {
    const medias = getMediasSameGroup(media);
    const mediaByType = mediasList.filter((m) => m.type === media.type);
    const mediaExits = medias.filter((mObj) => {
      const isExist = mediaByType.find(({ _id }) => mObj._id === _id);
      return !!isExist;
    });
    const { hotspotId } = match.params;
    if (mediaExits && mediaExits.length > 0) {
      return mediaExits.map((m) => (
        <div
          key={m.id}
          className={`box-media${hotspotId === m.id ? ' active' : ''}`}
          onClick={() => {
            navigateToRoute(m.id);
          }}
        >
          {m.title}
        </div>
      ));
    }
    return null;
  };

  const renderPopupHeader = () => {
    const { type } = media;
    if (type === MEDIA_TYPE.MAPFLOORPLAN) {
      return null;
    }

    return (
      <span className="Close-button" onClick={() => handleUpdateRoute()}>
        <IconClose />
      </span>
    );
  };

  return (
    <div
      className={`Hotspot-popup Hotspot-transition ${
        mounted && 'Hotspot-transition__blur'
      }`}
      style={formatPopupStyle(color1, color2, color3)}
    >
      <div className="Hotspot-popup-header">{renderPopupHeader()}</div>
      <div
        ref={hotspotBody}
        className={`Hotspot-popup-body 
        }  ${additionalClass}`}
      >
        <div className="Popup-body-content">
          <PerfectScrollbar>
            {[media].map((hs) => {
              if (hs.type === MEDIA_TYPE.IMAGE) {
                return (
                  <PopupInfoBox
                    tour={tour}
                    media={media}
                    headerColor={headerColor}
                    bodyColor={bodyColor}
                    key="PopupInfoBox"
                  />
                );
              } else if (hs.type === MEDIA_TYPE.GALLERY) {
                return (
                  <PopupGallery
                    media={media}
                    headerColor={headerColor}
                    bodyColor={bodyColor}
                    key="PopupGallery"
                  />
                );
              } else if (hs.type === MEDIA_TYPE.MATTERPORT) {
                return <PopupMatterport media={media} key="PopupMatterport" />;
              } else if (hs.type === MEDIA_TYPE.MAPFLOORPLAN) {
                return (
                  <PopupMapFloorPlan
                    key="MapFloorPlan"
                    media={media}
                    menuList={menuList}
                    scenes={scenes}
                    tour={tour}
                    closeHotspotPopup={() => handleUpdateRoute()}
                    hotspotHistory={hotspotHistory}
                  />
                );
              } else if (hs.type === MEDIA_TYPE.GOOGLE360) {
                return <PopupGoogle360 media={media} key="PopupGoogle360" />;
              } else if (hs.type === MEDIA_TYPE.TYPEFORM) {
                return <PopupTypeForm media={media} key="PopupTypeForm" />;
              } else if (hs.type === MEDIA_TYPE.CUSTOM_HTML) {
                return <PopupCustomHtml media={media} key="PopupCustomHtml" />;
              } else if (hs.type === MEDIA_TYPE.SKETCH_FAB) {
                return <PopupSketchFab media={media} key="PopupSketchFab" />;
              } else if (hs.type === MEDIA_TYPE.VIDEO) {
                return <PopupContentVideo media={media} key="PopupVideo" />;
              } else {
                return null;
              }
            })}
          </PerfectScrollbar>
        </div>
      </div>
      <div className="Hotspot-popup-footer">{renderMedia()}</div>
    </div>
  );
}

const mapStateToProps = ({ dimentions, json }) => ({
  dimentions,
  color1: (json.tour && json.tour.lightboxBackgroundColor1) || '',
  color2: (json.tour && json.tour.lightboxBackgroundColor2) || '',
  color3: (json.tour && json.tour.lightboxBackgroundColor3) || '',
  headerColor: (json.tour && json.tour.lightboxHeaderColor) || '',
  bodyColor: (json.tour && json.tour.lightboxBodyTextColor) || '',
});

const mapDispatchToProps = {
  closeHotspotPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(HotspotPopup);
