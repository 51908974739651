import React, { useState, useEffect } from 'react';
import './popup.infoBox.scss';
import ButtonReadMore from './buttonReadMoreNew';

const DIMENTION_PORTRAIT = 1;
const DIMENTION_LANDSCAPE = 2;

function useWindowDimention() {
  const isClient = typeof window === 'object';
  function getDim() {
    return !isClient
      ? DIMENTION_PORTRAIT
      : window.innerWidth < window.innerHeight
      ? DIMENTION_PORTRAIT
      : DIMENTION_LANDSCAPE;
  }
  const [winDim, setWinDim] = useState(getDim);
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWinDim(getDim());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);
  return winDim;
}

const checkDimentions = (img) => {
  if (img.target.width > img.target.height) {
    return DIMENTION_LANDSCAPE;
  }
  return DIMENTION_PORTRAIT;
};

function PopupInfoBox(props) {
  const [dimention, setDim] = useState(0);
  const windowDimention = useWindowDimention();
  const { title, text, imageURL, readMoreURL } = props.media;
  const { headerColor, bodyColor } = props;
  return (
    <div
      className={`Popup-content Popup-content-infobox ${
        windowDimention === DIMENTION_PORTRAIT
          ? 'Popup-content-portrait'
          : 'Popup-content-landscape'
      }`}
    >
      <img
        src={imageURL}
        onLoad={(evt) => setDim(checkDimentions(evt))}
        alt=""
      />
      <div className="Popup-content-wp">
        {dimention ? (
          <>
            <div className="infobox-img-wp">
              <img src={imageURL} alt={title} />
            </div>
            <div className="infobox-text-wp">
              <h2 style={{ color: headerColor || 'white' }}>{title}</h2>
              <p style={{ color: bodyColor || 'white' }}>{text}</p>
              {readMoreURL && (
                <div>
                  <ButtonReadMore href={readMoreURL} color="white" />
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default PopupInfoBox;
