import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Callout Map Floor Plan"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.43 32.43"
    >
      <rect
        fill="none"
        stroke={bgColor}
        strokeMiterlimit={10}
        x="0.5"
        y="0.5"
        width="31.43"
        height="31.43"
        rx="3.64"
      />
      <rect
        opacity={0.5}
        x="0.99"
        y="0.99"
        width="30.45"
        height="30.45"
        rx="3.17"
      />
      <rect
        fill={bgColor}
        x="2.41"
        y="2.41"
        width="27.62"
        height="27.62"
        rx="1.86"
      />
      <path
        d="M968.35,530.82H951.52a.84.84,0,0,0-.84.84v16.83a.85.85,0,0,0,.84.84h9.67a.84.84,0,0,0,.6-.25l7.15-7.16a.82.82,0,0,0,.25-.59v-9.67A.84.84,0,0,0,968.35,530.82Zm-.84,6.48h-5a.84.84,0,0,0,0,1.68h5v2l-6.66,6.67h-4.37v-1.3a.84.84,0,0,0-1.68,0v1.3h-2.44V532.5h2.44v7.08a.84.84,0,0,0,1.68,0V532.5h11Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        d="M954.32,543a1.32,1.32,0,1,0,1.32-1.31A1.33,1.33,0,0,0,954.32,543Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        d="M967.65,546.73a1.32,1.32,0,1,0,1.31,1.32A1.32,1.32,0,0,0,967.65,546.73Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        d="M959.15,539.45a1.32,1.32,0,1,0-1.32-1.31A1.32,1.32,0,0,0,959.15,539.45Z"
        transform="translate(-943.72 -523.88)"
      />
    </svg>
  );
};

export default Icon;
