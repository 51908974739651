import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useParamsByLocation } from './useParamsByLocation';

export function useBlur() {
  const { isLandingOpen, shouldBlur } = useSelector((state) => state);

  const [blur, setBlur] = useState(() => isLandingOpen);
  const { hotspotId } = useParamsByLocation();

  useEffect(() => {
    if (isLandingOpen || (hotspotId && shouldBlur)) {
      setBlur(true);
      return;
    }
    setBlur(false);
  }, [hotspotId, isLandingOpen, shouldBlur]);

  return blur;
}
