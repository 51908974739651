import React from 'react';

const Icon = (props) => {
  const bgColor = props.bgColor || 'white';
  return (
    <svg
      data-name="Google Map 360"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.43 32.43"
    >
      <rect
        fill="none"
        stroke={bgColor}
        strokeMiterlimit="10"
        x="0.5"
        y="0.5"
        width="31.43"
        height="31.43"
        rx="3.64"
      />
      <rect
        opacity="0.5"
        x="0.99"
        y="0.99"
        width="30.45"
        height="30.45"
        rx="3.17"
      />
      <polygon
        fill={bgColor}
        points="14.78 25.52 14.78 25.59 14.79 25.56 14.78 25.52"
      />
      <path
        fill={bgColor}
        d="M971.89,526.29H948a1.86,1.86,0,0,0-1.86,1.85V552A1.86,1.86,0,0,0,948,553.9h23.9a1.86,1.86,0,0,0,1.86-1.86v-23.9A1.86,1.86,0,0,0,971.89,526.29ZM958,551.61l.37-1.53c-5.65-.26-10-1.9-10-3.88,0-1.21,1.63-2.29,4.19-3a9.38,9.38,0,0,0,.61.8c-2.18.52-3.56,1.28-3.56,2.08,0,1.33,3.82,2.56,8.79,2.76L958,547l3.09,2.3Zm4.7-1.61.09-1.25c4.33-.31,7.5-1.47,7.5-2.68,0-.79-1.34-1.54-3.46-2.06a8,8,0,0,0,.61-.8c2.51.72,4.1,1.79,4.1,3C971.53,548,967.76,549.58,962.69,550Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        fill="#ffba00"
        d="M955.74,537.13h0Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        fill="#ffba00"
        d="M955.84,536.92v0Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        fill="#ffba00"
        d="M955.53,537.79c0-.16.08-.3.13-.45h0l-2.35-1.75a7.37,7.37,0,0,0-.69,4.44,7.65,7.65,0,0,0,.65,2.21l2.37-1.74A4.78,4.78,0,0,1,955.53,537.79Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        fill="#fd2c25"
        d="M964.94,533.32a7,7,0,0,0-4.7-1.86,7.25,7.25,0,0,0-5.28,2,7.54,7.54,0,0,0-1.65,2.15l2.35,1.75h0a4.64,4.64,0,0,1,6-2.83,4,4,0,0,1,1.35.87l2-2Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        fill="#00ac47"
        d="M962.77,542.86c-.14.1-.29.19-.44.28a4.71,4.71,0,0,1-6.69-2.64l-2.37,1.74a7.24,7.24,0,0,0,2.07,2.56,7.44,7.44,0,0,0,6.06,1.54,6.94,6.94,0,0,0,3.59-1.68Z"
        transform="translate(-943.72 -523.88)"
      />
      <path
        fill="#3284fc"
        d="M967.14,538.09c0-.16-.06-.48-.23-.48h-6.86v2.85h4.22v.08a.24.24,0,0,1,0,.08,3.77,3.77,0,0,1-1.48,2.23l2.22,1.8a6.59,6.59,0,0,1-1.58,1.05,6.62,6.62,0,0,0,3.72-5.24c0-.38.07-.76.11-1.14V539C967.21,538.67,967.17,538.38,967.14,538.09Z"
        transform="translate(-943.72 -523.88)"
      />
    </svg>
  );
};

export default Icon;
