import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import DropUpNavigator from './dropupNavigator';

import styled from 'styled-components';
import './styles.scss';

const DefaultButton = styled.button`
  box-sizing: border-box;
  background: none;
  border: none;
  padding: 8px 10px;
  margin: 0 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid
    ${(props) => (props.active ? props.menuHighlightColor : 'transparent')};
  color: ${(props) => (props.active ? props.menuHighlightColor : 'white')};
  outline: none;
  opacity: 1;
  width: auto;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3 ease-in-out;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.9);
  &:hover {
    border: 1px solid ${(props) => props.menuHighlightColor || 'transparent'};
    color: ${(props) => props.menuHighlightColor || 'white'};
    opacity: 0.8;
  }
`;

function VariantsNavigator(props) {
  const {
    introStatus,
    variants,
    tour: { menuHighlightColor },
    variantIndex,
    onChangeVariantIndex,
  } = props;
  const { position: menuPosition } = useSelector((state) => state.menu);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
    setTimeout(() => setReady(true), 1500);
  }, [variants, variantIndex]);
  const onClickVariant = useCallback(
    (index) => {
      if (variantIndex !== index && ready) onChangeVariantIndex(index);
    },
    [variantIndex, ready, onChangeVariantIndex]
  );

  const isSingleVariant = useMemo(() => variants.length === 1, [variants]);

  if (isSingleVariant) {
    return null;
  }

  return (
    <div className={`variants-navigation ${menuPosition} intro-${introStatus}`}>
      <div>
        {menuPosition === 'left' &&
          variants.map((v, index) => (
            <DefaultButton
              key={index}
              menuHighlightColor={menuHighlightColor}
              active={variantIndex === index}
              onClick={() => onClickVariant(index)}
            >
              {v.title}
            </DefaultButton>
          ))}

        {menuPosition === 'bottom' && (
          <DropUpNavigator
            variants={variants}
            onClickVariant={onClickVariant}
            variantIndex={variantIndex}
          />
        )}
      </div>
    </div>
  );
}

export default VariantsNavigator;
