import React, { useState, useEffect, useMemo } from 'react';

import { trackEventByType, EVENT_TYPE } from 'gaTracking';
import HOTSPOT_TYPE from 'consts/hotspotType.const';
import { useNavigateRoute } from 'common/hooks';

import LineSingle from './lineSingle';
import { useSelector } from 'react-redux';

const Line = (props) => {
  const [handleUpdateRoute] = useNavigateRoute();

  const [spots, setHotspots] = useState([]);
  const { hotspots } = props;
  const { hotspotHistory } = useSelector((state) => state);

  const spotHistory = useMemo(() => {
    return hotspotHistory.reduce((obj, key) => {
      obj[key] = true;
      return obj;
    }, {});
  }, [hotspotHistory]);

  useEffect(() => {
    setHotspots(
      hotspots.filter((hs) => hs.scene || (hs.media && !hs.media.audio))
    );
  }, [hotspots]);

  const onClickHotspot = (hs) => {
    if (hs.type === HOTSPOT_TYPE.MEDIA) {
      trackEventByType(EVENT_TYPE.HOTSPOT_FROM_HOTSPOT, hs.media.id);
      handleUpdateRoute(hs.media.id);
    } else {
      trackEventByType(EVENT_TYPE.SCENE_FROM_HOTSPOT, hs.scene.id);
      props.onClickJumpSpot(hs.scene.groupId, hs.scene.id);
    }
  };

  if (!hotspots || !hotspots.length) {
    return null;
  }

  return (
    <>
      {spots.map((hs) => (
        <LineSingle
          key={hs.id}
          currentSceneCoords={props.currentSceneCoords}
          position={hs.location}
          hotspot={hs}
          type={hs.type}
          scene={hs.scene}
          media={hs.media}
          tour={props.tour}
          color={props.brandColor}
          visited={spotHistory[hs._id]}
          onClick={() => onClickHotspot(hs)}
        />
      ))}
    </>
  );
};

export default Line;
