import React, { useMemo } from 'react';
import { useThree } from '@react-three/fiber';
import Lensflare from './lensflare';
import { getLensflareLocation } from './lensflareCheck';

function WebLensflare(props) {
  const { pano, variantIndex } = props;
  const threeContext = useThree();
  const lensflareLocation = useMemo(
    () => getLensflareLocation(pano, variantIndex),
    [pano, variantIndex]
  );

  if (!lensflareLocation) return null;

  return <Lensflare position={lensflareLocation} {...threeContext} />;
}

export default WebLensflare;
