import { useSelector } from 'react-redux';

import { useParamsByLocation } from 'common/hooks';

import LineList from '../../hotspots/line';

const LineListHandler = ({ params, showHP, ...restProps }) => {
  const { hotspotId: popupOpen } = useParamsByLocation();

  const { isLandingOpen } = useSelector((state) => state);

  if (!isLandingOpen && !popupOpen && showHP && restProps.hotspots.length) {
    return <LineList {...restProps} />;
  }

  return null;
};

export default LineListHandler;
