import React from 'react';
import './icon.scss';

const IconFacebook = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 63.94">
      <path
        d="M959.67,508.36a32,32,0,1,0,32,32A32,32,0,0,0,959.67,508.36Zm9.27,19.42c-1.28,0-2.56,0-3.84,0A5.33,5.33,0,0,0,964,528a2,2,0,0,0-1.58,1.9c-.09,1.76-.08,3.54-.1,5.3,0,.4.28.34.52.34H969c0,.43-.06.83-.12,1.22-.22,1.68-.46,3.35-.66,5,0,.37-.19.42-.49.41h-5c-.3,0-.45.05-.45.39,0,5.68,0,11.35,0,17a1.49,1.49,0,0,0,0,.21h-7c0-.15,0-.31,0-.47V542.76c0-.61,0-.61-.62-.61-1.69,0-3.39,0-5.09,0-.35,0-.48-.09-.48-.46,0-1.92,0-3.84,0-5.75,0-.38.14-.46.48-.46,1.72,0,3.43,0,5.14,0,.43,0,.59-.11.58-.56,0-1.79-.07-3.57,0-5.36a8.51,8.51,0,0,1,2.41-5.89,7.88,7.88,0,0,1,4.9-2.2,32.27,32.27,0,0,1,6.29.15c.29,0,.36.15.36.41,0,1.77,0,3.54,0,5.31C969.37,527.69,969.25,527.79,968.94,527.78Z"
        fill={color}
        transform="translate(-927.7 -508.36)"
      />
    </svg>
  );
};

const IconInstagram = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 63.94">
      <path
        fill={color}
        d="M974.05,533c-.15-4.34-2.15-6.94-6.35-7.18a151.23,151.23,0,0,0-16.9,0c-3.88.22-5.89,2.24-6.11,6.13a155.45,155.45,0,0,0,0,16.86c.22,3.93,2.29,6,6.25,6.16a158.86,158.86,0,0,0,16.62,0c4.05-.21,6-2.34,6.39-6.38.25-2.7,0-5.44,0-8.16h.06C974.06,537.92,974.13,535.44,974.05,533Zm-14.86,16.58a9.19,9.19,0,1,1,9.31-9.13A9.17,9.17,0,0,1,959.19,549.53Zm9.78-16.7a2.17,2.17,0,0,1-2.16-2.14,2.14,2.14,0,0,1,2.08-2.07,2.11,2.11,0,0,1,2.14,2.12A2.14,2.14,0,0,1,969,532.83Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M959.31,534.3a6,6,0,1,0,6,6.1A6,6,0,0,0,959.31,534.3Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M959.67,508.36a32,32,0,1,0,32,32A32,32,0,0,0,959.67,508.36Zm17.53,39.46c-.21,5.92-3.3,9.71-9.17,10.17a112.39,112.39,0,0,1-17.31,0c-5.8-.44-8.73-3.39-9.17-9.2a111,111,0,0,1,0-17c.44-5.79,3.38-8.73,9.19-9.17a109.49,109.49,0,0,1,17.18,0c6,.49,9.11,4.31,9.28,10.36.06,2.44,0,4.89,0,7.33S977.29,545.33,977.2,547.82Z"
        transform="translate(-927.7 -508.36)"
      />
    </svg>
  );
};

const IconPinterest = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 63.94">
      <path
        fill={color}
        d="M959.67,508.36a32,32,0,1,0,32,32A32,32,0,0,0,959.67,508.36ZM972.15,542c-2.93,6.07-8.45,7.89-14.71,4.81-1.73,4.13-3.33,7.92-4.92,11.72l-2-.52c.78-4.65,1.64-9.28,2.29-13.95.36-2.6.25-5.27.59-7.88.2-1.6.46-3.46,1.41-4.6a4.53,4.53,0,0,1,3.91-1.07c.79.2,1.69,2.14,1.59,3.22-.21,2.22-1.33,4.36-1.53,6.58-.12,1.26.49,3.35,1.39,3.77a5.45,5.45,0,0,0,4.49-.34c3.74-2.31,5.26-10.49,3-14.49s-7.76-5.25-12.51-3.14a10.16,10.16,0,0,0-5.33,12.19c.42,1.44.62,2.94,1,4.87-5.58-2.39-7.12-7.47-4.68-13.16,3.11-7.23,13-10.64,20.53-7C973.68,526.32,975.93,534.16,972.15,542Z"
        transform="translate(-927.7 -508.36)"
      />
    </svg>
  );
};

const IconTwitter = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 63.94">
      <path
        fill={color}
        d="M959.67,508.36a32,32,0,1,0,32,32A32,32,0,0,0,959.67,508.36Zm13.42,29c-3.05,14.55-15.77,21.16-29.88,15l8.5-3c-6.79-4-8.62-13.67-7.63-21l14,7.41c2.29-9.61,4.19-10.58,12.87-8.24,1.29.35,2.83-.23,4.68-.43C974.78,530.65,973.81,534,973.09,537.39Z"
        transform="translate(-927.7 -508.36)"
      />
    </svg>
  );
};

const IconWebsite = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 63.94">
      <path
        fill={color}
        d="M947.78,548.19l.27-.05a35.78,35.78,0,0,1-.81-6.24h-6.1a17.93,17.93,0,0,0,2.25,7.3c1.07-.27,2.11-.53,3.16-.77Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M973,528.68a17.79,17.79,0,0,0-6.09-4.68,23.24,23.24,0,0,1,2.57,5.41Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M954.72,555.71a7.19,7.19,0,0,0,2.69,2.18l.19.08v-7.59l-5.58.33A17.07,17.07,0,0,0,954.72,555.71Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M948.06,532.32l-1.12-.26-3.34-.8-.09,0,0,.07a18.15,18.15,0,0,0-2.21,6.42c0,.29-.07.58-.1.88l0,.16h6.1A37.13,37.13,0,0,1,948.06,532.32Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M945.25,551.89a17.85,17.85,0,0,0,6.32,4.84,23.08,23.08,0,0,1-2.7-5.59Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M951.44,524a17.71,17.71,0,0,0-6,4.58l3.47.75A22.94,22.94,0,0,1,951.44,524Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M957.6,533.27l-6.46-.45a31.85,31.85,0,0,0-.78,5.94h7.24Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M957.59,547.26V541.9h-7.23a32.24,32.24,0,0,0,.77,5.77Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M957.6,522.56h0a6.92,6.92,0,0,0-2.79,2.16,16.45,16.45,0,0,0-2.69,4.85l-.08.21,5.57.37Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M968,541.9h-7.23v5.37l6.46.44A31.63,31.63,0,0,0,968,541.9Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M960.76,538.77H968a31.87,31.87,0,0,0-.77-5.89l-6.46.4Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M959.67,508.36a32,32,0,1,0,32,32A32,32,0,0,0,959.67,508.36Zm-.5,53.17h0a21.2,21.2,0,1,1,0-42.4h0a21.2,21.2,0,1,1,0,42.4Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M970.29,548.22l1.09.26,3.51.83,0,0a17.11,17.11,0,0,0,2-5.13c.12-.56.2-1.11.28-1.7,0-.18.05-.35.08-.53H971.1A35.57,35.57,0,0,1,970.29,548.22Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M971.11,538.77h6.1a17.86,17.86,0,0,0-2.28-7.36h0l-4.61,1A36,36,0,0,1,971.11,538.77Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M966.33,529.82c-.07-.2-.13-.37-.2-.54a15.83,15.83,0,0,0-2.83-4.85,6.71,6.71,0,0,0-2.54-1.87v7.6l5.58-.33Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M966.77,556.74A17.76,17.76,0,0,0,973,552l-3.56-.78A22.86,22.86,0,0,1,966.77,556.74Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M960.76,558a6,6,0,0,0,2-1.3,15.7,15.7,0,0,0,3.58-5.92l-5.56-.36Z"
        transform="translate(-927.7 -508.36)"
      />
    </svg>
  );
};

const IconYoutube = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 63.94">
      <path
        fill={color}
        d="M954.9,546.76l11-6.69-11-6.56Z"
        transform="translate(-927.7 -508.36)"
      />
      <path
        fill={color}
        d="M959.67,508.36a32,32,0,1,0,32,32A32,32,0,0,0,959.67,508.36Zm17.09,38.74c-.13,3.81-2,6.08-6.2,6.1-3.76,0-7.51.42-11.11.65-4.07-.25-8-.63-11.88-.68-3.74-.06-5.69-2.06-5.86-5.47-.26-5.1-.24-10.23,0-15.33.17-3.47,2.33-5.48,5.91-5.51q11.5-.08,23,0c3.76,0,5.94,2.08,6.08,5.81C976.91,537.47,976.93,542.29,976.76,547.1Z"
        transform="translate(-927.7 -508.36)"
      />
    </svg>
  );
};

const Icons = (props) => {
  const { type, color } = props;
  switch (type) {
    case 'WEBSITE':
      return <IconWebsite color={color} />;
    case 'FACEBOOK':
      return <IconFacebook color={color} />;
    case 'INSTAGRAM':
      return <IconInstagram color={color} />;
    case 'TWITTER':
      return <IconTwitter color={color} />;
    case 'YOUTUBE':
      return <IconYoutube color={color} />;
    case 'PINTEREST':
      return <IconPinterest color={color} />;
    default:
      return <></>;
  }
};

const SocialIcon = (props) => {
  const { type, color, textColor, link } = props;
  return (
    <div className="social-icon">
      <div className="social-icon-svg">
        <a target="_blank" href={link} rel="noopener noreferrer">
          <Icons type={type} color={color} />
        </a>
      </div>
      <div className="social-icon-label" style={{ color: textColor }}>
        <a target="_blank" href={link} rel="noopener noreferrer">
          <span>{type}</span>
        </a>
      </div>
    </div>
  );
};

export default SocialIcon;
