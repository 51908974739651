import React from 'react';
import './style.scss';
import Swiper from './swiper';

function PopupGallery({ headerColor, bodyColor, media: { gallery } }) {
  return (
    <div className={`Popup-content Popup-content-gallery`}>
      <div className="Popup-content-wp">
        <Swiper
          headerColor={headerColor}
          bodyColor={bodyColor}
          gallery={gallery}
        />
      </div>
    </div>
  );
}

export default PopupGallery;
